import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { COUNTRIES, AIRPORTS, VISA_CATEGORIES } from "./data/constants";
import { format } from "date-fns";
import { mapValues } from "lodash/fp";
import * as firebase from "firebase/app";
import "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const FIELDS = [
  {
    key: "country",
    name: "Country",
    required: true,
    values: () => COUNTRIES,
  },
  {
    key: "passport",
    name: "Passport Used",
    values: (props, v) => props.passports,
    getOptionLabel: (v) =>
      `${v.country} (Expiration: ${
        v.expiration_date
          ? format(v.expiration_date?.toDate(), "yyyy-MM-dd")
          : "None"
      })`,
    required: true,
    getOptionSelected: (o, v) => o?.id === v?.id,
  },
  {
    key: "visa_category",
    name: "Visa Category",
    values: () => VISA_CATEGORIES,
    required: (v) => v.country === "United States",
  },

  {
    key: "start_date",
    name: "Entry Date",
    type: "date",
    required: true,
  },
  {
    key: "mode_of_arrival",
    name: "Mode of Arrival",
    type: "enum",
    values: () => ["Air", "Land", "Sea"],
    required: true,
  },

  {
    key: "port_of_arrival",
    name: "Port of Arrival",
    values: (props, v) => (v.mode_of_arrival === "Air" ? AIRPORTS : null),
    required: true,
  },

  {
    key: "end_date",
    name: "Exit Date",
    type: "date",
    required: false,
    validate: (value, getValues) => {
      const values = getValues();
      return !values.end_date || values.end_date >= values.start_date;
    },
  },

  {
    key: "mode_of_departure",
    name: "Mode of Departure",
    values: () => ["Air", "Land", "Sea"],
    required: false,
  },

  {
    key: "port_of_departure",
    name: "Port of Departure",
    values: (props, v) => (v.mode_of_departure === "Air" ? AIRPORTS : null),
    required: false,
  },
];

const parseData = (d) => {
  return {
    ...d,
    start_date: d.start_date
      ? format(d.start_date.toDate(), "yyyy-MM-dd")
      : null,
    end_date: d.end_date ? format(d.end_date.toDate(), "yyyy-MM-dd") : null,
  };
};

const parseDate = (d) => {
  return new Date(`${d}T12:00:00`);
};

const serializeData = (d) => {
  return mapValues((v) => (typeof v === "undefined" ? null : v), {
    ...d,
    start_date: d.start_date ? parseDate(d.start_date) : null,
    end_date: d.end_date ? parseDate(d.end_date) : null,
  });
};

const filterOptions = createFilterOptions({
  limit: 300,
});

const uploadBorderFile = async (userId, file: File) => {
  var storageRef = firebase.storage().ref();
  const path = `borders/${userId}/${uuidv4()}.${file.name.split(".").pop()}`;
  const fileRef = storageRef.child(path);
  const snapshot = await fileRef.put(file);
  return path;
};

const FirebaseImagePreview = (props) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    var storageRef = firebase.storage().ref();
    storageRef
      .child(props.fileRef)
      .getDownloadURL()
      .then((downloadUrl) => setUrl(downloadUrl));
  }, [props.fileRef]);

  return url ? (
    <img style={{ maxHeight: 150, maxWidth: 150 }} src={url} />
  ) : (
    <CircularProgress />
  );
};

const ImageField = (props) => {
  if (props.value == null || typeof props.value === "object") {
    // Is file
    return (
      <input onChange={props.onChange} accept=".jpg, .jpeg, .png" type="file" />
    );
  } else {
    return <FirebaseImagePreview fileRef={props.value} />;
  }
};

export const BorderCrossingsForm = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    errors,
    reset,
  } = useForm({
    defaultValues: props.data || {},
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "evidence", // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  );

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    reset(props.data ? parseData(props.data) : {});
  }, [props.data, reset]);

  const onSubmit = async (preData) => {
    setSaving(true);

    const { evidence, ...data } = preData as any;
    data["evidence"] = [...(evidence || [])];

    for (let i = 0; i < evidence.length; i++) {
      if (evidence[i]?.file_ref && typeof evidence[i]?.file_ref === "object") {
        const filename = await uploadBorderFile(
          props.userId,
          evidence[i]?.file_ref[0]
        );
        data["evidence"][i] = { file_ref: filename, confirmed: false };
      }
    }

    await props.onSave(serializeData(data));
    setSaving(false);
    if (!props.data) reset({});
  };

  const extra = watch(["country", "mode_of_arrival", "mode_of_departure"]);

  return (
    <Box>
      <Typography variant="h6">
        {props.data ? "Update" : "Add"} Border Crossing
      </Typography>
      <form autoComplete="asdf" onSubmit={handleSubmit(onSubmit)}>
        {FIELDS.map((field) => (
          <Box key={field.key} mb={2}>
            {field?.values?.(props, extra) ? (
              <Controller
                defaultValue={null}
                name={field.key}
                control={control}
                rules={{
                  required:
                    typeof field.required === "function"
                      ? field.required(extra)
                      : field.required,
                }}
                render={({ onChange, onBlur, value }) => {
                  return (
                    <Autocomplete
                      onChange={(e, v) => {
                        onChange(v);
                        if (field.name.startsWith("Port of")) {
                          setTimeout(() => onChange(v), 20);
                        }
                      }}
                      value={value || null}
                      onBlur={onBlur}
                      options={field.values(props, extra)}
                      getOptionLabel={field.getOptionLabel || ((v: any) => v)}
                      getOptionSelected={
                        field?.getOptionSelected || ((o: any, v: any) => o == v)
                      }
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={field.name}
                          size="small"
                          variant="filled"
                          autoComplete="asdf"
                        />
                      )}
                    />
                  );
                }}
              />
            ) : (
              <TextField
                inputRef={register({
                  required:
                    typeof field.required === "function"
                      ? field.required(extra)
                      : field.required,

                  ...(field.validate
                    ? { validate: (value) => field.validate(value, getValues) }
                    : {}),
                })}
                size="small"
                fullWidth
                name={field.key}
                label={field.name}
                type={field.type === "date" ? "date" : "text"}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
              />
            )}
            <Box style={{ color: "red" }}>
              <ErrorMessage
                errors={errors}
                name={field.key}
                message={
                  field.key !== "end_date"
                    ? "Required"
                    : "Should be after start date."
                }
              />
            </Box>
          </Box>
        ))}
        {/* Image Uploads for Stamps / Evidence */}
        <Box>
          <Typography variant="h6">Evidence</Typography>
          {fields.map((field, index) => (
            <Box key={field.id} mb={2}>
              <Box display="flex">
                <TextField
                  inputRef={register({
                    required: false,
                  })}
                  size="small"
                  fullWidth
                  name={`evidence[${index}].name`}
                  label={"Image Name"}
                  type={"text"}
                  defaultValue={field.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="filled"
                />

                <Button type="button" onClick={() => remove(index)}>
                  Delete
                </Button>
              </Box>
              <Controller
                key={field.id}
                defaultValue={field.file_ref}
                name={`evidence[${index}].file_ref`}
                control={control}
                rules={{
                  required: false,
                }}
                render={({ onChange, onBlur, value }) => {
                  return (
                    <ImageField
                      onChange={(e, v) => {
                        onChange(e.target.files);
                      }}
                      value={value || null}
                    />
                  );
                }}
              />
            </Box>
          ))}
          <Button type="button" onClick={() => append({ confirmed: false })}>
            Add Image
          </Button>
        </Box>

        <Button
          type="submit"
          variant="contained"
          disabled={saving}
          startIcon={saving ? <CircularProgress size={20} /> : null}
        >
          {props.data ? "Update" : "Add and Notify User"}
        </Button>
      </form>
    </Box>
  );
};
