import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { format } from "date-fns";
import { orderBy } from "lodash/fp";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export function BorderCrossingsTable(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell align="right">Entry Date</TableCell>
            <TableCell align="right">Exit Date</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(["start_date"], ["desc"], props.trips).map((row) => (
            <TableRow
              key={row.name}
              style={{
                backgroundColor:
                  row.id === props.selected ? "rgba(0,0,255,0.05)" : undefined,
              }}
            >
              <TableCell component="th" scope="row">
                {row.country}
              </TableCell>
              <TableCell align="right">
                {format(row.start_date.toDate(), "MM-dd-yyyy")}
              </TableCell>
              <TableCell align="right">
                {row.end_date
                  ? format(row.end_date.toDate(), "MM-dd-yyyy")
                  : null}
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => props.onEdit(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => props.onDelete(row)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
