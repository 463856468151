export const COUNTRIES = [
  "Ascension Island",
  "Andorra",
  "United Arab Emirates",
  "Afghanistan",
  "Antigua & Barbuda",
  "Anguilla",
  "Albania",
  "Armenia",
  "Angola",
  "Antarctica",
  "Argentina",
  "American Samoa",
  "Austria",
  "Australia",
  "Aruba",
  "Åland Islands",
  "Azerbaijan",
  "Bosnia & Herzegovina",
  "Barbados",
  "Bangladesh",
  "Belgium",
  "Burkina Faso",
  "Bulgaria",
  "Bahrain",
  "Burundi",
  "Benin",
  "Saint Barthélemy",
  "Bermuda",
  "Brunei Darussalam",
  "Bolivia (Plurinational State of)",
  "Brazil",
  "Bahamas",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Belarus",
  "Belize",
  "Canada",
  "Cocos (Keeling) Islands",
  "Congo",
  "Central African Republic",
  "Switzerland",
  "Côte d’Ivoire",
  "Cook Islands",
  "Chile",
  "Cameroon",
  "China",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Cabo Verde",
  "Curaçao",
  "Christmas Island",
  "Cyprus",
  "Czech Republic",
  "Germany",
  "Djibouti",
  "Denmark",
  "Dominica",
  "Dominican Republic",
  "Algeria",
  "Ecuador",
  "Estonia",
  "Egypt",
  "Western Sahara",
  "Eritrea",
  "Spain",
  "Ethiopia",
  "Finland",
  "Fiji",
  "Falkland Islands (Malvinas)",
  "Micronesia (Federated States of)",
  "Faroe Islands",
  "France",
  "Gabon",
  "United Kingdom of Great Britain and Northern Ireland",
  "Grenada",
  "Georgia",
  "French Guiana",
  "Guernsey",
  "Ghana",
  "Gibraltar",
  "Greenland",
  "Gambia (Republic of The)",
  "Guinea",
  "Guadeloupe",
  "Equatorial Guinea",
  "Greece",
  "South Georgia & South Sandwich Islands",
  "Guatemala",
  "Guam",
  "Guinea Bissau",
  "Guyana",
  "Hong Kong",
  "Heard Island & McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Indonesia",
  "Ireland",
  "Israel",
  "Isle of Man",
  "India",
  "British Indian Ocean Territory",
  "Iraq",
  "Iran (Islamic Republic of)",
  "Iceland",
  "Italy",
  "Jersey",
  "Jamaica",
  "Jordan",
  "Japan",
  "Kenya",
  "Kyrgyzstan",
  "Cambodia",
  "Kiribati",
  "Comoros",
  "Saint Kitts and Nevis",
  "Democratic People's Republic of Korea",
  "Republic of Korea",
  "Kuwait",
  "Cayman Islands",
  "Kazakhstan",
  "Lao People's Democratic Republic",
  "Lebanon",
  "Saint Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Liberia",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Libya",
  "Morocco",
  "Monaco",
  "Republic of Moldova",
  "Montenegro",
  "Saint Martin (French part)",
  "Madagascar",
  "Marshall Islands",
  "Macedonia",
  "Mali",
  "Myanmar",
  "Mongolia",
  "Macao",
  "Northern Mariana Islands",
  "Martinique",
  "Mauritania",
  "Montserrat",
  "Malta",
  "Mauritius",
  "Maldives",
  "Malawi",
  "Mexico",
  "Malaysia",
  "Mozambique",
  "Namibia",
  "New Caledonia",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "Niue",
  "New Zealand",
  "Oman",
  "Panama",
  "Peru",
  "French Polynesia",
  "Papua New Guinea",
  "Philippines",
  "Pakistan",
  "Poland",
  "Saint Pierre and Miquelon",
  "Pitcairn",
  "Puerto Rico",
  "Palestine",
  "Portugal",
  "Palau",
  "Paraguay",
  "Qatar",
  "Réunion",
  "Romania",
  "Serbia",
  "Russian Federation",
  "Rwanda",
  "Saudi Arabia",
  "Solomon Islands",
  "Seychelles",
  "Sudan",
  "Sweden",
  "Singapore",
  "Saint Helena",
  "Slovenia",
  "Svalbard & Jan Mayen",
  "Slovakia",
  "Sierra Leone",
  "San Marino",
  "Senegal",
  "Somalia",
  "Suriname",
  "South Sudan",
  "São Tomé & Príncipe",
  "El Salvador",
  "Sint Maarten (Dutch part)",
  "Syrian Arab Republic",
  "Tristan da Cunha",
  "Turks & Caicos Islands",
  "Chad",
  "French Southern Territories",
  "Togo",
  "Thailand",
  "Tajikistan",
  "Tokelau",
  "Timor-Leste",
  "Turkmenistan",
  "Tunisia",
  "Tonga",
  "Turkey",
  "Trinidad & Tobago",
  "Tuvalu",
  "Taiwan",
  "United Republic of Tanzania",
  "Ukraine",
  "Uganda",
  "United States Minor Outlying Islands",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vatican City (Holy See)",
  "Saint Vincent and the Grenadines",
  "Venezuela (Bolivarian Republic of)",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Vietnam",
  "Vanuatu",
  "Wallis & Futuna",
  "Samoa",
  "Kosovo",
  "Yemen",
  "Mayotte",
  "South Africa",
  "Zambia",
  "Zimbabwe",
];

export const VISA_CATEGORIES = [
  "A-1",
  "A-2",
  "A-3",
  "AM1",
  "AM2",
  "AM3",
  "B-1",
  "B-2",
  "B-1/B-2",
  "BCC",
  "BC1",
  "BC2",
  "BC3",
  "BC6",
  "B11",
  "B12",
  "B21",
  "B22",
  "B23",
  "B24",
  "B25",
  "BX1",
  "BX2",
  "BX3",
  "B31",
  "B32",
  "B33",
  "C-1",
  "C-1/D",
  "C-2",
  "C-3",
  "CR-1",
  "CR-2",
  "CW-1",
  "CW-2",
  "C51",
  "C52",
  "C53",
  "D",
  "E-1",
  "E-2",
  "E-2C",
  "E-3",
  "E-3D",
  "E-3R",
  "E-W3",
  "EB-1",
  "EB-2",
  "EB-3",
  "EB-4",
  "EB-5",
  "E11",
  "E12",
  "E13",
  "E14",
  "E15",
  "E21",
  "E22",
  "E23",
  "E31",
  "E32",
  "E34",
  "E35",
  "EW3",
  "EW4",
  "EW5",
  "E5",
  "F-1",
  "F-2",
  "F-3",
  "F1",
  "F3",
  "F4",
  "F2A",
  "F2B",
  "F11",
  "F12",
  "F21",
  "F22",
  "F23",
  "F24",
  "F25",
  "FX1",
  "FX2",
  "FX3",
  "F31",
  "F32",
  "F33",
  "F41",
  "F42",
  "F43",
  "G-1",
  "G-2",
  "G-3",
  "G-4",
  "G-5",
  "GB",
  "GT",
  "H-1B",
  "H-1B1",
  "H-1B1",
  "H-1C",
  "H-2A",
  "H-2B",
  "H-3",
  "H-4",
  "I",
  "I51",
  "I52",
  "I53",
  "IB-1",
  "IB-2",
  "IB-3",
  "IR-1",
  "IR-2",
  "IR-3",
  "IR-4",
  "IR-5",
  "IH3",
  "IH4",
  "IW-1",
  "IW-2",
  "J-1",
  "J-2",
  "K-1",
  "K-2",
  "K-3",
  "K-4",
  "L-1A",
  "L-1B",
  "L-2",
  "M-1",
  "M-2",
  "M-3",
  "N-8",
  "N-9",
  "NATO-1",
  "NATO-2",
  "NATO-3",
  "NATO-4",
  "NATO-5",
  "NATO-6",
  "NATO-7",
  "O-1",
  "O-2",
  "O-3",
  "P-1",
  "P-2",
  "P-3",
  "P-4",
  "Q-1",
  "R-1",
  "R-2",
  "R51",
  "R52",
  "R53",
  "S-5",
  "S-6",
  "S-7",
  "SB-1",
  "SC1",
  "SC2",
  "SD-1",
  "SD-2",
  "SD-3",
  "SE-1",
  "SE-2",
  "SE-3",
  "SF-1",
  "SF-2",
  "SG-1",
  "SH-1",
  "SH-2",
  "SJ-1",
  "SJ-2",
  "SK1",
  "SK2",
  "SK3",
  "SK4",
  "SM1",
  "SM2",
  "SM3",
  "SM4",
  "SM5",
  "SN1",
  "SN2",
  "SN3",
  "SN4",
  "SR-1",
  "SR-2",
  "SR-3",
  "SI",
  "SIJS",
  "SQ",
  "T-1",
  "T-2",
  "T-3",
  "T-4",
  "T-5",
  "T-6",
  "T51",
  "T52",
  "T53",
  "TN",
  "TD",
  "TN/TD",
  "U-1",
  "U-2",
  "U-3",
  "U-4",
  "U-5",
  "V-1",
  "V-2",
  "V-3",
  "DV-1",
  "DV-2",
  "DV-3",
  "VI-5",
  "ESTA",
  "No visa required",
  "Other",
];

export const AIRPORTS = [
  "Anaa Airport",
  "Arrabury Airport",
  "El Arish International Airport",
  "Adado Airport",
  "Rabah Bitat Airport (Les Salines Airport)",
  "Apalachicola Regional Airport",
  "Arapoti Airport",
  "Merzbrück Airport",
  "Arraias Airport",
  "Cayana Airstrip",
  "Aranuka Airport",
  "Aalborg Airport",
  "Mala Mala Airport",
  "Al Ain International Airport",
  "Anaco Airport",
  "Aji Pangeran Tumenggung Pranoto International Airport",
  "Anapa Airport",
  "Aarhus Airport",
  "Apalapsili Airport",
  "Altay Airport",
  "Asau Airport",
  "Allah Valley Airport",
  "Araxá Airport",
  "Al Ghaydah Airport",
  "Quetzaltenango Airport",
  "Abakan International Airport",
  "Asaba International Airport",
  "Albacete Airport",
  "Abadan International Airport",
  "Lehigh Valley International Airport",
  "Abaiang Atoll Airport",
  "Abingdon Airport",
  "Alpha Airport",
  "Abilene Regional Airport",
  "Port Bouet Airport (Felix Houphouet Boigny Int'l)",
  "Kabri Dar Airport",
  "Ambler Airport (FAA: AFM)",
  "Northern Peninsula Airport",
  "Albina Airstrip",
  "Aboisso Airport",
  "Atkamba Airport",
  "Albuquerque International Sunport",
  "Aberdeen Regional Airport",
  "Abu Simbel Airport",
  "Al-Baha Domestic Airport",
  "Haliwen Airport",
  "Nnamdi Azikiwe International Airport",
  "Abau Airport",
  "Albury Airport",
  "Southwest Georgia Regional Airport",
  "Aberdeen Airport",
  "General Juan N. Álvarez International Airport",
  "Antrim County Airport",
  "Kotoka International Airport",
  "Alcides Fernández Airport",
  "Lanzarote Airport",
  "St. Gallen–Altenrhein Airport",
  "Alderney Airport",
  "Anuradhapura Airport",
  "Nantucket Memorial Airport",
  "Aguaclara Airport",
  "Arica Airport",
  "Ciudad Acuña International Airport",
  "Sahand Airport",
  "Araracuara Airport",
  "Achinsk Airport",
  "Waco Regional Airport",
  "Achutupo Airport",
  "Arcata-Eureka Airport",
  "Xingyi Wanfenglin Airport",
  "Atlantic City International Airport",
  "Zabol Airport",
  "Adana Şakirpaşa Airport",
  "Adnan Menderes Airport",
  "Andakombe Airport",
  "Bole International Airport",
  "Aden International Airport",
  "Adıyaman Airport",
  "Lenawee County Airport",
  "Aldan Airport",
  "Arandis Airport",
  "Amman Civil Airport (Marka International Airport)",
  "Adak Airport",
  "Adelaide Airport",
  "Ardmore Municipal Airport",
  "Andes Airport",
  "Andamooka Airport",
  "Ampara Airport",
  "Kodiak Airport",
  "Robert F. Swinnie Airport (FAA: PHH)",
  "Addison Airport",
  "Ada Municipal Airport (FAA: ADH)",
  "Ardabil Airport",
  "Ed Daein Airport",
  "Andrews Field (Andrews Air Force Base)",
  "RAF Leuchars",
  "Alldays Airport",
  "Gustavo Rojas Pinilla International Airport",
  "Abemama Atoll Airport",
  "Baise Bama Airport",
  "Adareil Airport",
  "Aek Godang Airport",
  "Abéché Airport",
  "Aseki Airport",
  "Albert Lea Municipal Airport",
  "Amgu Airport",
  "Aioun el Atrouss Airport",
  "Jorge Newbery Airpark",
  "Ar Horqin Airport",
  "Sochi International Airport",
  "Ålesund Airport, Vigra",
  "Allakaket Airport (FAA: 6A8)",
  "Abu Musa Airport",
  "Alexandria International Airport",
  "Akureyri Airport",
  "San Rafael Airport",
  "Port Alfred Airport",
  "United States Air Force Academy Airfield",
  "Amalfi Airport",
  "Kondavattavan Tank Seaplane Base",
  "Alta Floresta Airport",
  "Jaffrey Airport–Silver Ranch",
  "Afton Municipal Airport",
  "Afore Airport",
  "Zarafshan Airport",
  "Afutara Airport",
  "Fort Worth Alliance Airport",
  "Afyon Airport",
  "Sabzevar Airport",
  "Agadir–Al Massira Airport",
  "Augsburg Airport",
  "Allegheny County Airport",
  "Anggi Airport",
  "Wangerooge Airfield",
  "Agen La Garenne Airport",
  "Angoram Airport",
  "Ängelholm–Helsingborg Airport (former ICAO: ESDB)",
  "Wageningen Airstrip",
  "Aguni Airport",
  "Kagua Airport",
  "Wanigela Airport",
  "Angoon Seaplane Base",
  "Magnolia Municipal Airport",
  "Málaga Airport",
  "Agrinion Airport",
  "Agra Airport",
  "Augusta Regional Airport at Bush Field",
  "Guaraní International Airport",
  "Lic. Jesús Terán Peredo International Airport",
  "Oswaldo Guevara Mujica Airport",
  "Agnew Airport",
  "Agatti Aerodrome",
  "Aggeneys Airport",
  "Abha Regional Airport",
  "Amedee Army Airfield",
  "Ardmore Downtown Executive Airport (FAA: 1F0)",
  "Ahe Airport",
  "Arapahoe Municipal Airport (FAA: 37V)",
  "Amery Municipal Airport",
  "Amahai Airport",
  "Hongyuan Airport",
  "Aishalton Airport",
  "Ashland Municipal Airport (FAA: S03)",
  "Athens Ben Epps Airport",
  "Alghero-Fertilia Airport",
  "Ahuas Airport",
  "Cherif Al Idrissi Airport",
  "Saih Rawl Airport",
  "Ambatolahy Airport",
  "Alpe d'Huez Airport",
  "Alliance Municipal Airport",
  "Ailinglaplap Airok Airport",
  "Anderson Municipal Airport (Darlington Field)",
  "Aiome Airport",
  "Assis Airport (former ICAO: SBAS)",
  "Yalinga Airport",
  "Aiambak Airport",
  "Ali-Sabieh Airport",
  "Aiken Municipal Airport",
  "Ailigandí Airport",
  "Ailuk Airport",
  "Wainwright Airport (FAA: AWI)",
  "Atlantic Municipal Airport",
  "Adampur Airport",
  "Aripuanã Airport",
  "Arorae Island Airport",
  "Aitutaki Airport",
  "Enua Airport",
  "George Downer Airport",
  "Ai-Ais Airport",
  "Lee C. Fine Memorial Airport",
  "Ajaccio Napoleon Bonaparte Airport",
  "Al-Jawf Domestic Airport",
  "Ağrı Airport",
  "Akjoujt Airport",
  "Arak Airport",
  "Lengpui Airport",
  "Ouani Airport",
  "Arvidsjaur Airport",
  "Punta Abreojos Airstrip",
  "Santa Maria Airport",
  "Mano Dayak International Airport",
  "Ankang Wulipu Airport",
  "Atka Airport (FAA: AKA)",
  "Akron Fulton International Airport (FAA: AKR)",
  "Akola Airport",
  "Akieni Airport",
  "Kufra Airport",
  "Anguganak Airport",
  "Prince Sultan Air Base",
  "Akiak Airport",
  "Asahikawa Airport",
  "Akhiok Airport",
  "Auckland Airport",
  "Zakouma Airport",
  "King Salmon Airport",
  "Colorado Plains Regional Airport",
  "Anaktuvuk Pass Airport",
  "Gunung Batin Airport",
  "Akure Airport",
  "Auki Gwaunaru'u Airport",
  "RAF Akrotiri",
  "Aksu Airport",
  "Akulivik Airport",
  "Aghajari Airport",
  "Aktobe Airport",
  "Sittwe Airport",
  "Almaty International Airport",
  "Albany International Airport",
  "Alicante–Elche Airport",
  "Alerta Airport",
  "Alpine–Casparis Municipal Airport (FAA: E38)",
  "Alta Airport",
  "Houari Boumediene Airport",
  "Albany Airport",
  "Alice International Airport",
  "Alexander Bay Airport",
  "Asella Airport",
  "Albenga Airport",
  "Alamogordo–White Sands Regional Airport",
  "St. Louis Regional Airport",
  "Waterloo Regional Airport",
  "Aleppo International Airport",
  "Alegrete Airport",
  "Alexandra Aerodrome",
  "San Luis Valley Regional Airport",
  "Alenquer Airport",
  "Alula Airport",
  "Walla Walla Regional Airport",
  "Thomas C. Russell Field",
  "El Nouzha Airport",
  "Alitak Seaplane Base",
  "Rick Husband Amarillo International Airport",
  "Ambilobe Airport",
  "Am Timan Airport",
  "Sardar Vallabhbhai Patel International Airport",
  "Alto Molocue Airport",
  "Ama Airport",
  "Amboin Airport",
  "Arba Minch Airport",
  "Selaparang Airport (closed 2011)",
  "Almenara Airport",
  "Animas Air Park (FAA: 00C)",
  "Puerto Armuelles Airport",
  "Queen Alia International Airport",
  "Gratiot Community Airport",
  "Mao Airport",
  "Ampanihy Airport",
  "Pattimura Airport",
  "Amsterdam Airport Schiphol",
  "Amata Airport",
  "Amanab Airport",
  "Amderma Airport",
  "Ames Municipal Airport",
  "Ammaroo Airport",
  "Ambatomainty Airport",
  "Ardmore Airport",
  "Angama Airstrip",
  "Anniston Regional Airport",
  "Ted Stevens Anchorage International Airport",
  "Anderson Regional Airport",
  "Angers – Loire Airport",
  "Cerro Moreno International Airport",
  "Angoulême – Cognac International Airport",
  "Aniak Airport",
  "Zanaga Airport",
  "Etimesgut Air Base",
  "Andulo Airport",
  "Antsirabato Airport",
  "Annette Island Airport",
  "Angoche Airport",
  "Lee Airport",
  "Tri-State Steuben County Airport",
  "Antwerp International Airport",
  "Andahuaylas Airport",
  "V. C. Bird International Airport",
  "Anvik Airport",
  "Ainsworth Regional Airport",
  "Andøya Airport, Andenes",
  "Anthony Municipal Airport",
  "Angus Downs Airport",
  "Aroa Airport",
  "Annanberg Airport",
  "Leipzig–Altenburg Airport",
  "Abou-Deïa Airport",
  "Anadolu Airport",
  "Anshan Teng'ao Airport",
  "Lima Allen County Airport",
  "Ancona Falconara Airport",
  "Aomori Airport",
  "Karpathos Island National Airport",
  "Paso de los Libres Airport",
  "Adam Airport",
  "Arona Airport",
  "Altoona–Blair County Airport",
  "Alférez FAP Alfredo Vladimir Sara Bauer Airport",
  "Sultan Abdul Halim Airport",
  "Amook Bay Seaplane Base (FAA: AK81)",
  "Aosta Valley Airport",
  "Attapeu International Airport",
  "Centennial Airport",
  "Apolo Airport",
  "Napa County Airport",
  "Naples Municipal Airport",
  "Phillips Army Airfield",
  "A.P. Hill Army Airfield",
  "Captain Luis F. Gómez Niño Air Base",
  "Apataki Airport",
  "Nampula Airport",
  "Alpena County Regional Airport",
  "Antonio Roldán Betancourt Airport",
  "Asapa Airport",
  "Arapiraca Airport",
  "April River Airport",
  "Anápolis Airport",
  "Marion County Airport (Brown Field)",
  "Apucarana Airport",
  "Apple Valley Airport",
  "Faleolo International Airport",
  "Arapongas Airport",
  "Alto Parnaíba Airport",
  "Zapala Airport",
  "Araraquara Airport",
  "Quiché Airport",
  "Anqing Tianzhushan Airport",
  "Al Qaisumah/Hafr Al Batin Airport",
  "King Hussein International Airport",
  "Ariquemes Airport",
  "Rodríguez Ballón International Airport",
  "Saqani Airport",
  "Girdwood Airport",
  "Acadiana Regional Airport",
  "Ann Arbor Municipal Airport",
  "Arctic Village Airport",
  "Alor Island Airport",
  "Antonio (Nery) Juarbe Pol Airport (FAA: ABO)",
  "Acaricuara Airport",
  "Walnut Ridge Regional Airport",
  "Talagi Airport",
  "Chacalluta International Airport",
  "Arso Airport",
  "Arusha Airport",
  "Arly Airport",
  "Armidale Airport",
  "Stockholm Arlanda Airport",
  "Arboletes Airport",
  "Aragip Airport",
  "El Troncal Airport",
  "Alto Río Senguer Airport",
  "Aragarças Airport",
  "Watertown International Airport",
  "Araçatuba Airport",
  "Lakeland Airport (Noble F. Lee Memorial Field)",
  "Arad International Airport",
  "Aracati Airport",
  "Ararat Airport",
  "N'zeto Airport",
  "Assab International Airport",
  "Ashgabat International Airport",
  "Ascención de Guarayos Airport",
  "Andros Town International Airport",
  "Aspen–Pitkin County Airport (Sardy Field)",
  "Narimanovo Airport",
  "Ashburton Aerodrome",
  "Nashua Airport (Boire Field)",
  "RAF Ascension Island (Wideawake Field)",
  "Amami Airport",
  "Yamoussoukro Airport",
  "Harrison County Airport",
  "Asmara International Airport",
  "Talladega Municipal Airport",
  "Asosa Airport",
  "Alice Springs Airport",
  "Austin Airport (FAA: 9U3)",
  "Erkilet International Airport",
  "Astoria Regional Airport",
  "Silvio Pettirossi International Airport",
  "Amboseli Airport",
  "Aswan International Airport",
  "John F. Kennedy Memorial Airport",
  "Ashley Municipal Airport",
  "Asirim Airport",
  "Comandante FAP Germán Arias Graziani Airport",
  "Atbara Airport",
  "Arthur's Town Airport",
  "Uru Harbour Airport",
  "Antlers Municipal Airport (FAA: 80F)",
  "Chachoan Airport",
  "PAF Base Minhas",
  "Athens International Airport (Eleftherios Venizelos Airport)",
  "Artigas International Airport",
  "Antsirabe Airport",
  "Atqasuk Edward Burnell Sr. Memorial Airport",
  "Hartsfield–Jackson Atlanta International Airport",
  "Altamira Airport",
  "Namatanai Airport",
  "Ohio University Airport (Snyder Field) (FAA: UNI)",
  "Aitape Airstrip",
  "Sri Guru Ram Dass Jee International Airport",
  "Atar International Airport",
  "Artesia Municipal Airport",
  "Atmautluak Airport (FAA: 4A2)",
  "Casco Cove Coast Guard Station",
  "Ati Airport",
  "Appleton International Airport",
  "Atbasar Airport",
  "Watertown Regional Airport",
  "Assiut Airport",
  "Queen Beatrix International Airport",
  "Santiago Pérez Quiroz Airport",
  "Augustus Downs Airport",
  "Abu Rudeis Airport",
  "Auxerre – Branches Aerodrome",
  "Augusta State Airport",
  "Abu Dhabi International Airport",
  "Aua Island Airport",
  "Ambunti Airport",
  "Alakanuk Airport",
  "Aur Airport",
  "Austin Municipal Airport",
  "Auburn Municipal Airport",
  "Auburn University Regional Airport",
  "Agaun Airport",
  "Atuona Airport",
  "Aurillac – Tronquières Airport",
  "Austin–Bergstrom International Airport",
  "Atauro Airport",
  "Aurukun Airport",
  "Aumo Airport",
  "Wausau Downtown Airport",
  "Araguaína Airport",
  "Anatom Airport",
  "Aurora Municipal Airport (FAA: ARR)",
  "Anshun Huangguoshu Airport",
  "Aviano Air Base",
  "Auvergne Airport",
  "Máximo Gómez Airport",
  "Arvaikheer Airport",
  "Asheville Regional Airport",
  "Avignon – Provence Airport",
  "Avon Park Executive Airport",
  "Wilkes-Barre/Scranton International Airport",
  "Avu Avu Airport",
  "Avalon Airport",
  "Marana Regional Airport (FAA: AVQ)",
  "Catalina Airport",
  "Awasa Airport",
  "Awaba Airport",
  "Aniwa Airport",
  "Alowe Airport",
  "Wake Island Airfield",
  "West Memphis Municipal Airport",
  "Alton Downs Airport",
  "Austral Downs Airport",
  "Awar Airport",
  "Ahvaz International Airport",
  "Clayton J. Lloyd International Airport",
  "Maxson Airfield (FAA: 89N)",
  "Aramac Airport",
  "Alexandroupoli Airport (Dimokritos Airport)",
  "Xanxere Airport",
  "Alxa Left Banner Bayanhot Airport",
  "Algona Municipal Airport (FAA: AXA)",
  "Amakusa Airfield",
  "Ataq Airport",
  "Alexandria Airport",
  "El Edén International Airport",
  "Alexandria Municipal Airport (Chandler Field)",
  "Spring Point Airport",
  "Arutua Airport",
  "Altus/Quartz Mountain Regional Airport",
  "Akita Airport",
  "Axum Airport",
  "Neil Armstrong Airport",
  "Angel Fire Airport",
  "Ayapel Airport",
  "Ayacucho Airport",
  "Alroy Downs Airport",
  "Yaguara Airport",
  "Yari Airport",
  "Arkalyk Airport",
  "Anthony Lagoon Airport",
  "Yas Island Seaplane Base",
  "Anyang Airport",
  "Juan de Ayolas Airport",
  "Coronel FAP Alfredo Mendívil Duarte Airport",
  "Ayers Rock Airport",
  "Ayr Airport",
  "Waycross–Ware County Airport",
  "Antalya Airport",
  "Aiyura Airport",
  "Ayawasi Airport",
  "Tnte. Gral. Gerardo Pérez Pinedo Airport",
  "Arugam Bay Seaplane Base",
  "Phoenix–Mesa Gateway Airport (FAA: IWA)",
  "Amazon Bay Airport",
  "Shahid Sadooghi Airport",
  "Pablo L. Sidar Airport",
  "Al Bateen Executive Airport",
  "Fazenda Tucunaré Airport",
  "Andizhan Airport",
  "Kalamazoo/Battle Creek International Airport",
  "Jorge Jiménez Cantú National Airport",
  "Touat-Cheikh Sidi Mohamed Belkebir Airport",
  "Samaná El Catey International Airport",
  "Zapatoca Airport",
  "Ambriz Airport",
  "Bialla Airport",
  "Beale Air Force Base",
  "Barranca de Upia Airport",
  "Barksdale Air Force Base",
  "Barcelonnette – Saint-Pons Airport",
  "Westfield-Barnes Regional Airport",
  "Loakan Airport",
  "Bahrain International Airport",
  "Buenos Aires Airport",
  "Bali Airport",
  "Metropolitan Area",
  "Batman Airport",
  "Battle Mountain Airport (Lander County Airport)",
  "Basongo Airport",
  "Baibara Airport",
  "Ernesto Cortissoz International Airport",
  "Qionghai Bo'ao Airport",
  "Balalae Airport",
  "Chafei Amsei Airport",
  "Baotou Airport",
  "Biawonque Airport",
  "Barnaul Airport",
  "Baia Mare Airport",
  "Barcelos Airport",
  "Balmaceda Airport",
  "Benson Municipal Airport",
  "Bay City Municipal Airport (FAA: BYY)",
  "Curtis Field",
  "Butaritari Atoll Airport",
  "Stralsund–Barth Airport",
  "Biju Patnaik International Airport",
  "Bitburg Airport (former Bitburg Air Base)",
  "Kasane Airport",
  "Ballera Airport",
  "Battambang Airport",
  "Bario Airport",
  "Berbera Airport",
  "Bembridge Airport",
  "Barbuda Codrington Airport",
  "Baillif Airport",
  "Blackbushe Airport",
  "Berbérati Airport",
  "Aurel Vlaicu International Airport (Băneasa Airport)",
  "Nero-Mer Airport",
  "Broken Bow Municipal Airport",
  "Wings Field (FAA: LOM)",
  "Bambari Airport",
  "Zambezi Airport",
  "Gustavo Rizo Airport",
  "Virginia Tech Montgomery Executive Airport",
  "Bear Creek 3 Airport (FAA: Z48)",
  "Bacolod–Silay International Airport",
  "Bryce Canyon Airport",
  "Bouca Airport",
  "Bemichi Airport",
  "Baucau Airport",
  "Barcaldine Airport",
  "Bolwarra Airport",
  "Barra del Colorado Airport",
  "Bacău International Airport",
  "Barcelona–El Prat Airport",
  "Baco Airport (Jinka Airport)",
  "Bambu Airport",
  "Brak Airport",
  "Novo Campo Airport",
  "Southern Seaplane Airport (FAA: 65LA)",
  "Boca Raton Airport",
  "Bauchi State Airport",
  "Hector Silva Airstrip",
  "Benguerra Island Airport",
  "Beloretsk Airport",
  "Bulchi Airport",
  "Bickerton Island Airport",
  "L.F. Wade International Airport",
  "Bundaberg Airport",
  "Barra do Corda Airport",
  "Badu Island Airport",
  "Baudette International Airport",
  "Rinkenberger RLA Airport (FAA: 3IS8)",
  "Blanding Municipal Airport",
  "Bandar Lengeh Airport",
  "Bird Island Airport",
  "Syamsudin Noor Airport",
  "Soko Airport",
  "Bradley International Airport",
  "Bandırma Airport",
  "Talhar Airport",
  "Husein Sastranegara International Airport",
  "Bhadrapur Airport (Chandragadhi Airport)",
  "Vadodara Airport (Civil Airport Harni)",
  "Igor I. Sikorsky Memorial Airport",
  "Brindisi – Salento Airport",
  "Gbadolite Airport",
  "Bardufoss Airport",
  "Moba Airport",
  "Bedford Downs Airport",
  "Broadus Airport",
  "Bandon State Airport (FAA: S05)",
  "Baindoung Airport",
  "Bereina Airport",
  "Benbecula Airport",
  "Beech Factory Airport",
  "Laurence G. Hanscom Field",
  "Beagle Bay Airport",
  "Bluefields Airport",
  "Belgrade Nikola Tesla Airport",
  "Southwest Michigan Regional Airport",
  "Beica Airport",
  "Kalimarau Airport",
  "Bareilly Airport",
  "Val de Cans International Airport",
  "Beni Mellal Airport",
  "Benina International Airport",
  "Lake Macquarie Airport (Belmont Airport)",
  "Bellary Airport",
  "RAF Honington",
  "Berlin Brandenburg Airport (in construction)",
  "Brest Bretagne Airport",
  "Bethel Airport",
  "Bedourie Airport",
  "Beersheba Airport",
  "Beira Airport",
  "RAF Benson",
  "Beirut–Rafic Hariri International Airport / Beirut Air Base",
  "Beru Island Airport",
  "Bahía Negra Airport",
  "Bloomfield Airport",
  "Bradford Regional Airport",
  "Bielefeld Airport",
  "Western Nebraska Regional Airport (William B. Heilig Field)",
  "Bullfrog Basin Airport (FAA: U07)",
  "Bacacheri Airport",
  "Boeing Field/King County International Airport",
  "Bijie Feixiong Airport",
  "Buckley Air Force Base",
  "Meadows Field Airport",
  "Mobile Downtown Airport",
  "Bloemfontein Airport",
  "Buffalo Range Airport",
  "Beaver County Airport (FAA: BVI)",
  "Bahía Piña Airport",
  "Virgil I. Grissom Municipal Airport",
  "Belfast International Airport",
  "Beaufort County Airport (FAA: ARW)",
  "Bengbu Airport",
  "Buriram Airport",
  "Sidi Bel Abbès Airport",
  "Bafoussam Airport",
  "Palonegro International Airport",
  "Booué Airport",
  "Bragança Airport",
  "Hutchinson County Airport",
  "Decatur County Industrial Air Park",
  "Bangui M'Poko International Airport",
  "Bingöl Airport",
  "Abbaye Airport",
  "Grantley Adams International Airport",
  "Borgarfjörður Eystri Airport",
  "Big Creek Airport",
  "Baglung Airport",
  "Greater Binghamton Airport (Edwin A. Link Field)",
  "Belaya Gora Airport",
  "Bergen Airport, Flesland",
  "Bongo Airport",
  "Big Lake Airport",
  "Bangor International Airport",
  "Bagdad Airport (FAA: E51)",
  "Bangassou Airport",
  "Bento Gonçalves Airport",
  "Baghdad International Airport",
  "Comandante Gustavo Kraemer Airport",
  "Il Caravaggio International Airport (Orio al Serio Airport)",
  "Braga Airport",
  "Los Perales Airport",
  "Hancock County-Bar Harbor Airport",
  "George Best Belfast City Airport",
  "Woodbourne Airport",
  "Bahía Cupica Airport",
  "Brus Laguna Airport",
  "Bisha Domestic Airport",
  "Comandante Espora Airport",
  "Bhuj Airport / Bhuj Rudra Mata Air Force Base",
  "Bukhara International Airport",
  "Bahía de los Ángeles Airport",
  "Birmingham–Shuttlesworth International Airport",
  "Beihan Airport",
  "Raja Bhoj Airport",
  "Bhojpur Airport",
  "Broken Hill Airport",
  "Bharatpur Airport",
  "Bathurst Airport",
  "Brighton Downs Airport",
  "Bhavnagar Airport",
  "Bahawalpur Airport",
  "Bhagatanwala Airport",
  "Birmingham Airport",
  "Beihai Fucheng Airport",
  "Metropolitan Area",
  "Bastia – Poretta Airport",
  "Baidoa Airport",
  "Block Island State Airport",
  "Beatrice Municipal Airport",
  "Biggs Army Airfield",
  "Allen Army Airfield",
  "Eastern Sierra Regional Airport",
  "Bikini Atoll Airport",
  "Biliau Airport",
  "Frans Kaisiepo Airport",
  "Billings Logan International Airport",
  "South Bimini Airport",
  "Bamyan Airport",
  "Bilbao Airport",
  "Bulimba Airport",
  "Biarritz Pays Basque Airport",
  "Biratnagar Airport",
  "Bismarck Municipal Airport",
  "Baitadi Airport",
  "Bíldudalur Airport",
  "Bria Airport",
  "Billiluna Airport",
  "Keesler Air Force Base",
  "Bhisho Airport",
  "Bimin Airport",
  "Soummam – Abane Ramdane Airport",
  "Bojnord Airport",
  "Rocky Mountain Metropolitan Airport",
  "Bakkafjörður Airport",
  "Baleela Airport",
  "Båtsfjord Airport",
  "Kotamobagu Mopait Airport",
  "Bajhang Airport",
  "Bemidji Regional Airport",
  "Wayne County Airport",
  "Benjina Airport (Nangasuri Airport)",
  "Banjul International Airport",
  "Bujumbura International Airport",
  "Bajone Airport",
  "Bermejo Airport",
  "Arthur Siqueira–Bragança Paulista State Airport",
  "Bahja Airport",
  "Bahir Dar Airport",
  "Metropolitan Area",
  "Bentota River Airport",
  "Bajura Airport",
  "Milas–Bodrum Airport",
  "Bajawa Soa Airport",
  "Del Bajío International Airport (Guanajuato Int'l Airport)",
  "Batajnica Air Base",
  "Badajoz Airport (Talavera la Real Air Base)",
  "Nal Airport",
  "Buckland Airport (FAA: BVK)",
  "Stephens County Airport",
  "Baker City Municipal Airport",
  "Lake Brooks Seaplane Base",
  "Branson Airport (FAA: BBG)",
  "Barking Sands PMRF",
  "Kota Kinabalu International Airport",
  "Boké Baralande Airport",
  "Suvarnabhumi Airport",
  "Cleveland Burke Lakefront Airport",
  "Bakelalan Airport",
  "Balkanabat Airport",
  "Bamako–Sénou International Airport",
  "Barkly Downs Airport",
  "Blackall Airport",
  "Bokoro Airport",
  "Fatmawati Soekarno Airport",
  "Blackstone Army Airfield (Allen C. Perkinson Airport)",
  "Betioky Airport",
  "Raleigh County Memorial Airport",
  "Brookings Regional Airport",
  "Kavumu Airport",
  "Bukoba Airport",
  "General José Antonio Anzoátegui International Airport",
  "Panamá Pacífico International Airport (former Howard AFB)",
  "Bali Airport",
  "Boulder City Municipal Airport (FAA: 61B)",
  "Dala Airport (Borlänge Airport)",
  "Mercer County Airport",
  "Belaga Airport",
  "Blythe Airport",
  "Bellingham International Airport",
  "Mostépha Ben Boulaid Airport",
  "Blackpool Airport",
  "Billund Airport",
  "Monmouth Executive Airport",
  "Benalla Airport",
  "Blönduós Airport",
  "Huallaga Airport",
  "Bologna Guglielmo Marconi Airport",
  "Kempegowda International Airport",
  "Bollon Airport",
  "Blackwater Airport",
  "Blue Canyon–Nyack Airport",
  "MidAmerica St. Louis Airport / Scott Air Force Base",
  "Belluno Airport",
  "Belmullet Aerodrome",
  "Chileka International Airport",
  "Stockholm Bromma Airport",
  "Bumba Airport",
  "Brigham City Airport",
  "Belo sur Tsiribihina Airport",
  "Broome International Airport",
  "Bakouma Airport",
  "Monroe County Airport",
  "Bomai Airport",
  "Central Illinois Regional Airport",
  "Baramita Airport",
  "Borkum Airfield",
  "Berlin Regional Airport",
  "Bitam Airport",
  "Bamarni Airport",
  "Bhamo Airport",
  "Brampton Island Airport",
  "Bamburi Airport",
  "Baltrum Airport",
  "Socrates Mariani Bittencourt Airport",
  "Beaumont Municipal Airport",
  "Sultan Muhammad Salahudin Airport (Bima Airport)",
  "Buon Ma Thuot Airport",
  "Bordj Mokhtar Airport",
  "Big Mountain Air Force Station (FAA: 37AK)",
  "Île Art – Waala Airport",
  "Bamu Airport",
  "Nashville International Airport",
  "Boende Airport",
  "Beni Airport",
  "Bandar Abbas International Airport",
  "Brisbane Airport",
  "Warm Springs Bay Seaplane Base",
  "Banning Municipal Airport",
  "Benin Airport",
  "Ballina Byron Gateway Airport",
  "Barnwell Regional Airport",
  "Bodinumu Airport",
  "Brønnøysund Airport, Brønnøy",
  "Burns Municipal Airport",
  "Bannu Airport",
  "Baganga Airport",
  "Banfora Airport",
  "Barinas Airport",
  "Bundi Airport",
  "Blumenau Airport",
  "Boana Airport",
  "Boone Municipal Airport",
  "Banja Luka International Airport",
  "Bellona/Anua Airport",
  "Banz Airport",
  "Boma Airport",
  "Bora Bora Airport (Motu Mute Airport)",
  'Bocas del Toro "Isla Colón" International Airport',
  "Bordeaux–Mérignac Airport",
  "Boundji Airport",
  "El Dorado International Airport",
  "Bournemouth Airport",
  "Boise Air Terminal (Gowen Field)",
  "Burgas Airport",
  "Brookings Airport",
  "Ballykelly Airfield (RAF Ballykelly)",
  "Chhatrapati Shivaji International Airport",
  "Flamingo International Airport",
  "Bodø Airport",
  "Bouar Airport",
  "Boku Airport",
  "Logan International Airport",
  "Bosset Airport",
  "Bourges Airport",
  "Boang Island Airport",
  "Bartow Municipal Airport",
  "Borroloola Airport",
  "Bobo Dioulasso Airport",
  "Bozoum Airport",
  "Boridi Airport",
  "Bamenda Airport",
  "Bapi Airport",
  "Qinhuangdao Beidaihe Airport",
  "Batuna Airport",
  "Barra do Garças Airport",
  "Bislig Airport",
  "Miley Memorial Field",
  "Biangabip Airport",
  "Bole Alashankou Airport",
  "Begumpet Airport",
  "Sultan Aji Muhammad Sulaiman Airport",
  "Porto Seguro Airport",
  "Jack Brooks Regional Airport",
  "Qamdo Bamda Airport",
  "Besalampy Airport",
  "Dr. Juan C. Angara Airport",
  "Busselton Margaret River Airport",
  "Bubaque Airport",
  "Bogorodskoye Airport",
  "London Biggin Hill Airport",
  "Bagani Airport",
  "Batagay Airport",
  "Brunswick Golden Isles Airport",
  "Boulia Airport",
  "Rafael Hernández Airport",
  "Tehini Airport",
  "Barra Airport",
  "Ignatyevo Airport",
  "Brest Airport",
  "J. F. Mitchell Airport",
  "Bartlett Cove Seaplane Base",
  "Balgo Hill Airport",
  "Barreiras Airport",
  "Barreirinhas Airport",
  "San Carlos de Bariloche Airport",
  "Brainerd Lakes Regional Airport",
  "Bremen Airport",
  "Brahman Airport",
  "Bari Karol Wojtyła Airport",
  "Bright Airport",
  "Bourke Airport",
  "Southeast Iowa Regional Airport",
  "Jacinto Lara International Airport",
  "Bern Airport",
  "Brownsville/South Padre Island International Airport",
  "Biaru Airport",
  "Brno–Tuřany Airport",
  "Barra Airport",
  "Bristol Airport",
  "Bathurst Island Airport",
  "Brussels Airport (Zaventem Airport)",
  "Bremerhaven Airport",
  "Wiley Post–Will Rogers Memorial Airport",
  "María Montez International Airport",
  "Samuels Field",
  "Bender Qassim International Airport",
  "Brasília International Airport (Presidente J. Kubitschek Int'l Airport)",
  "José Celestino Mutis Airport",
  "Baoshan Yunduan Airport",
  "Sematan Airport",
  "Bradshaw Army Airfield",
  "Bata Airport",
  "Balesin Airport",
  "Bairnsdale Airport",
  "Biskra Airport",
  "EuroAirport Basel-Mulhouse-Freiburg",
  "Bishe Kola Air Base",
  "Bossangoa Airport",
  "Basco Airport",
  "Bensbach Airport",
  "Bisbee Municipal Airport (FAA: P04)",
  "Basra International Airport",
  "Balsas Airport",
  "Bost Airport",
  "Basankusu Airport",
  "Besakoa Airport",
  "Boswell Bay Airport (FAA: AK97)",
  "Pathein Airport",
  "Bardera Airport",
  "Bertoua Airport",
  "Bétou Airport",
  "Batticaloa Airport",
  "Brunette Downs Airport",
  "Sherbro International Airport",
  "Skypark Airport",
  "Batangafo Airport",
  "Hang Nadim Airport",
  "Barter Island LRRS Airport",
  "Sultan Iskandar Muda International Airport",
  "Bratsk Airport",
  "W. K. Kellogg Airport",
  "Bert Mooney Airport",
  "Marlboro County Jetport (FAA: BBP)",
  "Botopasi Airstrip",
  "Butler County Airport (K.W. Scholter Field)",
  "Butare Airport",
  "Baton Rouge Metropolitan Airport (Ryan Field)",
  "M. R. Štefánik Airport",
  "Bettles Airport",
  "Bintulu Airport",
  "Burlington International Airport",
  "Batu Licin Airport",
  "Betoota Airport",
  "Beatty Airport",
  "Buka Island Airport",
  "Cram Field",
  "Burketown Airport",
  "Budapest Ferenc Liszt International Airport",
  "Metropolitan Area",
  "Buffalo Niagara International Airport",
  "Benguela Airport (Gen. V. Deslandes Airport)",
  "Metropolitan Area",
  "Bokondini Airport",
  "Bou Saada Airport",
  "Albuq Airport",
  "Bulolo Airport",
  "Butler Memorial Airport",
  "Gerardo Tobar López Airport",
  "Burao Airport",
  "Bathinda Airport (Bhisiana Air Force Station)",
  "Joshua Mqabuko Nkomo International Airport",
  "Bob Hope Airport",
  "Batumi International Airport (Alexander Kartveli Batumi Int'l Airport)",
  "Bathpalathang Airport",
  "Muara Bungo Airport",
  "Placeres Airport",
  "Betoambari Airport",
  "Bunia Airport",
  "Bunbury Airport",
  "Bushehr Airport",
  "Beauvais–Tillé Airport",
  "Boa Vista International Airport",
  "Aristides Pereira International Airport (Rabil Airport)",
  "Brive–Souillac Airport",
  "Dama Airport",
  "Berlevåg Airport",
  "Vilhena Airport (Brigadeiro Camarão Airport)",
  "Birdsville Airport",
  "Bovanenkovo Airport",
  "Huacaraje Airport",
  "Baures Airport",
  "Belmonte Airport",
  "Bartlesville Municipal Airport",
  "Bolovip Airport",
  "Esperadinha Airport",
  "Breves Airport",
  "Beluga Airport (FAA: BLG)",
  "Burevestnik Airport",
  "Batavia Downs Airport",
  "Batesville Regional Airport",
  "Beverly Municipal Airport",
  "Beverley Springs Airport",
  "Gautam Buddha Airport",
  "Barrow Island Airport",
  "Brawley Municipal Airport",
  "Brownwood Regional Airport",
  "Braunschweig–Wolfsburg Airport",
  "Barrow/Walney Island Airport",
  "Bowling Green-Warren County Regional Airport",
  "RMAF Butterworth",
  "Baltimore/Washington International Thurgood Marshall Airport",
  "Bawan Airport",
  "Bol Airport (Brač Airport)",
  "Blackwell–Tonkawa Municipal Airport (FAA: BKN)",
  "Bowman Municipal Airport (FAA: BPP)",
  "Brunei International Airport",
  "Balakovo Airport",
  "Bewani Airport",
  "Brewarrina Airport",
  "Burnie Airport",
  "Bankstown Airport",
  "Las Brujas Airport",
  "Banyuwangi Airport",
  "RAF Bentwaters",
  "George R. Carr Memorial Air Field",
  "Babo Airport",
  "Bade Airport",
  "Bakel Airport",
  "Bellburn Airstrip",
  "Bendigo Airport",
  "Balkhash Airport",
  "Boundiali Airport",
  "Boraldai Airport",
  "Buckeye Municipal Airport",
  "Blue Lagoon Seaplane Base",
  "Batom Airport",
  "Bodrum-Imsik Airport",
  "Buochs Airport",
  "Biała Podlaska Airport",
  "Bam Airport",
  "Borrego Valley Airport (FAA: L08)",
  "PT Badak Bontang Airport",
  "Bancasi Airport",
  "Breiðdalsvík Airport",
  "Bawean Airport",
  "Borama Airport",
  "Krayniy Airport",
  "Bunsil Airport",
  "Boundary Airport",
  "Dibba Airport",
  "Yacuiba Airport",
  "Al Bayda Airport",
  "Albert – Picardie Airport",
  "Johnson County Airport",
  "Arkansas International Airport",
  "Burley Municipal Airport",
  "Beja Airport",
  "Bouaké Airport",
  "Bella Yella Airport",
  "Carlos Manuel de Céspedes Airport",
  "Bayankhongor Airport",
  "Bonito Airport",
  "Barimunya Airport",
  "Bunyu Airport",
  "Læsø Airport",
  "Bicycle Lake Army Airfield (Fort Irwin)",
  "Bantry Aerodrome",
  "Bindlacher Berg Airport",
  "Beira Lake Seaplane Base",
  "Blakely Island Airport (FAA: 38WA)",
  "Baniyala Airport",
  "San Pedro Airport",
  "Bazaruto Island Airport",
  "Umberto Modiano Airport",
  "Balranald Airport",
  "Philip S. W. Goldson International Airport",
  "Benton Field",
  "Bydgoszcz Ignacy Jan Paderewski Airport",
  "Bumi Hills Airstrip",
  "Balıkesir Airport (Merkez Airport)",
  "Bryansk International Airport",
  "Barisal Airport",
  "Bemolanga Airport",
  "Bozeman Yellowstone International Airport (Gallatin Field)",
  "Bolzano Airport",
  "Bizant Airport",
  "Béziers Cap d'Agde Airport",
  "Eagle Air Park",
  "Buta Zega Airport",
  "Maya-Maya Airport",
  "Bazhong Enyang Airport",
  "Bălți International Airport",
  "RAF Brize Norton",
  "Catacamas Airport",
  "Cabinda Airport",
  "Cascavel Airport (Adalberto Mendes da Silva Airport)",
  "Wexford County Airport",
  "Columbia Metropolitan Airport",
  "Carauari Airport",
  "Cagliari Elmas Airport",
  "Cà Mau Airport",
  "Cairo International Airport",
  "Canaima Airport",
  "Akron-Canton Airport",
  "Campbeltown Airport / RAF Machrihanish",
  "Camiri Airport",
  "Guangzhou Baiyun International Airport",
  "Clayton Municipal Airpark",
  "Hugo Chávez International Airport",
  "Juan H. White Airport",
  "Caribou Municipal Airport",
  "Anfa Airport",
  "Cascais Municipal Aerodrome",
  "Caruaru Airport (Oscar Laranjeiras Airport)",
  "Cazombo Airport",
  "Bartolomeu Lysandro Airport",
  "Carlisle Lake District Airport",
  "Cayenne – Félix Eboué Airport",
  "Cobar Airport",
  "Jorge Wilstermann International Airport",
  "Cherrabun Airport",
  "Car Nicobar Air Force Base",
  "Greater Cumberland Regional Airport",
  "Council Bluffs Municipal Airport",
  "Cambridge Airport",
  "Boudghene Ben Ali Lotfi Airport",
  "Cape Barren Island Airport",
  "Cabo Rojo Airport",
  "Colby Municipal Airport (Shalz Field)",
  "Tomás de Heres Airport",
  "Columbus Air Force Base",
  "Penggung Airport (Cakrabuwana Airport)",
  "Awang Airport",
  "Coimbra Airport",
  "Margaret Ekpo International Airport",
  "Canberra Airport",
  "Oro Negro Airport",
  "Catumbela Airport",
  "Cottbus-Drewitz Airport",
  "Cobán Airport",
  "Campo Mourão Airport",
  "Condobolin Airport",
  "Canobie Airport",
  "Chimore Airport",
  "Cable Airport",
  "Jardines del Rey Airport",
  "Carcassonne Airport",
  "Crane County Airport (FAA: E13)",
  "Chile Chico Airfield",
  "Concórdia Airport",
  "Calicut International Airport",
  "Cocos (Keeling) Islands Airport",
  "Chinchilla Airport",
  "Diomício Freitas Airport",
  "Chaghcharan Airport",
  "Carimagua Airport",
  "Carriel Sur International Airport",
  "Buchanan Field Airport",
  "Simón Bolívar International Airport",
  "Colonia Catriel Airport",
  "Netaji Subhas Chandra Bose International Airport",
  "Craig Cove Airport",
  "Cowell Airport",
  "Cáceres Airport",
  "Northeast Iowa Regional Airport",
  "Chub Cay International Airport",
  "Cooinda Airport",
  "Cold Bay Airport",
  "Cedar City Regional Airport",
  "Cauquira Airport",
  "Chengde Puning Airport",
  "Charles de Gaulle Airport (Roissy Airport)",
  "Harrell Field",
  "Cachoeiro de Itapemirim Airport (Raimundo de Andrade Airport)",
  "Conceição do Araguaia Airport",
  "George T. Lewis Airport",
  "Candle 2 Airport (FAA: AK75)",
  "Woodward Field",
  "Cradock Airport",
  "Kadapa Airport",
  "Croydon Airport",
  "Chadron Municipal Airport",
  "Childress Municipal Airport",
  "Castellón–Costa Azahar Airport",
  "Camden Airport",
  "Merle K. (Mudhole) Smith Airport",
  "Essex County Airport",
  "Cagayan de Sulu Airport",
  "Cessna Aircraft Field",
  "Mactan–Cebu International Airport",
  "Del Norte County Airport (Jack McNamara Field)",
  "Ceduna Airport",
  "Cherepovets Airport",
  "Westover Metropolitan Airport / Westover Air Reserve Base",
  "Hawarden Airport (Chester Airport)",
  "Chelinda Airport",
  "Chiang Rai International Airport (Mae Fah Luang Int'l)",
  "Chelyabinsk Airport",
  "Central Airport",
  "Ciudad Obregón International Airport",
  "Waco Kungo Airport",
  "Concepción Airport",
  "Cannes – Mandelieu Airport",
  "Cherbourg – Maupertus Airport",
  "Cessnock Airport",
  "Cholet Le Pontreau Airport",
  "Oconee County Regional Airport",
  "Mettel Field",
  "Bob Sikes Airport",
  "Chena Hot Springs Airport (FAA: AK13)",
  "Murray-Calloway County Airport",
  "Cortez Municipal Airport",
  "Cabo Frio International Airport",
  "Caçador Airport",
  "Coulter Field",
  "Clermont-Ferrand Auvergne Airport",
  "Cafunfo Airport",
  "Jaime González Airport",
  "Clifton Hills Airport",
  "Camfield Airport",
  "Chlef International Airport",
  "Conklin (Leismer) Airport",
  "Donegal Airport",
  "Confresa Airport",
  "Carpentaria Downs Airport",
  "Creston Aerodrome (TC: CAJ3)",
  "Caen – Carpiquet Airport",
  "Coffs Harbour Airport",
  "Greenlee County Airport",
  "Corfu International Airport (Ioannis Kapodistrias Int'l Airport)",
  "Coffeyville Municipal Airport",
  "Craig Seaplane Base",
  "Marechal Rondon International Airport",
  "Cape Gloucester Airport",
  "Changde Taohuayuan Airport",
  "Cambridge–Dorchester Airport",
  "Cuyahoga County Airport",
  "Casiguran Airport",
  "São Paulo/Congonhas–Deputado Freitas Nobre Airport",
  "Cape Girardeau Regional Airport",
  "Kasompe Airport",
  "Soekarno–Hatta International Airport",
  "Camiguin Airport",
  "Cologne Bonn Airport",
  "Zhengzhou Xinzheng International Airport",
  "Shah Amanat International Airport",
  "Changchun Longjia International Airport",
  "Campo Grande International Airport",
  "College Park Airport",
  "Chinguetti Airport",
  "Caiguna Airport",
  "Laguindingan Airport",
  "Casa Grande Municipal Airport",
  "Chattanooga Metropolitan Airport (Lovell Field)",
  "Chilas Airport",
  "Christchurch International Airport",
  "Jinhae Airport",
  "Chaoyang Airport",
  "Chachapoyas Airport",
  "Metropolitan Area",
  "Chipinge Airport",
  "Chickasha Municipal Airport",
  "Challis Airport (FAA: LLJ)",
  "Tnte. FAP Jaime Montreuil Morales Airport",
  "Jeonju Airport",
  "Charlottesville–Albemarle Airport",
  "Circle Hot Springs Airport",
  'Chania International Airport ("Ioannis Daskalogiannis")',
  'Châteauroux-Centre "Marcel Dassault" Airport',
  "Charleston International Airport / Charleston Air Force Base",
  "Chatham Islands / Tuuta Airport",
  "Chuathbaluk Airport (FAA: 9A3)",
  "Chaves Airport",
  'Changuinola "Capitán Manuel Niño" International Airport',
  "Choiseul Bay Airport",
  "Chiloquin State Airport (FAA: 2S7)",
  "Ciampino–G. B. Pastine International Airport",
  "Chico Municipal Airport",
  "The Eastern Iowa Airport",
  "Collie Airport",
  "Chifeng Yulong Airport",
  "Craig–Moffat Airport (FAA: CAG)",
  "Changzhi Wangcun Airport",
  "Aydın Airport (Çıldır Airport)",
  "Captain Aníbal Arab Airport",
  "Chalkyitsik Airport",
  "Council Airport (FAA: K29)",
  "Cimitarra Airport",
  "Arthur N. Neu Airport",
  "Teniente Coronel Carmelo Peralta Airport",
  "Chipata Airport",
  "Chiquimula Airport",
  "Cairo Regional Airport",
  "Canton Island Airport",
  "Shymkent International Airport",
  "Chippewa County International Airport",
  "Canouan Airport",
  "FAP Captain José Abelardo Quiñones González International Airport",
  "Comiso Airport",
  "Coari Airport",
  "Mayor General FAP Armando Revoredo Iglesias Airport",
  "Coimbatore International Airport",
  "El Loa Airport",
  "Candilejas Airport",
  "Coondewanna Airport",
  "Chilko Lake (Tsylos Park Lodge) Aerodrome (TC: CAG3)",
  "Cheongju International Airport",
  "Chitral Airport",
  "Chumphon Airport",
  "Cijulang Nusawiru Airport",
  "Abraham González International Airport",
  "Copalar Airport",
  "Jeju International Airport",
  "Kegelman Air Force Auxiliary Field",
  "North Central West Virginia Airport",
  "Cherkasy International Airport",
  "Crooked Creek Airport (FAA: CJX)",
  "Lampson Field (FAA: 1O2)",
  "Chongqing Jiangbei International Airport",
  "Chokurdakh Airport",
  "Croker Island Airport",
  "Sharp County Regional Airport (FAA: CVK)",
  "Chkalovsky Airport",
  "Fletcher Field",
  "Crookston Municipal Airport (Kirkwood Field)",
  "Cornélio Procópio Airport",
  "Crane Island Airstrip",
  "Carajás Airport",
  "Sarakhs Airport",
  "Cordova Municipal Airport",
  "Clarksville–Montgomery County Regional Airport (Outlaw Field)",
  "Graeme Rowley Aerodrome",
  "Chicken Airport",
  "Conakry International Airport (Gbessia Int'l Airport)",
  "Çanakkale Airport",
  "Comilla Airport",
  "McClellan–Palomar Airport (FAA: CRQ)",
  "Cleveland Hopkins International Airport",
  "New Coalinga Municipal Airport (FAA: C80)",
  "Coolah Airport",
  "Clintonville Municipal Airport",
  "Cluj-Napoca International Airport",
  "Clinton Regional Airport",
  "Easterwood Airport (Easterwood Field)",
  "William R. Fairchild International Airport",
  "Carolina Airport",
  "Alfonso Bonilla Aragón International Airport",
  "Clarks Point Airport",
  "Licenciado Miguel de la Madrid Airport",
  "Cliff Hatfield Memorial Airport",
  "Chehalis-Centralia Airport",
  "Charlotte Douglas International Airport",
  "Columbus Municipal Airport (FAA: BAK)",
  "Caldas Novas Airport",
  "Clearwater Air Park",
  "Clorinda Airport",
  "Calvi – Sainte-Catherine Airport",
  "Calabozo Airport",
  "Cunnamulla Airport",
  "Bandaranaike International Airport",
  "Camocim Airport",
  "Cootamundra Airport",
  "Ciudad del Carmen International Airport",
  "Chambéry-Savoie Airport",
  "Corumbá International Airport",
  "John Glenn Columbus International Airport",
  "University of Illinois Willard Airport",
  "Qimei Airport",
  "Club Makokola Airport",
  "Camooweal Airport",
  "Carmelita Airport",
  "Mohammed V International Airport",
  "Obbia Airport",
  "Santana do Araguaia Airport",
  "Clermont Airport",
  "Colmar – Houssen Airport",
  "Iskushuban Airport",
  "Cameta Airport",
  "Chimbu Airport",
  "Coromandel Aerodrome",
  "Ignacio Agramonte International Airport",
  "Houghton County Memorial Airport",
  "Sparta/Fort McCoy Airport",
  "Caia Airport",
  "Cananea National Airport",
  "Coonamble Airport",
  "Coconut Island Airport",
  "Mihail Kogălniceanu International Airport",
  "Fremont County Airport (FAA: 1V6)",
  "Tancredo Neves International Airport (Confins Int'l Airport)",
  "Cognac – Châteaubernard Air Base",
  "Claremont Municipal Airport",
  "Changhai Airport",
  "Cloncurry Airport",
  "Blosser Municipal Airport",
  "Sindal Airport",
  "Cavern City Air Terminal",
  "Kannur International Airport",
  "Chino Airport",
  "Nerlerit Inaat Airport (Constable Pynt Airport)",
  "Doctor Fernando Piragine Niveyro International Airport",
  "Chañaral Airport",
  "Cairns Airport",
  "Charata Airport",
  "Chanute Martin Johnson Airport",
  "Canavieiras Airport",
  "TSTC Waco Airport",
  "Chiang Mai International Airport",
  "Canyonlands Field",
  "Cangamba Airport",
  "Columbia Airport (FAA: O22)",
  "Coolibah Airport",
  "Comodoro Pierrestegui Airport",
  "Yellowstone Regional Airport",
  "Coeur d'Alene Airport",
  "Patrick Air Force Base",
  "Mandinga Airport",
  "Cooch Behar Airport",
  "Merritt Island Airport",
  "Coonabarabran Airport",
  "Cochin International Airport (Nedumbassery Airport)",
  "Coll Airport",
  "Coleman Municipal Airport",
  "Concord Municipal Airport",
  "Cadjehoun Airport",
  "Cooperstown-Westville Airport (FAA: K23)",
  "Choibalsan Airport",
  "Ingeniero Aeronáutico Ambrosio L.V. Taravella International Airport (Pajas Blancas)",
  "Colorado Springs Airport",
  "Cotulla–La Salle County Airport",
  "Columbia Regional Airport",
  "Coquimbo Airport",
  "Coolawanyah Station Airport",
  "Constanza Airport",
  "Cape Palmas Airport",
  "Capurganá Airport",
  "Aviador Carlos Campos Airport",
  "Coober Pedy Airport",
  "Ing. Alberto Acuña Ongay International Airport",
  "Ngloram Airport",
  "Carmen de Patagones Airport",
  "Copenhagen Airport, Kastrup",
  "Cape Orford Airport",
  "General Navas Pardo Airport",
  "Compton/Woodley Airport",
  "Cape Rodney Airport",
  "Desierto de Atacama Airport",
  "Coposa Airport",
  "Casper–Natrona County International Airport",
  "St. Louis Downtown Airport",
  "Cape Town International Airport",
  "Cururupu Airport",
  "Campina Grande Airport (Presidente João Suassuna Airport)",
  "Benjamín Rivera Noriega Airport",
  "Canarana Airport",
  "Shahrekord Airport",
  "Calais–Dunkerque Airport",
  "Ciudad Real Central Airport",
  "Cape Flattery Airport",
  "Costa Marques Airport",
  "Caquetania Airport",
  "Wulong Xiannüshan Airport",
  "Craiova International Airport",
  "Collarenebri Airport",
  "Santa Ana Airport",
  "General Enrique Mosconi International Airport",
  "Grand Strand Airport",
  "Carnot Airport",
  "Jacksonville Executive at Craig Airport",
  "Cherrabah Airport",
  "Colonel Hill Airport (Crooked Island Airport)",
  "Coorabie Airport",
  "Clark International Airport",
  "Brussels South Charleroi Airport",
  "Catarman National Airport",
  "Corcoran Airport",
  "Corpus Christi International Airport",
  "Caravelas Airport",
  "Ceres Airport",
  "C. David Campbell Field (Corsicana Municipal Airport)",
  "Z. M. Jack Stell Field",
  "Lauriston Airport (Carriacou Island Airport)",
  "Crotone Airport (Sant'Anna Airport)",
  "Yeager Airport",
  "Roscoe Turner Airport",
  "Carlton Hill Airport",
  "Turkmenabat Airport",
  "Colonsay Airport",
  "Caransebeș Airport",
  "Cañas Mojica Airport",
  "Cresswell Downs Airport",
  "Buckhorn Ranch Airport (FAA: 0CO2)",
  "Creil Air Base",
  "Columbus Airport",
  "Solovki Airport",
  "Casino Airport",
  "Cap Skirring Airport",
  "Clinton-Sherman Airport",
  "Carson Airport (FAA: CXP)",
  "Magdeburg–Cochstedt Airport",
  "Creston Municipal Airport",
  "Casuarito Airport",
  "Cassilândia Airport",
  "Castaway Island Seaplane Base",
  "Santa Cruz do Sul Airport",
  "Crossville Memorial-Whitson Field",
  "Changsha Huanghua International Airport",
  "Cheboksary Airport",
  "Brigadier Hector Eduardo Ruiz Airport",
  "Catania–Fontanarossa Airport",
  "Cut Bank Municipal Airport",
  "Coronel Felipe Varela International Airport",
  "Chitré Alonso Valderrama Airport",
  "Cartí Airport",
  "Coatepeque Airport",
  "Rafael Núñez International Airport",
  "Chester County G. O. Carlson Airport (FAA: MQS)",
  "Cuito Cuanavale Airport",
  "Canton Municipal Airport (FAA: 7G9)",
  "Charleville Airport",
  "Chetumal International Airport",
  "Cooktown Airport",
  "Calverton Executive Airpark (FAA: 3C8)",
  "Carutapera Airport",
  "Do Palmar Airport",
  "Cattle Creek Airport",
  "New Chitose Airport",
  "Le Castellet Airport",
  "Chengdu Shuangliu International Airport",
  "Cottonwood Airport (FAA: P52)",
  "Cortland County Airport (Chase Field, FAA: N03)",
  "Cross City Airport",
  "Clinton–Sampson County Airport",
  "Ciudad Constitución Airport",
  "Jim Hamilton–L.B. Owens Airport",
  "Camilo Daza International Airport",
  "Caloundra Airport",
  "Mariscal Lamar International Airport",
  "Cuneo International Airport",
  "Cudal Airport",
  "Cushing Municipal Airport",
  "Curillo Airport",
  "Culion Airport",
  "Caye Caulker Airport",
  "Bachigualato Federal International Airport",
  "Antonio José de Sucre Airport",
  "Cancún International Airport",
  "Caruru Airport",
  "General José Francisco Bermúdez Airport",
  "Coen Airport",
  "Curaçao International Airport (Hato Int'l Airport)",
  "Columbus Municipal Airport (FAA: 0NM0)",
  "Cutral Có Airport",
  "General Roberto Fierro Villalobos International Airport",
  "Casigua El Cubo Airport",
  "Cuddihy Field (FAA: 07TE)",
  "Cue Airport",
  "Alejandro Velasco Astete International Airport",
  "Chungrebu Airport",
  "Cleve Airport",
  "Coveñas Airport",
  "Courchevel Altiport",
  "Cincinnati/Northern Kentucky International Airport",
  "Caviahue Airport",
  "Caleta Olivia Airport",
  "General Mariano Matamoros Airport",
  "Cape Vogel Airport",
  "General Pedro J. Méndez International Airport",
  "Clovis Municipal Airport",
  "Corvallis Municipal Airport",
  "Carnarvon Airport",
  "Cannon Air Force Base",
  "Coventry Airport",
  "Corvo Airport",
  "Central Wisconsin Airport",
  "Afonso Pena International Airport",
  "Chernivtsi International Airport",
  "Chennault International Airport",
  "Clinton Municipal Airport",
  "Cangyuan Washan Airport",
  "Cardiff Airport",
  "Cowarie Airport",
  "Center Island Airport (FAA: 78WA)",
  "Cowra Airport",
  "Corowa Airport",
  "Cochise County Airport (FAA: P33)",
  "Caicara del Orinoco Airport",
  "Cox's Bazar Airport",
  "Chitina Airport",
  "Coldfoot Airport",
  "Vancouver Harbour Flight Centre (Coal Harbour Seaplane Base)",
  "Cassidy International Airport",
  "Caxias do Sul Airport (Hugo Cantergiani Regional Airport)",
  "Calexico International Airport",
  "Candala Airport",
  "Lone Star Executive Airport",
  "Tunggul Wulung Airport",
  "Christmas Creek Airport",
  "Cam Ranh International Airport",
  "Charters Towers Airport",
  "Cat Cay Airport",
  "Antoine-Simon Airport",
  "Charles Kirkconnell International Airport",
  "Caye Chapel Airport",
  "San Ignacio Town Airstrip",
  "Chefornak Airport (FAA: CFK)",
  "Corryong Airport",
  "Chiayi Airport",
  "Coyoles Airport",
  "Chatham Seaplane Base",
  "Vilo Acuña Airport",
  "Calbayog Airport",
  "Colonia Airport (Laguna de los Patos Intn'l Airport)",
  "Cheyenne Regional Airport (Jerry Olson Field)",
  "Yakataga Airport",
  "Cuyo Airport",
  "Captain Rogelio Castillo National Airport",
  "Chersky Airport",
  "Cauayan Airport",
  "Chichen Itza International Airport",
  "Carlos Ruhl Airport",
  "Copper Center 2 Airport (FAA: Z93)",
  "José Leonardo Chirino Airport",
  "Cape Romanzof LRRS Airport",
  "Corozal Airport",
  "Corazón de Jesús Airport",
  "Cascade Locks State Airport",
  "Mohamed Boudiaf International Airport",
  "Cozumel International Airport",
  "Chisana Airport",
  "Chistochina Airport",
  "Cape Pole Seaplane Base (FAA: Z71)",
  "Cruzeiro do Sul International Airport",
  "Dimmit County Airport",
  "Las Brujas Airport",
  "Częstochowa-Rudniki Airport",
  "Changzhou Benniu Airport",
  "Cluny Airport",
  "Davison Army Airfield",
  "Daytona Beach International Airport",
  "Shahjalal International Airport",
  "Da Nang International Airport",
  "Daup Airport",
  "Barstow-Daggett Airport",
  "Dathina Airport",
  "Dakhla Oasis Airport",
  "Dallas Love Field",
  "Damascus International Airport",
  "Danville Regional Airport",
  "Dabo Airport",
  "Darchula Airport",
  "Julius Nyerere International Airport",
  "Great Bear Lake Airport (TC: CFF4)",
  "Datong Yungang Airport",
  "Daru Airport",
  "Enrique Malek International Airport",
  "Dazhou Heshi Airport",
  "Dayton International Airport",
  "Darwaz Airport",
  "Dalbandin Airport",
  "Al Alamain International Airport",
  "Baicheng Chang'an Airport",
  "Dhanbad Airport",
  "Dutch Bay Seaplane Base",
  "Debre Marqos Airport",
  "W. H. 'Bud' Barron Airport",
  "Dubbo City Regional Airport",
  "Debepare Airport",
  "Dubuque Regional Airport",
  "Dubois Municipal Airport (FAA: U41)",
  "Debre Tabor Airport",
  "Dambulu Oya Tank Seaplane Base",
  "Dubrovnik Airport",
  "Dalby Airport",
  "Ronald Reagan Washington National Airport",
  "Canefield Airport",
  "Dubai Creek Seaplane Base",
  "Decimomannu Air Base",
  "Dahl Creek Airport",
  "Castres–Mazamet Airport",
  "RAAF Base Curtin",
  "Duncan Town Airport",
  "Pryor Field Regional Airport",
  "Daocheng Yading Airport",
  "Dodge City Regional Airport",
  "Dhaalu Airport",
  "Dandong Langtou Airport",
  "Dodoima Airport",
  "Delta Downs Airport",
  "Dadu Airport",
  "Dera Ghazi Khan International Airport",
  "Debrecen International Airport",
  "Decatur Airport",
  "Jolly Grant Airport",
  "Yuzhno-Kurilsk Mendeleyevo Airport",
  "Dezful Airport",
  "Decorah Municipal Airport",
  "Denis Island Airport",
  "Indira Gandhi International Airport",
  "Dembidolo Airport",
  "Denver International Airport",
  "Daporijo Airport",
  "Deqing Moganshan Airport",
  "Derim Airport",
  "Desroches Airport",
  "Coleman A. Young International Airport",
  "Nop Goliat Dekai Airport",
  "Deir ez-Zor Airport",
  "Defiance Memorial Airport",
  "Drumduff Airport",
  "Dallas/Fort Worth International Airport",
  "Dangriga Airport",
  "Degeh Bur Airport",
  "Dalgaranga Airport",
  "Mudgee Airport",
  "Douglas Lake Airport",
  "Dugong Beach Lodge Airstrip",
  "Douglas Municipal Airport",
  "Dandugama Seaplane Base",
  "Naval Surface Warfare Center Dahlgren Division (FAA: NDY)",
  "General Guadalupe Victoria International Airport",
  "Daugavpils International Airport",
  "Dargaville Aerodrome",
  "Sibulan Airport (Dumaguete Airport)",
  "Dédougou Airport",
  "Converse County Airport",
  "King Abdulaziz Air Base",
  "Deer Harbor Sea Plane Base",
  "Durham Downs Airport",
  "Al Dhafra Air Base",
  "Dhangadhi Airport",
  "Dhala Airport",
  "Gaggal Airport",
  "Dothan Regional Airport",
  "De Kooy Airfield",
  "Dalhart Municipal Airport",
  "Doha International Airport",
  "Dibrugarh Airport (Mohanbari Airport)",
  "Arrachart Airport",
  "Diqing Shangri-La Airport",
  "Dijon Air Base",
  "Dickinson Theodore Roosevelt Regional Airport",
  "Presidente Nicolau Lobato International Airport",
  "Dimbokro Airport",
  "Dien Bien Phu Airport",
  "Diapaga Airport",
  "Divinópolis Airport (Brigadeiro Cabral Airport)",
  "Aba Tenna Dejazmach Yilma International Airport",
  "Dolisie Airport",
  "Diu Airport",
  "Divo Airport",
  "Mawella Lagoon Seaplane Base",
  "Diyarbakır Airport",
  "Djougou Airport",
  "Sultan Thaha Airport",
  "Djerba–Zarzis International Airport",
  "Tiska Djanet Airport",
  "Jebel Ali Seaplane Base",
  "Sentani Airport",
  "Djambala Airport",
  "Delta Junction Airport (FAA: D66)",
  "Daloa Airport",
  "Djúpivogur Airport",
  "Katsina Airport",
  "Dunk Island Airport",
  "Chautauqua County/Dunkirk Airport",
  "Léopold Sédar Senghor International Airport",
  "Dikson Airport",
  "Docker River Airport",
  "Douala International Airport",
  "Dalbertis Airport",
  "Dalian Zhoushuizi International Airport",
  "Dole–Jura Airport",
  "Laughlin Air Force Base",
  "Dillingham Airport",
  "Duluth International Airport",
  "Lien Khuong Airport",
  "Dulkaninna Airport",
  "Dillon County Airport (FAA: DLC)",
  "Dalaman Airport",
  "Dillon Airport",
  "Columbia Gorge Regional Airport (The Dalles Municipal Airport)",
  "Dali Airport",
  "Delissaville Airport",
  "Dillon's Bay Airport",
  "Dalanzadgad Airport",
  "Davis–Monthan Air Force Base",
  "Taraz Airport (Jambyl Airport)",
  "Doomadgee Airport",
  "Domodedovo International Airport",
  "Don Mueang International Airport",
  "King Fahd International Airport",
  "Deming Municipal Airport",
  "Sedalia Regional Airport",
  "Dhamar Airport",
  "Diamantino Airport",
  "Dimapur Airport",
  "Kadena Air Base",
  "Dunbar Airport",
  "Dundee Airport",
  "Martuba Air Base",
  "Doongan Airport",
  "Dunhuang Airport",
  "Wad Medani Airport",
  "Dnipropetrovsk International Airport",
  "Daniel Field",
  "Denham Airport",
  "Dalton Municipal Airport",
  "Dianópolis Airport",
  "Tribhuvannagar Airport (Dang Airport)",
  "Deniliquin Airport",
  "Dinard–Pleurtuit–Saint-Malo Airport",
  "Denison Municipal Airport",
  "Dinangat Airport",
  "Vermilion Regional Airport",
  "Galegu Airport",
  "Denizli Çardak Airport",
  "Doany Airport",
  "Dobo Airport",
  "Dornoch Airport",
  "Dodoma Airport",
  "Djoemoe Airstrip",
  "Dongola Airport",
  "Hamad International Airport",
  "Doini Island Airport",
  "Donetsk International Airport",
  "Deauville – Saint-Gatien Airport",
  "Douglas–Charles Airport",
  "Dos Lagunas Airport",
  "Dorobisoro Airstrip",
  "Dolpa Airport",
  "Dori Airport",
  "Dios Airport",
  "Dourados Airport (Francisco de Matos Pereira Airport)",
  "Dover Air Force Base",
  "Dongara Airport",
  "Dongying Shengli Airport",
  "DuPage Airport",
  "Pampa Guanaco Airport",
  "Dieppe - Saint-Aubin Airport",
  "Michael Army Airfield",
  "Dipolog Airport",
  "Devonport Airport",
  "Ngurah Rai International Airport",
  "Deputatsky Airport",
  "Dumpu Airport",
  "Daqing Sartu Airport",
  "Duqm International Airport",
  "Desert Rock Airport",
  "Derby Airport",
  "Dirico Airport",
  "Dorunda Airport",
  "Drummond Island Airport (FAA: DRM)",
  "Drift River Airport",
  "Deering Airport (FAA: DEE)",
  "Dabra Airport",
  "Beauregard Regional Airport",
  "Drietabbetje Airstrip",
  "Drake Bay Airport",
  "Dirranbandi Airport",
  "Durango–La Plata County Airport",
  "Durrie Airport",
  "Dresden Airport",
  "Del Rio International Airport",
  "Drummond Airport (FAA: M26)",
  "Dharavandhoo Airport",
  "Darwin International Airport",
  "Drysdale River Airport",
  "Robin Hood Airport Doncaster Sheffield",
  "Dschang Airport",
  "La Désirade Airport (Grande-Anse Airport)",
  "Combolcha Airport",
  "Dilasag Airport",
  "Destin Executive Airport (FAA: DTS)",
  "Dera Ismail Khan Airport",
  "Des Moines International Airport",
  "Ordos Ejin Horo Airport",
  "Sondok Airport",
  "Blaise Diagne International Airport",
  "Dansville Municipal Airport",
  "Dongsha Island Airport",
  "Delta Municipal Airport",
  "Silangit Airport",
  "Datadawai Airport",
  "Bagasbas Airport",
  "Furnace Creek Airport (FAA: L06)",
  "Diamantina Airport",
  "Detroit Lakes Airport (Wething Field)",
  "Dortmund Airport",
  "Shreveport Downtown Airport",
  "Decatur Shores Airport",
  "Metropolitan Area",
  "Wudalianchi Airport",
  "Detroit Metropolitan Wayne County Airport",
  "Durant Regional Airport–Eaker Field",
  "Dublin Airport",
  "Halliburton Field",
  "Dunedin International Airport",
  "Dundo Airport",
  "Pine Island Airport (FAA: 7NC2)",
  "Bisbee Douglas International Airport",
  "DuBois Regional Airport",
  "Dukuduku Airport",
  "Pinang Kampai Airport",
  "Duncan Airport",
  "King Shaka International Airport",
  "Düsseldorf Airport",
  "Unalaska Airport",
  "Andavadoaka Airport",
  "Diavik Airport",
  "Devils Lake Regional Airport (Devils Lake Municipal Airport)",
  "Davenport Municipal Airport",
  "Francisco Bangoy International Airport",
  "Davenport Downs Airport",
  "Daly River Airport",
  "Phoenix Deer Valley Airport",
  "Dwanga Airport (Dwangwa Airport)",
  "Soalala Airport",
  "Al Maktoum International Airport",
  "Dawadmi Domestic Airport (King Salman bin Abdul Aziz Domestic Airport)",
  "David Wayne Hooks Memorial Airport",
  "Diyawanna Oya Seaplane Base",
  "Dwyer Airport",
  "Dubai International Airport",
  "Dixie Airport",
  "Bruce Campbell Field (FAA: MBO)",
  "Danbury Municipal Airport",
  "Dysart Airport",
  "Zhangjiajie Hehua Airport",
  "Doylestown Airport",
  "Diamantina Lakes Airport",
  "Ugolny Airport",
  "Dyess Air Force Base",
  "Dushanbe International Airport",
  "Daly Waters Airport",
  "Dzaoudzi–Pamandzi International Airport",
  "Codazzi Airport",
  "Zhezkazgan Airport",
  "Santa Bernardina International Airport",
  "Dazu Air Base",
  "Eagle Airport",
  "Abbs Airport",
  "Siwo Airport",
  "Elenak Airport",
  "Najran Domestic Airport",
  "Phifer Airfield",
  "Metropolitan Area",
  "Kearney Regional Airport",
  "San Sebastián Airport",
  "Pangborn Memorial Airport",
  "Chippewa Valley Regional Airport",
  "Marina di Campo Airport",
  "Entebbe International Airport",
  "El Obeid Airport",
  "El Bagre Airport (El Tomin Airport)",
  "El Bayadh Airport",
  "Esbjerg Airport",
  "Erbil International Airport",
  "El Borma Airport",
  "Ebadon Airstrip",
  "Ebon Airport",
  "Webster City Municipal Airport",
  "Saint-Étienne–Bouthéon Airport",
  "Ebolowa Airport",
  "Iosco County Airport (FAA: 6D9)",
  "Elizabeth City Regional Airport",
  "Echuca Airport",
  "Costa Esmeralda Airport",
  "Ercan International Airport",
  "El Encanto Airport",
  "Northwest Florida Beaches International Airport",
  "El Charco Airport",
  "Mondell Field",
  "Edna Bay Seaplane Base",
  "El Debba Airport",
  "Erldunda Airport",
  "Northeastern Regional Airport",
  "Elmendorf Air Force Base",
  "Edinburgh Airport",
  "Captain Jack Thomas/El Dorado Airport (FAA: EQA)",
  "Eldoret International Airport",
  "La Roche-sur-Yon Aerodrome",
  "Balıkesir Koca Seyit Airport",
  "Erandique Airport",
  "Edward River Airport",
  "Edwards Air Force Base",
  "Needles Airport",
  "Eek Airport",
  "Dillant–Hopkins Airport",
  "Ellington Field",
  "Efogi Airport",
  "Newport State Airport",
  "Cephalonia International Airport",
  "Jefferson Municipal Airport",
  "Engati Airport",
  "Bergerac Dordogne Périgord Airport",
  "Eagle County Regional Airport",
  "Duke Field (Eglin Auxiliary Field 3)",
  "Neghelle Airport",
  "Seghe Airport",
  "Geneina Airport",
  "Belgorod International Airport",
  "Maverick County Memorial International Airport",
  "Egilsstaðir Airport",
  "Eagle River Union Airport",
  "Egegik Airport (FAA: EII)",
  "El Bolsón Airport",
  "Cape Newenham LRRS Airport",
  "Popondetta Airport",
  "Eisenach-Kindel Airport",
  "Yeniseysk Airport",
  "Einasleigh Airport",
  "Yeysk Airport",
  "Eielson Air Force Base",
  "Eindhoven Airport",
  "Terrance B. Lettsome International Airport",
  "Ein Yahav Airfield",
  "Yariguíes Airport",
  "Al Wajh Domestic Airport",
  "Ejin Banner Taolai Airport",
  "Enejit Airport",
  "Murray Field",
  "Ekibastuz Airport",
  "Elkedra Airport",
  "Ekereku Airport",
  "Elkhart Municipal Airport (FAA: EKM)",
  "Elkins-Randolph County Airport",
  "Elko Regional Airport",
  "Shakhtyorsk Airport",
  "Eskilstuna Airport",
  "Elizabethtown Regional Airport (Addington Field)",
  "Eagle Lake Airport",
  "Las Flores Airport",
  "Elcho Island Airport",
  "South Arkansas Regional Airport at Goodwin Field",
  "El Real Airport",
  "El Fasher Airport",
  "El Golea Airport",
  "North Eleuthera Airport",
  "Elim Airport",
  "El Recreo Airport",
  "Elk City Regional Business Airport",
  "Ellisras Airport",
  "Elmira/Corning Regional Airport",
  "Bowers Field",
  "Eldorado Airport",
  "El Paso International Airport",
  "Prince Nayef bin Abdulaziz Regional Airport",
  "Elelim Airport",
  "East London Airport",
  "El Tor Airport",
  "Guemar Airport",
  "Elfin Cove Seaplane Base",
  "Ellamar Seaplane Base (FAA: 1Z9)",
  "El Tigre Airport",
  "Ely Airport",
  "Wellsville Municipal Airport (Tarantine Field)",
  "East Midlands Airport",
  "Emerald Airport",
  "Emden Airport",
  "Empangeni Airport",
  "Emirau Airport",
  "Emmonak Airport (FAA: ENM)",
  "Emmen Air Base",
  "Kemmerer Municipal Airport",
  "Néma Airport",
  "Emo Airport",
  "Emporia Municipal Airport",
  "Embessa Airport",
  "San Gabriel Valley Airport",
  "El Maitén Airport",
  "El Minya Airport",
  "Kenai Municipal Airport",
  "Eneabba Airport",
  "Nancy-Essey Airport",
  "Vance Air Force Base",
  "H. Hasan Aroeboesman Airport",
  "Enontekiö Airport",
  "Enshi Xujiaping Airport",
  "El Nido Airport",
  "El Naranjo Airport",
  "Enniskillen/St Angelo Airport",
  "Centralia Municipal Airport",
  "Nenana Municipal Airport",
  "Teniente Amin Ayub Gonzalez Airport",
  "Enschede Airport Twente",
  "Enewetak Auxiliary Airfield",
  "Akanu Ibiam International Airport",
  "Wendover Airport",
  "Kenosha Regional Airport",
  "Yan'an Nanniwan Airport",
  "Olaya Herrera Airport",
  "Eday Airport",
  "Keokuk Municipal Airport",
  "El Dorado Airport",
  "Neosho Hugh Robinson Airport",
  "Elorza Airport",
  "El Palomar Airport",
  "Browns Airport (FAA: NE69)",
  "Ephrata Municipal Airport",
  "Épinal – Mirecourt Airport",
  "Epéna Airport",
  "Esperance Airport",
  "Arroyo Barril Airport",
  "Eliptamin Airport",
  "Pärnu Airport",
  "Esquel Airport",
  "Erigavo Airport",
  "Pukatja Airport (Ernabella Airport)",
  "Erzincan Airport",
  "Berdiansk Airport",
  "Erave Airport",
  "Erfurt–Weimar Airport",
  "Erbogachen Airport",
  "Moulay Ali Cherif Airport",
  "Erie International Airport (Tom Ridge Field)",
  "Erenhot Saiwusu International Airport",
  "Erechim Airport",
  "Eirunepé Airport (Amaury Feitosa Tomaz Airport)",
  "Elrose Airport",
  "Errol Airport",
  "Eros Airport",
  "Erdenet Airport",
  "Erume Airport",
  "Kerrville Municipal Airport (Louis Schreiner Field)",
  "Erzurum Airport",
  "Esa'ala Airport",
  "Esenboğa International Airport",
  "Delta County Airport",
  "Orcas Island Airport (FAA: ORS)",
  "Ensenada Airport",
  "Esler Airfield (Esler Regional Airport)",
  "Dr. Luis María Argaña International Airport",
  "Shoreham Airport (Brighton City Airport)",
  "Espinosa Airport",
  "Eskişehir Airport",
  "Elista Airport",
  "Carlos Concha Torres International Airport",
  "Easton Airport (Newnam Field)",
  "Ohkay Owingeh Airport (FAA: E14)",
  "Stroudsburg–Pocono Airport (FAA: N53)",
  "Ricardo García Posada Airport",
  "Essen/Mülheim Airport",
  "Estherville Municipal Airport",
  "Essaouira-Mogador Airport",
  "Easton State Airport",
  "West Bend Municipal Airport",
  "Etadunna Airport",
  "Genda Wuha Airport",
  "J. Hozman Airport",
  "Ramon Airport",
  "Eastland Municipal Airport",
  "Santa Rosa International Airport",
  "Enterprise Municipal Airport (FAA: EDN)",
  "Metz–Nancy–Lorraine Airport",
  "Eua Airport (Kaufana Airport)",
  "Eucla Airport",
  "Eureka Airport (FAA: 05U)",
  "Weedon Field",
  "Eugene Airport (Mahlon Sweet Airport)",
  "Neumünster Airport",
  "Hassan I Airport",
  "Paratebueno Airport",
  "Evelio Javier Airport (Antique Airport)",
  "F. D. Roosevelt Airport",
  "Ben Bruce Memorial Airpark (FAA: 4TE8)",
  "Eva Downs Airport",
  "Harstad/Narvik Airport, Evenes",
  "Sveg Airport",
  "Evans Head Memorial Aerodrome",
  "Eveleth-Virginia Municipal Airport",
  "Zvartnots International Airport",
  "Evansville Regional Airport",
  "Evanston-Uinta County Burns Field",
  "Évreux-Fauville Air Base",
  "New Bedford Regional Airport",
  "Ewer Airport",
  "Enarotali Airport",
  "Newton City/County Airport",
  "Coastal Carolina Regional Airport",
  "Ewo Airport",
  "Newark Liberty International Airport",
  "Excursion Inlet Seaplane Base",
  "Exmouth Airport",
  "Exeter International Airport",
  "Beloyarsk Airport",
  "Yélimané Airport",
  "El Alcaraván Airport",
  "Yerington Municipal Airport (FAA: O43)",
  "Eliye Springs Airport",
  "Key West International Airport",
  "Ministro Pistarini International Airport",
  "Elazığ Airport",
  "Beryozovo Airport",
  "Faranah Airport",
  "Farnborough Airport",
  "Faaite Airport",
  "Vágar Airport",
  "Felker Army Airfield",
  "Fagurhólsmýri Airport",
  "Farah Airport",
  "Fairbanks International Airport",
  "Diego Jiménez Torres Airport (FAA: X95)",
  "False Island Seaplane Base (FAA: 2Z6)",
  "Farmington Regional Airport",
  "Faro Airport",
  "Frieda River Airport",
  "Hector International Airport",
  "Fáskrúðsfjörður Airport",
  "Fresno Yosemite International Airport",
  "Fahud Airport",
  "Fakarava Airport",
  "Fayetteville Regional Airport (Grannis Field)",
  "Fasa Airport",
  "Fonte Boa Airport",
  "Fayzabad Airport",
  "Francisco Beltrão Airport (Paulo Abdala Airport)",
  "Simmons Army Airfield",
  "Ladd Army Airfield",
  "Faribault Municipal Airport",
  "Lubumbashi International Airport",
  "Fort Bridger Airport",
  "Friday Harbor Seaplane Base (FAA: W33)",
  "Fairbury Municipal Airport",
  "Glacier Park International Airport (FAA: GPI)",
  "Ficksburg Airport",
  "Fresno Chandler Executive Airport",
  "Flying Cloud Airport",
  "Sea-Airport Cuxhaven/Nordholz",
  "Leonardo da Vinci–Fiumicino Airport",
  "Butts Army Airfield (Fort Carson)",
  "Forrest City Municipal Airport",
  "Førde Airport, Bringeland",
  "Martinique Aimé Césaire International Airport",
  "Friedrichshafen Airport (Bodensee Airport)",
  "Frederick Municipal Airport",
  "Frederick Regional Airport",
  "Bandundu Airport",
  "Findlay Airport",
  "Fetlar Airport",
  "Sanfebagar Airport",
  "Feira de Santana Airport (Gov. João Durval Carneiro Airport)",
  "Fergana International Airport",
  "Feijó Airport",
  "Ferkessédougou Airport",
  "Fürstenfeldbruck Air Base",
  "Fernando de Noronha Airport (Gov. Carlos Wilson Airport)",
  "Albertus Airport",
  "Fremont Municipal Airport",
  "Fes–Saïss Airport",
  "First Flight Airport",
  "RAF Fairford",
  "Fairfield Municipal Airport",
  "Fergus Falls Municipal Airport (Einar Mickelson Field)",
  "Wright-Patterson Air Force Base (First Flight-Office)",
  "Capital City Airport",
  "Futaleufú Airfield",
  "Fderik Airport",
  "Fagali'i Airport",
  "Fangatau Airport",
  "Sierra Vista Municipal Airport (Libby Army Airfield)",
  "Fakahina Airport",
  "Elizabeth Field (FAA: 0B8)",
  "Fair Isle Airport",
  "Fria Airport",
  "N'djili Airport (Kinshasa Int'l Airport)",
  "Finke Airport",
  "Fillmore Municipal Airport (FAA: FOM)",
  "Finschhafen Airport",
  "Fitzroy Crossing Airport",
  "Fujairah International Airport",
  "Karlsruhe/Baden-Baden Airport",
  "Bangoka International Airport",
  "Fukui Airport",
  "Venango Regional Airport (Chess Lamberton Field)",
  "Franklin Municipal–John Beverly Rose Airport",
  "Fakfak Torea Airport",
  "Fukushima Airport",
  "Gustavo Artunduaga Paredes Airport",
  "Cangapara Airport",
  "Fond du Lac County Airport",
  "Flensburg-Schäferhaus Airport",
  "Flagstaff Pulliam Airport",
  "Flotta Island Airport",
  "Holt Airport",
  "Fort Lauderdale–Hollywood International Airport",
  "Filadelfia Airport",
  "Hercílio Luz International Airport",
  "Florence Regional Airport",
  "Marion County Regional Airport",
  "Florence Airport, Peretola (Amerigo Vespucci Airport)",
  "Flinders Island Airport",
  "Flat Airport",
  "Sherman Army Airfield",
  "Flores Airport",
  "Fallon Municipal Airport",
  "Finley Airport",
  "Ferdinand Lumban Tobing Airport",
  "Formosa International Airport (El Pucú Airport)",
  "Tipton Airport",
  "Otis Air National Guard Base",
  "Kalemie Airport",
  "Memmingen Airport",
  "Four Corners Regional Airport",
  "Münster Osnabrück International Airport",
  "Fort Madison Municipal Airport (FAA: FSW)",
  "Florence Municipal Airport (FAA: 6S2)",
  "Page Field",
  "Lungi International Airport",
  "Neubrandenburg Airport",
  "Madeira Airport",
  "Funadhoo Airport",
  "Fane Airport",
  "Fada N'gourma Airport",
  "Fincha Airport",
  "Nîmes–Alès–Camargue–Cévennes Airport (Garons Airport)",
  "Pyongyang Sunan International Airport",
  "Fort Collins–Loveland Municipal Airport",
  "Funter Bay Seaplane Base",
  "Bishop International Airport",
  "Oristano-Fenosu Airport",
  "Foula Airfield",
  "Fort Bragg Airport (FAA: 82CL)",
  "Fuzhou Changle International Airport",
  "Fort Dodge Regional Airport",
  "Topeka Regional Airport",
  'Foggia "Gino Lisa" Airport',
  "Francis S. Gabreski Airport",
  "Foumban Nkounja Airport",
  "La Fortuna Arenal Airport",
  "Kornasoren Airport (Numfoor Airport)",
  "Pinto Martins – Fortaleza International Airport",
  "Forrest Airport",
  "Forster (Wallis Island) Airport",
  "Fougamou Airport",
  "Foya Airport",
  "Grand Bahama International Airport",
  "St. Lucie County International Airport",
  "Perry-Foley Airport (FAA: 40J)",
  "Frankfurt Airport",
  "Forbes Airport",
  "Franca Airport (Ten. Lund Presotto–Franca State Airport)",
  "Friday Harbor Airport (FAA: FHR)",
  "Fera Airport (Fera/Maringe Airport)",
  "Republic Airport",
  "French Lick Municipal Airport",
  "Marshall Army Airfield",
  "Frégate Island Airport",
  "Forlì International Airport (Luigi Ridolfi Airport)",
  "Fairmont Municipal Airport",
  "Bryant Army Airport",
  "Florø Airport",
  "Feramin Airport",
  "Front Royal–Warren County Airport",
  "Mundo Maya International Airport",
  "Frutillar Airport",
  "Manas International Airport",
  "Francistown Airport",
  "Eastern Slopes Regional Airport (FAA: IZG)",
  "Fritzlar Air Base",
  "Figari–Sud Corse Airport",
  "Sioux Falls Regional Airport (Joe Foss Field)",
  "Henry Post Army Airfield (Fort Sill)",
  "Fort Scott Municipal Airport",
  "Fossil Downs Airport",
  "Fort Smith Regional Airport",
  "Saint-Pierre Airport",
  "RAF Kinloss",
  "Fort Stockton–Pecos County Airport",
  "Fort Sumner Municipal Airport",
  "Shizuoka Airport (Mt. Fuji Shizuoka Airport)",
  "Futuna Airport",
  "Comandante Armando Tola International Airport",
  "Fitiuta Airport (FAA: FAQ)",
  "Godman Army Airfield",
  "Tôlanaro Airport (Marillac Airport)",
  "Fort Worth Meacham International Airport",
  "Owando Airport",
  "Fulton County Airport (Charlie Brown Field)",
  "Fulleborn Airport",
  "Fuerteventura Airport",
  "Fuyang Xiguan Airport",
  "Fukue Airport (Gotō-Fukue Airport)",
  "Fukuoka Airport (Itazuke Air Base)",
  "Fullerton Municipal Airport",
  "Fuma Airport",
  "Funafuti International Airport",
  "Foshan Shadi Airport",
  "Pointe Vele Airport",
  "Flora Valley Airport",
  "Fuvahmulah Airport",
  "Forrest River Airport",
  "Fort Wayne International Airport",
  "NAS Fort Worth JRB / Carswell Field (FAA: NFW)",
  "Farewell Airport",
  "Fort Lauderdale Executive Airport",
  "Cuamba Airport",
  "Forest City Municipal Airport",
  "Fuyuan Dongji Airport",
  "Fayetteville Municipal Airport",
  "Fuyun Koktokay Airport",
  "Faya-Largeau Airport",
  "Fort Yukon Airport",
  "Drake Field (Fayetteville Executive Airport)",
  "Bristol Filton Airport",
  "Guamal Airport",
  "Gabbs Airport",
  "Gracias Airport",
  "Northeast Alabama Regional Airport",
  "Gabès – Matmata International Airport",
  "Gafsa – Ksar International Airport",
  "Gage Airport",
  "Gayndah Airport",
  "Montgomery County Airpark",
  "Yamagata Airport (Junmachi Airport)",
  "Edward G. Pitka Sr. Airport",
  "Gambell Airport",
  "Gan International Airport",
  "Mariana Grajales Airport",
  "Gusap Airport",
  "Gao International Airport (Korogoussou Airport)",
  "Garaina Airport",
  "Garissa Airport",
  "Gap–Tallard Airport",
  "Lokpriya Gopinath Bordoloi International Airport",
  "Gag Island Airport",
  "Gangaw Airport",
  "Gamba Airport",
  "Gaya Airport (Bodhgaya Airport)",
  "Guasopa Airport",
  "Cotswold Airport",
  "Qabala International Airport",
  "Gasuke Airport",
  "Great Bend Municipal Airport",
  "Sir Seretse Khama International Airport",
  "Negarbo Airport",
  "Galesburg Municipal Airport",
  "Galbraith Lake Airport",
  "Kalaburagi Airport",
  "Marie-Galante Airport (Les Bases)",
  "Gbangbatok Airport",
  "South Goulburn Island Airport",
  "Garbaharey Airport",
  "Gamboola Airport",
  "Walter J. Koladza Airport",
  "Gorgan Airport",
  "Khashm el Girba Airport",
  "Gibb River Airport",
  "Ginbata Airport",
  "Great Barrier Aerodrome",
  "Guacamayas Airport",
  "Gillette–Campbell County Airport",
  "Grand Coulee Dam Airport (FAA: 3W7)",
  "Gachsaran Airport",
  "Guernsey Airport",
  "Grand Central Airport",
  "Garden City Regional Airport",
  "Owen Roberts International Airport",
  "Grand Canyon National Park Airport",
  "Grand Canyon Bar 10 Airport",
  "Grand Canyon West Airport (FAA: 1G4)",
  "Greeneville–Greene County Municipal Airport",
  "Gounda Airport",
  "Donaldson Center Airport (FAA: GYH)",
  "Gordon Downs Airport",
  "Gode Airport",
  "Magdagachi Airport",
  "Golden Horn Lodge Seaplane Base (FAA: 3Z8)",
  "Gordil Airport",
  "Gandajika Airport",
  "Guadalajara International Airport (Miguel Hidalgo y Costilla Int'l)",
  "Gardner Municipal Airport",
  "Gdańsk Lech Wałęsa Airport",
  "Guasdualito Airport (Vare Maria Airport)",
  "Guadalupe Airport",
  "Gondar Airport (Atse Tewodros Airport)",
  "JAGS McCartney International Airport (Grand Turk Int'l)",
  "Dawson Community Airport",
  "Gladwin Zettel Memorial Airport",
  "Sokol Airport",
  "Gelendzhik Airport",
  "Nouméa Magenta Airport",
  "Gebe Airport",
  "Geçitkale Air Base",
  "Delaware Coastal Airport",
  "George Town Aerodrome",
  "Geva Airport",
  "Spokane International Airport",
  "Sepé Tiaraju Airport",
  "President Obiang Nguema International Airport",
  "Cheddi Jagan International Airport",
  "Rafael Cabrera Mustelier Airport",
  "General Santos International Airport (Tambler Airport)",
  "Geraldton Airport",
  "Gällivare Airport",
  "Gewoia Airport",
  "Geelong Airport",
  "South Big Horn County Airport",
  "Pope Field",
  "Grenfell Airport",
  "Griffith Airport",
  "Grand Forks International Airport",
  "Floyd Bennett Memorial Airport",
  "Clarence Valley Regional Airport",
  "Bartica Airport",
  "Granville–Mont-Saint-Michel Aerodrome [fr]",
  "Grootfontein Air Base",
  "Água Boa Airport",
  "Lumbala N'guimbo Airport",
  "Gregory Downs Airport",
  "Georgetown County Airport",
  "Almeirim Airport",
  "East Texas Regional Airport",
  "Gastão Mesquita Airport",
  "Guaíra Airport",
  "Gilgal Airport",
  "Kakamega Airport",
  "Gagnoa Airport",
  "Guiglo Airport",
  "Garowe International Airport",
  "Gobernador Gregores Airport",
  "Exuma International Airport",
  "Glasgow Airport (Wokal Field)",
  "Noumérat – Moufdi Zakaria Airport",
  "Governor's Harbour Airport",
  "Great Harbour Cay Airport",
  "Garachiné Airport",
  "Giebelstadt Airport",
  "Gahcho Kue Aerodrome (TC: CGK2)",
  "Centerville Municipal Airport",
  "Guanghan Airport",
  "West Kutai Melalan Airport",
  "Ghat Airport",
  "Gualeguaychú Airport",
  "Gibraltar International Airport (North Front Airport)",
  "Boigu Island Airport",
  "Gitega Airport",
  "Winter Haven's Gilbert Airport",
  "Rio de Janeiro–Galeão International Airport",
  "Siguiri Airport",
  "Gilgit Airport",
  "Miele Mimbale Airport",
  "Santiago Vila Airport",
  "Gisborne Airport",
  "Geita Airport",
  "Sigiriya Airport",
  "Giyani Airport",
  "Jizan Regional Airport (King Abdullah bin Abdulaziz Airport)",
  "Guanaja Airport",
  "Jijel Ferhat Abbas Airport",
  "Guajará-Mirim Airport",
  "Gjögur Airport",
  "Grand Junction Regional Airport (Walker Field)",
  "Goroka Airport",
  "Gökçeada Airport",
  "NATO Air Base Geilenkirchen",
  "Palungtar Airport",
  "Kooddoo Airport",
  "Great Keppel Island Airport",
  "Gulkana Airport",
  "Kongo Boumba Airport",
  "Gatlinburg–Pigeon Forge Airport",
  "Glasgow Airport",
  "San Carlos Apache Airport (FAA: P13)",
  "Geladi Airport",
  "Goodland Municipal Airport (Renner Field)",
  "Gainesville Municipal Airport",
  "Golfito Airport",
  "Glengyle Airport",
  "Mid-Delta Regional Airport",
  "Glen Innes Airport",
  "Abdullahi Yusuf International Airport",
  "Gol Airport, Klanten",
  "Glenormiston Airport",
  "Guelmim Airport",
  "Gloucestershire Airport",
  "Gulgubip Airport",
  "Gaylord Regional Airport",
  "Scholes International Airport at Galveston",
  "Gladstone Airport",
  "Gelephu Airport",
  "Golovin Airport",
  "Glasgow Municipal Airport",
  "Gamar Malamo Airport",
  "Goldsworthy Airport",
  "Gilze-Rijen Air Base",
  "Gemena Airport",
  "Gambela Airport",
  "Guerima Airport",
  "Ben Slimane Airport",
  "Gomel Airport",
  "Gasmata Airport",
  "Gamboma Airport",
  "Greymouth Airport",
  "Gombe Lawanti International Airport",
  "Gimpo International Airport",
  "Golog Maqin Airport",
  "Totegegie Airport (Gambier Island Airport)",
  "Guimarães Airport",
  "Granite Mountain Air Station (FAA: GSZ)",
  "Greenville Downtown Airport",
  "Monument Valley Airport (FAA: UT25)",
  "La Gomera Airport",
  "Grodno Airport",
  "Grenoble–Isère Airport",
  "Maurice Bishop International Airport",
  "Gansner Field (FAA: 2O1)",
  "Gooding Municipal Airport",
  "Lyudao Airport (Green Island Airport)",
  "Guanambi Airport",
  "Ginir Airport",
  "Dr. Arturo Umberto Illia Airport",
  "Binaka Airport",
  "Grants-Milan Municipal Airport",
  "Goodnews Airport",
  "Gainesville Regional Airport",
  "Şanlıurfa GAP Airport",
  "Ghanzi Airport",
  "Genoa Cristoforo Colombo Airport",
  "Robe Airport",
  "Gora Airport",
  "Gonaili Airstrip",
  "Gobabis Airport",
  "Nuuk Airport",
  "Goa International Airport (Dabolim Airport)",
  "Nizhny Novgorod International Airport (Strigino Airport)",
  "Guthrie–Edmond Regional Airport",
  "Gold Beach Municipal Airport (FAA: 4S1)",
  "Goma International Airport",
  "Groton–New London Airport",
  "Goondiwindi Airport",
  "Gorakhpur Airport",
  "Golmud Airport",
  "Gore Airport",
  "Gosford Airport",
  "Göteborg Landvetter Airport",
  "Garoua International Airport",
  "Gove Airport",
  "Gorna Oryahovitsa Airport",
  "Araxos Airport",
  "Tancredo Thomas de Faria Airport",
  "Mount Gordon Airport",
  "Guapi Airport (Juan Casiano Airport)",
  "Guápiles Airport",
  "Garden Point Airport",
  "General Pico Airport",
  "Seymour Airport",
  "Gulfport–Biloxi International Airport",
  "Grand Rapids–Itasca County Airport (Gordon Newstrom Field)",
  "Galion Municipal Airport",
  "Gamarra Airport",
  "Austin Straubel International Airport",
  "Grand Cess Airport",
  "Greenwood County Airport",
  "Greenville Airport",
  "Gray Army Airfield",
  "Gardez Airport",
  "Garuahi Airport",
  "Central Nebraska Regional Airport",
  "George Airport",
  "Killeen–Fort Hood Regional Airport / Robert Gray Army Airfield",
  "Garasa Airport",
  "Grand Marais/Cook County Airport (FAA: CKC)",
  "Gordon Municipal Airport",
  "Girona–Costa Brava Airport",
  "Gurupi Airport",
  "Groningen Airport Eelde",
  "Gerald R. Ford International Airport",
  "Grosseto Airport",
  "Gujrat Airport",
  "São Paulo/Guarulhos–Governador André Franco Montoro International Airport",
  "Grozny Airport",
  "Graciosa Airport",
  "Federico García Lorca Airport (Granada Jaén Airport)",
  "Grímsey Airport",
  "Graz Airport",
  "Long Pasia Airport",
  "Seymour Johnson Air Force Base",
  "Gascoyne Junction Airport",
  "Göteborg City Airport",
  "Goshen Municipal Airport",
  "Grand-Santi Airport",
  "San José Airport",
  "Taltheilei Narrows Airport (TC: CFA7)",
  "Dayrestan Airport (Qeshm International Airport)",
  "Mount Gunson Airport",
  "Piedmont Triad International Airport",
  "Greenville–Spartanburg International Airport",
  "Sharq Al-Owainat Airport",
  "Qardho Airport",
  "Sabi Sabi Airport",
  "Gustavus Airport",
  "Azaza Airport",
  "Saratov Gagarin Airport",
  "Gatokae Aerodrome",
  "Groote Eylandt Airport",
  "Great Falls International Airport",
  "Grantsburg Municipal Airport",
  "Rügen Airport (Güttin Airfield)",
  "Glentanner Aerodrome",
  "Jalaluddin Airport",
  "Grants Pass Airport (FAA: 3S8)",
  "Golden Triangle Regional Airport",
  "The Granites Airport",
  "Georgetown Airport",
  "Holešov Airport",
  "Gettysburg Regional Airport (FAA: W05)",
  "Kirawira B Airstrip",
  "La Aurora International Airport",
  "Guerrero Negro Airport",
  "Gunnison–Crested Butte Regional Airport",
  "Goundam Airport",
  "Guriaso Airport",
  "Jack Edwards Airport (FAA: JKA)",
  "Guari Airport",
  "Gunnedah Airport",
  "Güiria Airport",
  "Guaratinguetá Airport",
  "Goulburn Airport",
  "Antonio B. Won Pat International Airport (Guam Int'l)",
  "Gallup Municipal Airport",
  "Guanare Airport",
  "Gurney Airport",
  "Grissom Air Reserve Base",
  "RAF Gütersloh",
  "Grundarfjörður Airport",
  "Mougulu Airport",
  "Atyrau Airport",
  "Guna Airport",
  "Guymon Municipal Airport",
  "Guarapari Airport",
  "Geneva Airport",
  "Gordonsville Municipal Airport",
  "Green River Airport",
  "Lee Gilmer Memorial Airport",
  "Sovetskaya Gavan Airport",
  "Greenvale Airport",
  "Coronel Altino Machado de Oliveira Airport",
  "Majors Airport",
  "Gävle-Sandviken Airport",
  "Gwa Airport",
  "Gwadar International Airport",
  "Thornhill Air Base",
  "Rajmata Vijaya Raje Scindia Airport (Gwalior Airport)",
  "Gnarowein Airport",
  "Greenwood–Leflore Airport",
  "Glenwood Springs Municipal Airport",
  "Sylt Airport (Westerland Airport)",
  "Glendale Fokker Field (FAA: WV66)",
  "Galway Airport",
  "Beringin Airport",
  "Sayun Airport",
  "Negage Airport",
  "Gannan Xiahe Airport",
  "Teniente Vidal Airfield",
  "Yagoua Airport",
  "Greeley–Weld County Airport",
  "Guayaramerín Airport",
  "Heydar Aliyev International Airport",
  "José Joaquín de Olmedo International Airport",
  "Magan Airport",
  "Gisenyi Airport",
  "Argyle Airport",
  "General José María Yáñez International Airport",
  "Santa Genoveva Airport",
  "Gympie Airport",
  "Phoenix Goodyear Airport",
  "Guangyuan Panlong Airport",
  "Guyuan Liupanshan Airport",
  "Yasser Arafat International Airport",
  "Garze Gesar Airport",
  "Ghazni Airport",
  "Nusatupe Airport",
  "Gazipaşa–Alanya Airport",
  "Gaziantep Oğuzeli International Airport",
  "Qazvin Airport",
  "Hasvik Airport",
  "Marion County – Rankin Fite Airport",
  "Hachijojima Airport",
  "Halmstad Airport (Halmstad City Airport)",
  "Half Moon Bay Airport",
  "Prince Said Ibrahim International Airport",
  "Three Rivers Municipal Airport (Dr. Haines Flying Field)",
  "Hannover Airport",
  "Haikou Meilan International Airport",
  "Halali Airport",
  "Hamburg Airport",
  "Noi Bai International Airport",
  "Butler County Regional Airport",
  "Hanimaadhoo International Airport",
  "Capital City Airport (FAA: CXY)",
  "Ha'il Regional Airport",
  "Heathlands Airport",
  "Haugesund Airport, Karmøy",
  "José Martí International Airport",
  "Haverfordwest Aerodrome",
  "Hacaritama Airport",
  "Hatzfeldthaven Airport",
  "Hobart International Airport",
  "Hobbs Industrial Airpark",
  "Habi Airport",
  "Borg El Arab Airport",
  "Hattiesburg Bobby L. Chain Municipal Airport",
  "Entrance Island Seaplane Base (Hobart Bay) (FAA: 2Z1)",
  "Holbrook Municipal Airport (FAA: P14)",
  "Qilian Airport",
  "Hobart Regional Airport",
  "Hambantota Sea Plane Base",
  "Bulgan Airport, Khovd",
  "Hubli Airport",
  "Big Spring McMahon–Wrinkle Airport (FAA: BPG)",
  "Columbia County Airport (FAA: 1B1)",
  "Hechi Jinchengjiang Airport",
  "Eyl Airport",
  "Hengchun Airport",
  "Halls Creek Airport",
  "Holy Cross Airport (FAA: HCA)",
  "Cheraw Municipal Airport (Lynch Bellinger Field, FAA: CQW)",
  "Heidelberg Airport",
  "Hyderabad Airport",
  "Brewster Field",
  "Heringsdorf Airport",
  "Handan Airport",
  "Dillingham Airfield",
  "Kulhudhuffushi Airport",
  "Hamadan Airport",
  "Yampa Valley Airport",
  "Havadarya Airport",
  "Air Force Base Hoedspruit",
  "Hat Yai International Airport",
  "Herat International Airport",
  "Hinthada Airport",
  "Herendeen Bay Airport (FAA: AK33)",
  "Thompson–Robbins Airport",
  "Heho Airport",
  "Heide–Büsum Airport",
  "Heihe Airport",
  "Helsinki Airport (Helsinki-Vantaa Airport)",
  "Helsinki-Malmi Airport",
  "Haelogo Airport",
  "Heraklion International Airport (Nikos Kazantzakis Airport)",
  "Hermiston Municipal Airport (FAA: HRI)",
  "Hohhot Baita International Airport",
  "Natchez–Adams County Airport (Hardy–Anders Field)",
  "Haifa Airport (Uri Michaeli Airport)",
  "Hartford–Brainard Airport",
  "Hefei Xinqiao International Airport (formerly Hefei Luogang International Airport)",
  "Mackall Army Airfield",
  "Hornafjörður Airport",
  "Hagfors Airport",
  "Hammerfest Airport",
  "Hargeisa International Airport (Egal Int'l)",
  "Hughenden Airport",
  "Higuerote Airport",
  "Hangzhou Xiaoshan International Airport",
  "Paloich Airport",
  "Heligoland Airport (Düne Airport)",
  "Mae Hong Son Airport",
  "Korhogo Airport",
  "Hagerstown Regional Airport (Richard A. Henson Field)",
  "Hastings Airport",
  "Mount Hagen Airport",
  "Hog River Airport (FAA: 2AK6)",
  "JMSDF Hachinohe Air Base",
  "Hilton Head Airport (FAA: HXD)",
  "Wheeler Army Airfield",
  "Frankfurt–Hahn Airport",
  "Hua Hin Airport",
  "Hawthorne Municipal Airport (Jack Northrop Field)",
  "Hikueru Airport",
  "Huai'an Lianshui Airport",
  "Range Regional Airport",
  "Horn Island Airport",
  "Mount Washington Regional Airport",
  "Hill Air Force Base",
  "Highbury Airport",
  "Lake Havasu City Airport",
  "Hiroshima Airport",
  "Shilavo Airport",
  "Hingurakgoda Airport (Minneriya Airport)",
  "Sacheon Airport",
  "Hillsboro Airport (Portland–Hillsboro Airport)",
  "Headingly Airport",
  "Honiara International Airport",
  "Haivaro Airport",
  "Hiroshima–Nishi Airport",
  "Huaihua Zhijiang Airport",
  "Civil Aerodrome Khajuraho",
  "Khujirt Airport",
  "Blytheville Municipal Airport",
  "Healy Lake Airport",
  "Hakodate Airport",
  "Hong Kong International Airport (Chek Lap Kok Airport)",
  "Hokitika Airport",
  "Hoskins Airport",
  "Hawkins Field",
  "Phuket International Airport",
  "Haskovo Malevo Airport",
  "Hickory Regional Airport",
  "Lanseria International Airport",
  "Hillenbrand Industries Airport",
  "Hill City Municipal Airport",
  "Hulunbuir Hailar Airport",
  "Saint Helena Airport",
  "Hultsfred-Vimmerby Airport",
  "Wheeling Ohio County Airport",
  "Ulanhot Airport",
  "Hollister Municipal Airport (FAA: CVH)",
  "Hillside Airport",
  "Park Township Airport",
  "Helena Regional Airport",
  "Halim Perdanakusuma International Airport",
  "Hood Army Airfield",
  "St Helens Airport",
  "Hamilton Airport",
  "Helenvale Airport",
  "Hluhluwe Airport",
  "Hamilton Airport",
  "Khanty-Mansiysk Airport",
  "Sohag International Airport",
  "Oued Irara–Krim Belkacem Airport",
  "Hermannsburg Airport",
  "Hami Airport (Kumul Airport)",
  "Khmelnytskyi Airport",
  "Holloman Air Force Base",
  "General Ignacio Pesqueira García International Airport",
  "Hamar Airport, Stafsberg",
  "Hemet-Ryan Airport",
  "Hemavan Tärnaby Airport",
  "Seosan Air Base",
  "Hanamaki Airport",
  "Huntingburg Airport",
  "Billy Mitchell Airport (FAA: HSE)",
  "Haneda Airport",
  "Hoonah Airport",
  "Heiweni Airport",
  "Honolulu International Airport",
  "Hana Airport",
  "Honinabi Airport",
  "Haines Airport",
  "Hengyang Nanyue Airport",
  "Hola Airport",
  "Lea County Regional Airport",
  "Komako Airport",
  "Hodeida International Airport",
  "Ban Huoeisay Airport",
  "Al-Ahsa International Airport",
  "Frank País Airport",
  "Hohenems-Dornbirn Airport",
  "Hao Airport",
  "Hooker Creek Airport",
  "Homer Airport",
  "Huron Regional Airport",
  "Nhon Co Airport",
  "Campbell Army Airfield",
  "Hof–Plauen Airport",
  "Horta Airport",
  "Chos Malal Airport",
  "Memorial Field Airport",
  "William P. Hobby Airport",
  "Ørsta–Volda Airport, Hovden",
  "Homalin Airport",
  "Hoy Island Airport",
  "Lifuka Island Airport (Salote Pilolevu Airport)",
  "Hooper Bay Airport",
  "Hopevale Airport",
  "Shennongjia Hongping Airport",
  "Cat Bi International Airport",
  "Westchester County Airport",
  "Hampton Municipal Airport",
  "Princeville Airport (FAA: HI01)",
  "Baytown Airport",
  "Bowerman Airport",
  "Harbin Taiping International Airport",
  "Zhayrem Airport",
  "Harare International Airport",
  "Hurghada International Airport",
  "Mattala Rajapaksa International Airport",
  "Kharkiv International Airport (Osnova Airport)",
  "Valley International Airport",
  "Hassi R'Mel Airport (Tilrempt Airport)",
  "Boone County Airport",
  "La Herrera Airport",
  "Harrismith Airport",
  "RAF Linton-on-Ouse",
  "Henbury Airport",
  "Horizontina Airport",
  "Harrisburg-Raleigh Airport",
  "Shaoguan Guitou Airport",
  "Saga Airport",
  "Henderson Executive Airport (FAA: HND)",
  "Hastings Municipal Airport",
  "Zhengzhou Shangjie Airport",
  "Huesca–Pirineos Airport",
  "Huslia Airport (FAA: HLA)",
  "Horsham Airport",
  "Zhoushan Putuoshan Airport",
  "Ingalls Field",
  "Hisar Airport",
  "Homestead Air Reserve Base",
  "Huntsville International Airport (Carl T. Jones Field)",
  "Hsinchu Air Base",
  "Chita International Airport (Kadala Airport)",
  "Khatanga Airport",
  "Hawthorne Industrial Airport",
  "Great Barrier Reef Airport",
  "Roscommon County–Blodgett Memorial Airport",
  "Khatgal Airport",
  "Hotan Airport",
  "East Hampton Airport",
  "Hateruma Airport",
  "Tri-State Airport (Milton J. Ferguson Field)",
  "Huatugou Airport",
  "Hopetoun Airport",
  "Huntsville Municipal Airport (Bruce Brothers Regional)",
  "Lawrence County Airpark",
  "Hatay Airport",
  "Hato Corozal Airport",
  "Redstone Army Airfield",
  "Humbert River Airport",
  "Humacao Airport",
  "Humboldt Municipal Airport (FAA: 0K7)",
  "Humera Airport",
  "Terre Haute International Airport (Hulman Field)",
  "Huehuetenango Airport",
  "Huahine – Fare Airport",
  "Phu Bai International Airport",
  "Stan Stamper Municipal Airport (FAA: HHW)",
  "Hukuntsi Airport",
  "Houlton International Airport",
  "Houma–Terrebonne Airport",
  "Hualien Airport",
  "Holingol Huolinhe Airport",
  "Hun Airport",
  "Hughes Airport",
  "Hutchinson Municipal Airport",
  "Alférez FAP David Figueroa Fernandini Airport",
  "Hudiksvall Airport",
  "Francisco Correa da Cruz Airport",
  "Bahías de Huatulco International Airport",
  "Humberside Airport",
  "Huizhou Pingtan Airport",
  "Analalava Airport",
  "Hervey Bay Airport",
  "Khovd Airport",
  "Hanksville Airport",
  "Honningsvåg Airport, Valan",
  "Hólmavík Airport",
  "Tweed New Haven Airport",
  "Havre City–County Airport",
  "Hartsville Regional Airport",
  "Hawabango Airport",
  "Hayward Executive Airport",
  "Hawk Inlet Seaplane Base",
  "Wilpena Pound Airport",
  "Hwange National Park Airport",
  "North Perry Airport",
  "Delingha Airport",
  "Hay Airport",
  "Barnstable Municipal Airport (Boardman/Polando Field)",
  "Wycombe Air Park",
  "Rajiv Gandhi International Airport",
  "Hayfields Airport",
  "Hydaburg Seaplane Base",
  "Hollis Clark Bay Seaplane Base",
  "Taizhou Luqiao Airport",
  "Sawyer County Airport",
  "Hays Regional Airport",
  "Hyvinkää Airfield",
  "Merville–Calonne Airport",
  "Hanzhong Chenggu Airport",
  "Liping Airport",
  "Húsavík Airport",
  "Hazleton Municipal Airport",
  "Fort MacKay/Horizon Airport",
  "Hazyview Airport",
  "Igarka Airport",
  "McConnell Air Force Base",
  "Washington Dulles International Airport",
  "Niagara Falls International Airport",
  "George Bush Intercontinental Airport",
  "In Amenas Airport (Zarzaitine Airport)",
  "Bob Baker Memorial Airport",
  "Sayak Airport (Siargao Airport)",
  "Bahregan Airport",
  "Tunoshna Airport",
  "Iași International Airport",
  "Iaura Airport",
  "Ibadan Airport",
  "General Villamil Airport",
  "Perales Airport",
  "Iboki Airport",
  "Indigo Bay Lodge Airport",
  "Ibo Island Airport",
  "Iberia Airport",
  "Ibaraki Airport",
  "Ibiza Airport",
  "Icabarú Airport",
  "Andrés Miguel Salazar Marcano Airport",
  "Cicia Airport",
  "Majoor Henk Fernandes Airport (Nieuw Nickerie Airport)",
  "Schenck Field",
  "Incheon International Airport",
  "Sicogon Airport",
  "Cascade Airport (FAA: KU70)",
  "Wichita Dwight D. Eisenhower National Airport",
  "Icy Bay Airport (FAA: 19AK)",
  "Idaho Falls Regional Airport",
  "Idre Airport",
  "Chilonzuine Island Airstrip",
  "Idiofa Airport",
  "Ida Grove Municipal Airport",
  "Idaho County Airport (FAA: GIC)",
  "Indiana County–Jimmy Stewart Airport",
  "Indulkana Airport",
  "Indagen Airport",
  "Santa Isabel do Morro Airport",
  "Independence Municipal Airport",
  "Devi Ahilyabai Holkar International Airport",
  "Île d'Yeu Aerodrome",
  "Zielona Góra Airport",
  "Iejima Airport",
  "Riesa–Göhlis Airfield",
  "Kyiv International Airport (Zhuliany)",
  "Iowa Falls Municipal Airport",
  "Iffley Airport",
  "Hesa Air Base",
  "Ísafjörður Airport",
  "Innisfail Airport",
  "Isfahan International Airport (Shahid Beheshti Int'l)",
  "Ivano-Frankivsk International Airport",
  "Laughlin/Bullhead International Airport",
  "Ifuru Airport",
  "Inagua Airport (Matthew Town Airport)",
  "Ingeniero Jacobacci Airport (Capitán FAA H. R. Borden Airport)",
  "Iğdır Airport",
  "Tchongorove Airport",
  "Igiugig Airport",
  "Ingham Airport",
  "Çiğli Air Base",
  "Kingman Airport",
  "Maria Cristina Airport (Iligan Airport)",
  "Jaime Ortíz Betancur Airport",
  "Cataratas del Iguazú International Airport",
  "Ingolstadt Manching Airport",
  "Magas Airport (Sleptsovskaya Airport)",
  "Foz do Iguaçu International Airport",
  "Inhaca Airport",
  "Qishn Airport",
  "Ihosy Airport",
  "Iranshahr Airport",
  "Ihu Airport",
  "Inishmaan Aerodrome",
  "Ilam Airport",
  "Nissan Island Airport",
  "Izhevsk Airport",
  "João Batista Bos Filho Airport",
  "Jacksonville Municipal Airport",
  "Tehran Imam Khomeini International Airport",
  "Wilkes County Airport (FAA: UKF)",
  "Iki Airport",
  "Greater Kankakee Airport",
  "Ikela Airport",
  "Nikolski Air Station",
  "Inkerman Airport",
  "Tiksi Airport",
  "International Airport Irkutsk",
  "Issyk-Kul International Airport",
  "Illaga Airport",
  "Lleida–Alguaire Airport",
  "Skylark Field",
  "Ilford Airport",
  "Wilmington Airport (New Castle Airport)",
  "Illesheim Army Airfield",
  "Iliamna Airport",
  "Ilaka-Est Airport (Atsinanana Airport)",
  "Willmar Municipal Airport (John L. Rice Field)",
  "Wilmington International Airport",
  "Wilmington Air Park",
  "Iloilo International Airport",
  "Île des Pins Airport",
  "Ilo Airport",
  "Ilorin International Airport",
  "Ilopango International Airport",
  "Kilaguni Airport",
  "Ileg Airport",
  "Islay Airport (Glenegedale Airport)",
  "Žilina Airport",
  "Iamalele Airport",
  "Imbaimadai Airport",
  "Imonda Airport",
  "Imphal International Airport (Tulihal Airport)",
  "Inhaminga Airport",
  "Ine Airport (FAA: N20)",
  "Simikot Airport",
  "Imperial Municipal Airport",
  "Immokalee Regional Airport",
  "Imane Airport",
  "Zemio Airport",
  "Imperatriz Airport (Prefeito Renato Moreira Airport)",
  "Maku International Airport",
  "Ford Airport",
  "Inta Airport",
  "Independence Airport",
  "Yinchuan Hedong International Airport",
  "Indianapolis International Airport",
  "Chinde Airport",
  "In Guezzam Airport",
  "Inhambane Airport",
  "Niš Constantine the Great Airport",
  "Injune Airport",
  "Winkler County Airport",
  "Falls International Airport",
  "Innamincka Airport",
  "Innsbruck Airport (Kranebitten Airport)",
  "Inongo Airport",
  "Inisheer Aerodrome",
  "Creech Air Force Base",
  "Smith Reynolds Airport",
  "Nauru International Airport",
  "Inverness Airport",
  "Winslow–Lindbergh Regional Airport",
  "Inanwatan Airport",
  "Inyati Airport",
  "In Salah Airport",
  "Ioannina National Airport",
  "Iokea Airport",
  "Isle of Man Airport (Ronaldsway Airport)",
  "Impfondo Airport",
  "Ioma Airport",
  "Inishmore Aerodrome (Kilronan Airport)",
  "Ilhéus Jorge Amado Airport",
  "Illorsuit Heliport",
  "Iowa City Municipal Airport",
  "Ipota Airport",
  "Mataveri International Airport (Isla de Pascua Airport)",
  "Ipil Airport",
  "Ipiranga Airport",
  "Sultan Azlan Shah Airport",
  "San Luis Airport",
  "Imperial County Airport (Boley Field)",
  "Usiminas Airport",
  "Williamsport Regional Airport",
  "Ipiaú Airport",
  "San Isidro de El General Airport",
  "Al Asad Airbase",
  "Qiemo Airport",
  "Qingyang Airport",
  "Diego Aracena International Airport",
  "Coronel FAP Francisco Secada Vignetta International Airport",
  "Kirakira Airport (Ngorangora Airstrip)",
  "Iraan Municipal Airport (FAA: 2F0)",
  "Circle City Airport (FAA: CRC)",
  "Ishwardi Airport",
  "Irecê Airport",
  "Lockhart River Airport",
  "Iringa Airport (Nduli Airport)",
  "Capitán Vicente Almandos Almonacid Airport",
  "Kirksville Regional Airport",
  "Igrim Airport",
  "Iriona Airport",
  "Birao Airport",
  "Matari Airport",
  "Kirsch Municipal Airport",
  "Iranamadu Waterdrome",
  "Tapuruquara Airport",
  "Mount Isa Airport",
  "Islamabad International Airport",
  "St Mary's Airport",
  "Iscuande Airport",
  "Isparta Süleyman Demirel Airport",
  "New Ishigaki Airport",
  "Isisford Airport",
  "Isla Mujeres Airport",
  "Ozar Airport",
  "Istanbul Atatürk Airport",
  "Kissimmee Gateway Airport",
  "Sloulin Field International Airport",
  "Kinston Regional Jetport (Stallings Field)",
  "Long Island MacArthur Airport",
  "Schoolcraft County Airport",
  "Wiscasset Airport (FAA: IWI)",
  "Istanbul New Airport",
  "Sulaimaniyah International Airport",
  "South Wood County Airport (Alexander Field)",
  "Itacoatiara Airport",
  "Itaituba Airport",
  "Ituberá Airport",
  "Ithaca Tompkins Regional Airport",
  "Itambacuri Airport",
  "Itokama Airport",
  "Osaka International Airport (Itami Int'l)",
  "Itabuna Airport",
  "Hilo International Airport",
  "Itaperuna Airport",
  "Itaqui Airport",
  "Itumbiara Airport",
  "Iturup Airport",
  "Niue International Airport (Hanan Int'l)",
  "Ilu Airport",
  "Inus Airport",
  "Ambanja Airport",
  "Invercargill Airport",
  "Dolac Airport",
  "Viveros Island Airport",
  "Ivalo Airport",
  "Chibolo Airport",
  "Inverell Airport",
  "Inverway Airport",
  "Ivanovo Yuzhny Airport",
  "Gogebic–Iron County Airport",
  "Iwami Airport (Hagi-Iwami Airport)",
  "Marine Corps Air Station Iwakuni",
  "Iwo Jima Air Base",
  "West Houston Airport",
  "Agartala Airport (Singerbhil Airport)",
  "Bagdogra Airport",
  "Chandigarh Airport",
  "Allahabad Airport (Bamrauli Air Force Base)",
  "Mangalore Airport",
  "Belgaum Airport",
  "Kailashahar Airport",
  "Lilabari Airport",
  "Jammu Airport (Satwari Airport)",
  "Keshod Airport",
  "Kushok Bakula Rimpochee Airport",
  "Madurai Airport",
  "Khowai Airport",
  "Pathankot Airport",
  "Kamalpur Airport",
  "Birsa Munda Airport",
  "Silchar Airport (Kumbhirgram Air Force Base)",
  "Pasighat Airport",
  "Aurangabad Airport (Chikkalthana Airport)",
  "Along Airport",
  "Sonari Airport",
  "Bidar Airport",
  "Kandla Airport (Gandhidham Airport)",
  "Veer Savarkar International Airport (Port Blair Airport)",
  "Inyokern Airport",
  "Presidente Itamar Franco Airport (Zona da Mata Regional Airport)",
  "Metropolitan Area",
  "Izumo Airport",
  "Ixtepec Airport",
  "Jalalabad Airport",
  "Jabiru Airport",
  "Jackson Hole Airport",
  "Jandakot Airport",
  "Jaén Airport",
  "Jaffna Airport",
  "PAF Base Shahbaz",
  "Jaipur International Airport",
  "Jacmel Airport",
  "El Lencero Airport",
  "Bezmer Air Base",
  "Jackson–Evers International Airport",
  "Chacarita Airport",
  "Jacquinot Bay Airport",
  "Jahrom Airport",
  "Jasper County Airport (Bell Field)",
  "Jabot Airport",
  "Francisco Carle Airport",
  "Ilulissat Airport",
  "Jacksonville International Airport",
  "Notohadinegoro Airport",
  "La Isabela International Airport (Dr. Joaquin Balaguer Int'l)",
  "Jonesboro Municipal Airport",
  "São Borja Airport",
  "Bethel Seaplane Base (FAA: Z59)",
  "Santa Terezinha Municipal Airport",
  "New Century AirCenter (FAA: IXD)",
  "Julia Creek Airport",
  "Jacobina Airport",
  "Jacareacanga Airport",
  "Kimble County Airport",
  "LBJ Ranch Airport (FAA: 0TE7)",
  "Grant County Regional Airport (Ogilvie Field) (FAA: GCD)",
  "Francisco Álvares de Assis Airport",
  "Jeongseok Airport",
  "Jodhpur Airport",
  "Jordan Airport",
  "Juazeiro do Norte Airport",
  "Prefeito Octávio de Almeida Neves Airport",
  "Jingdezhen Luojia Airport",
  "King Abdulaziz International Airport",
  "Jérémie Airport",
  "Jefferson City Memorial Airport",
  "Aasiaat Airport",
  "Jeh Airport",
  "Jeki Airstrip",
  "Jequié Airport",
  "Jersey Airport",
  "John F. Kennedy International Airport",
  "Northeast Ohio Regional Airport (FAA: HZY)",
  "Paamiut Airport",
  "Jamnagar Airport (Govardhanpur Airport)",
  "Jagdalpur Airport",
  "Jiagedaqi Airport",
  "Jiayuguan Airport",
  "Jinggangshan Airport",
  "Senai International Airport",
  "Xishuangbanna Gasa Airport",
  "Fort MacKay/Albian Aerodrome (TC: CAL4)",
  "Kapalua Airport",
  "Sisimiut Airport",
  "Chautauqua County-Jamestown Airport",
  "Juína Airport",
  "Djibouti–Ambouli International Airport",
  "Jinchang Jinchuan Airport",
  "Wilwal International Airport",
  "Ikaria Island National Airport",
  "Jilin Ertaizi Airport",
  "Aba Segud Airport",
  "Jinja Airport",
  "Jos Orno Imsula Airport",
  "Jipijapa Airport",
  "Qianjiang Wulingshan Airport",
  "Jiri Airport",
  "Jiujiang Lushan Airport",
  "Jiwani Airport",
  "Jajao Airport",
  "Comte. Ariston Pessoa Regional Airport",
  "Humberto Ghizzo Bortoluzzi Regional Airport",
  "Juanjuí Airport",
  "Mulika Lodge Airport",
  "Quanzhou Jinjiang International Airport",
  "Jönköping Airport",
  "Chios Island National Airport",
  "Kalymnos Island National Airport",
  "Janakpur Airport",
  "metropolitan area2",
  "Cherokee County Airport (FAA: JSO)",
  "Quartz Creek Airport",
  "Joplin Regional Airport",
  "Jabalpur Airport (Dumna Airport)",
  "Jales Airport",
  "Jamba Airport",
  "Lancang Jingmai Airport",
  "Mykonos Island National Airport",
  "Jomsom Airport",
  "Jamestown Regional Airport",
  "Jiamusi Dongjiao Airport",
  "Januária Airport",
  "O. R. Tambo International Airport",
  "Jining Qufu Airport",
  "Junín Airport",
  "Ja'Aluni Airport",
  "Juneau International Airport",
  "Naxos Island National Airport",
  "Jinzhou Bay Airport",
  "Joensuu Airport",
  "Adisucipto International Airport",
  "Port St. Johns Airport",
  "Joinville-Lauro Carneiro de Loyola Airport",
  "Doris Lake Aerodrome (TC: CDL7)",
  "Yoshkar-Ola Airport",
  "Jolo Airport",
  "Njombe Airport",
  "Josephstaal Airport",
  "Yakubu Gowon Airport",
  "Joliet Regional Airport",
  "Presidente Castro Pinto International Airport",
  "José Coleto Airport",
  "Qaarsut Airport (Uummannaq/Qaarsut Airport)",
  "Jaqué Airport",
  "Kalaeloa Airport (John Rodgers Field)",
  "Veer Surendra Sai Airport",
  "Jorhat Airport (Rowriah Airport)",
  "Juruena Airport",
  "Kilimanjaro International Airport",
  "Atarot Airport (Jerusalem International Airport)",
  "Jaisalmer Airport",
  "Sitia Public Airport",
  "Skiathos Island National Airport",
  "Jiansanjiang Airport",
  "Jask Airport",
  "José de San Martín Airport",
  "Jessore Airport",
  "John Murtha Johnstown–Cambria County Airport",
  "Maniitsoq Airport",
  "Syros Island National Airport",
  "Moussa Nakhl Tobias–Bauru/Arealva State Airport",
  "Jataí Airport",
  "Santorini (Thira) National Airport",
  "Astypalaia Island National Airport",
  "Inácio Luís do Nascimento Airport",
  "Juba International Airport",
  "Chizhou Jiuhuashan Airport",
  "Juist Airport",
  "Gobernador Horacio Guzmán International Airport",
  "Inca Manco Cápac International Airport",
  "Jumla Airport",
  "Jundah Airport",
  "Juradó Airport",
  "Jurien Bay Airport",
  "Juticalpa Airport",
  "Upernavik Airport",
  "Quzhou Airport",
  "Ankavandra Airport",
  "Central Jersey Regional Airport (FAA: 47N)",
  "Southern Wisconsin Regional Airport",
  "Jwaneng Airport",
  "Zanjan Airport",
  "Jungwon Air Base",
  "Jixi Xingkaihu Airport",
  "Jackson County Airport (Reynolds Field)",
  "Jiroft Airport",
  "Jyväskylä Airport",
  "Jiuzhai Huanglong Airport",
  "Kasama Airport",
  "Kariba Airport",
  "Kamishly Airport",
  "Kaduna Airport",
  "Kake Seaplane Base",
  "Karato Airport",
  "Gangneung Air Base",
  "Kaieteur International Airport",
  "Kajaani Airport",
  "Kar Airport",
  "Kaltag Airport",
  "Kamaran Airport",
  "Mallam Aminu Kano International Airport",
  "Kuusamo Airport",
  "Kapanga Airport",
  "Kamulai Airport",
  "Kamarang Airport",
  "Karasburg Airport",
  "Kaitaia Airport",
  "Kauhava Airfield",
  "Kavanayén Airport",
  "Kawthaung Airport",
  "Kalbarri Airport",
  "Wakaya Airport",
  "Kuabang Airport",
  "Kabala Airport",
  "Kirkimbie Airport",
  "Birch Creek Airport (FAA: Z91)",
  "Kimberley Downs Airport",
  "Bell Island Hot Springs Seaplane Base",
  "Karubaga Airport",
  "Kabalega Falls Airport",
  "Kribi Airport",
  "Kings Canyon Airport",
  "Hamid Karzai International Airport",
  "Kabwum Airport",
  "Tunta Airport",
  "Kabalo Airport",
  "Boryspil International Airport",
  "Kasungu Airport",
  "Sultan Ismail Petra Airport",
  "Bo Airport",
  "Kaben Airport",
  "Gusti Syamsir Alam Airport (Stagen Airport)",
  "Krabi Airport",
  "Chignik Bay Seaplane Base (FAA: Z78)",
  "Kambuaya Airport",
  "Streaky Bay Airport",
  "Kaikoura Aerodrome",
  "Kuqa Qiuci Airport",
  "Tepoe Airstrip",
  "Coffman Cove Seaplane Base",
  "Kamur Airport",
  "Collinsville Airport",
  "Kadanwari Airport",
  "Chignik Fisheries Airport (closed)",
  "Kuching International Airport",
  "Komaio Airport",
  "Kirensk Airport",
  "Chignik Lagoon Airport (Chignik Flats Airport)",
  "Kahramanmaraş Airport",
  "Chernofski Harbor Seaplane Base",
  "Cengiz Topel Naval Air Station",
  "Kamianets-Podilskyi Airport",
  "Chignik Lake Airport (FAA: A79)",
  "Colorado Creek Airport",
  "Kings Creek Station Airport",
  "Koggala Airport",
  "Masindi Airport",
  "Kōchi Ryōma Airport",
  "Kolda North Airport",
  "Kambalda Airport",
  "Kandi Airport",
  "Khuzdar Airport",
  "Koroba Airport",
  "Kandahar International Airport",
  "Haluoleo Airport",
  "Ndjolé Ville Airport",
  "Kodiak Municipal Airport",
  "Kärdla Airport",
  "Kaadedhdhoo Airport",
  "Ndendé Airport",
  "Kadhdhoo Airport",
  "Kandep Airport",
  "Kamberatoro Airport",
  "Kandrian Airport",
  "Kamaran Downs Airport",
  "Kamphaeng Saen Airport",
  "Skardu Airport",
  "Vunisea Airport",
  "Victoria Reservoir Seaplane Base",
  "Kadugli Airport",
  "Teply Klyuch Airport",
  "Polgolla Reservoir Seaplane Base",
  "Nanwalek Airport",
  "Kasenga Airport",
  "Kaédi Airport",
  "Kelle Airport",
  "Keflavík International Airport",
  "Keglsugl Airport",
  "Kenmore Air Harbor (FAA: S60)",
  "Kepi Airport",
  "Kemerovo International Airport",
  "Ekwok Airport",
  "Kiel Airport",
  "Kemi-Tornio Airport",
  "Kenema Airport",
  "Odienné Airport",
  "Nepalgunj Airport",
  "Kebar Airport",
  "Kerman Airport",
  "Kelsey Airport",
  "Kengtung Airport",
  "Keekorok Airport",
  "Halli Airport",
  "Keewaywin Airport (TC LID: CPV8)",
  "Kanabea Airport",
  "Kericho Airport",
  "Kelani River-Peliyagoda Waterdrome",
  "Kiffa Airport",
  "Fortescue Dave Forrest Airport",
  "Kalkgurung Airport",
  "Kirby Lake Airport",
  "False Pass Airport",
  "Kastamonu Airport",
  "Kananga Airport",
  "Konge Airport",
  "Kingscote Airport",
  "Khrabrovo Airport",
  "Kaghau Airport",
  "Sary-Arka Airport",
  "Kédougou Airport",
  "Yongai Airport",
  "Kalgoorlie-Boulder Airport",
  "Karonga Airport",
  "Koliganek Airport (FAA: JZZ)",
  "Kigali International Airport (Kanombe Airport)",
  "Kungim Airport",
  "Kasongo Lunda Airport",
  "Kirovohrad Airport",
  "Kogalym International Airport",
  "Kulgera Airport",
  "Kos Island International Airport",
  "Kangding Airport",
  "Keningau Airport",
  "Kagi Airport",
  "Grayling Airport",
  "Kingaroy Airport",
  "Glacier Creek Airport",
  "Khaneh Airport (Piranshahr Airport)",
  "Kerch Airport (Voykovo Airport)",
  "Khorramabad Airport",
  "Kherson International Airport",
  "Kashgar Airport (Kashi Airport)",
  "Kaohsiung International Airport",
  "Jinnah International Airport",
  "Kauhajoki Airfield",
  "Kharg Airport",
  "Khamti Airport",
  "Nanchang Changbei International Airport",
  "Khoka Moya Airport",
  "Kharkhorin Airport",
  "Khasab Airport",
  "Khost Airfield",
  "Kremenchuk Airport",
  "Khabarovsk Novy Airport",
  "Khwai River Airport",
  "Kihihi Airstrip",
  "Khoy Airport",
  "Kauehi Aerodrome",
  "Kaiapit Airport",
  "Ivanof Bay Seaplane Base",
  "Mesa Del Rey Airport",
  "Kristianstad Airport",
  "Aropa Airport",
  "Kingfisher Lake Airport",
  "Koingnaas Airport",
  "Kish International Airport",
  "Kibuli Airport",
  "Niigata Airport",
  "Kirkuk Airport",
  "Kilwa Airport",
  "Kimberley Airport",
  "Norman Manley International Airport",
  "Kili Airport (FAA: Q51)",
  "Kickapoo Downtown Airport (FAA: CWC)",
  "Kira Airport",
  "Kerry Airport (Farranfore Airport)",
  "Kisumu International Airport",
  "Kithira Island National Airport",
  "Kiunga Airport",
  "Chișinău International Airport",
  "Southdowns Airport",
  "Kansai International Airport",
  "Kilwa Masoko Airport",
  "Kikinonda Airport",
  "Yemelyanovo International Airport",
  "Kaili Huangping Airport",
  "Kanas Airport",
  "Kortrijk–Wevelgem International Airport",
  "Kerama Airport",
  "Kertajati International Airport",
  "Kamiraba Airport",
  "Blangpidie Airport",
  "Koyuk Alfred Adams Airport",
  "Kitoi Bay Seaplane Base",
  "Khon Kaen Airport",
  "Kokoda Airport",
  "Kerikeri Airport (Bay of Islands Airport)",
  "Konawaruk Airport",
  "Kongiganak Airport (FAA: DUY)",
  "Akiachak Airport (FAA: Z13)",
  "Kitakyushu Airport",
  "Kalakaket Creek Air Station (FAA: 1KC)",
  "Karluk Lake Seaplane Base",
  "Khok Kathiam Air Force Base",
  "Kirkenes Airport, Høybuktmoen",
  "Kaikohe Aerodrome",
  "Koolburra Airport",
  "Krasnoselkup Airport",
  "Kaukura Airport",
  "Kashan Airport",
  "Kentland Municipal Airport (FAA: 50I)",
  "Ekuk Airport",
  "Kikwit Airport",
  "Kikai Airport (Kikaiga Shima Airport)",
  "Kilkenny Airport",
  "Koh Kong Airport",
  "Kalabo Airport",
  "Kaolack Airport",
  "Migalovo Air Base",
  "Kaélé Airport",
  "Grabtsevo Airport",
  "Kalskag Airport",
  "Kolhapur Airport",
  "Kotakoli Air Base",
  "Kalokol Airport (Fergusons Gulf Airport)",
  "Levelock Airport (FAA: 9Z8)",
  "Kalaleh Airport",
  "Larsen Bay Airport (FAA: 2A3)",
  "Kalibo International Airport",
  "Keluang Airport",
  "Kalmar Airport",
  "Southwest Washington Regional Airport",
  "Klagenfurt Airport",
  "Karlovy Vary Airport",
  "Klawock Airport (FAA: AKW)",
  "Kalamata International Airport",
  "Kamisuku Airport",
  "Kleinzee Airport",
  "Kerema Airport",
  "Koinambe Airport",
  "King Khaled Military City Airport",
  "Kamembe Airport",
  "Kamina Airport",
  "Kunming Changshui International Airport",
  "Johan Pienaar Airport",
  "Miyazaki Airport",
  "Kumamoto Airport",
  "Makabana Airport",
  "Kamileroi Airport",
  "Kimam Airport",
  "Kamina Airport (Kamina Ville Airport)",
  "Manokotak Airport (FAA: MBA)",
  "Keetmanshoop Airport",
  "Komatsu Airport (Kanazawa Airport)",
  "Karimui Airport",
  "Kumasi Airport",
  "Kampot Airport",
  "Kismayo Airport (Kisimayu Airport)",
  "Kalaymyo Airport (Kalemyo Airport)",
  "Kostroma Airport",
  "King Khalid Air Base",
  "Moser Bay Seaplane Base",
  "Kaoma Airport",
  "Viña del Mar Airport",
  "Kanab Municipal Airport",
  "Kindu Airport",
  "Kanainj Airport",
  "RAF Marham",
  "Kaimana Airport",
  "Kinmen Airport (Shang Yi Airport)",
  "Katanning Airport",
  "Kindamba Airport",
  "Kokhanok Airport (FAA: 9K2)",
  "Kelanoa Airport",
  "Kaniama Airport",
  "Kankan Airport (Diankana Airport)",
  "Kualanamu International Airport",
  "Kapanda Airport",
  "Koné Airport",
  "Jam Airport",
  "King Island Airport",
  "Kennett Memorial Airport (FAA: TKX)",
  "Kanpur Airport (Chakeri Air Force Station)",
  "New Stuyahok Airport",
  "East Kimberley Regional Airport",
  "Kéniéba Airport",
  "Kona International Airport at Keāhole",
  "Koumac Airport",
  "Kotabangun Airport",
  "El Tari Airport",
  "Komatipoort Airport",
  "Khong Island Airport",
  "Koolatah Airport",
  "Kirkwall Airport",
  "Kagoshima Airport",
  "Kokkola-Pietarsaari Airport",
  "Koumala Airport",
  "Komo-Manda Airport",
  "Kontum Airport",
  "Kongolo Airport",
  "Nakhon Phanom Airport",
  "Köthen Airport",
  "Kakoro Airport",
  "Sihanoukville International Airport (Kaong Kang Airport)",
  "Kotlik Airport (FAA: 2A9)",
  "Koulamoutou Airport",
  "Kokshetau Airport",
  "Ganzhou Huangjin Airport",
  "Kokonao Airport",
  "Olga Bay Seaplane Base",
  "Ouzinkie Airport (FAA: 4K5)",
  "Kopiago Airport",
  "Point Baker Seaplane Base",
  "Port Clarence Coast Guard Station",
  "Yapsiei Airport",
  "Kondubol Airport",
  "Kurupung Airport",
  "Kapit Airport",
  "Kapal Airport",
  "Kompiam Airport",
  "Kipnuk Airport (FAA: IIK)",
  "Pohang Airport",
  "Kalpowar Airport",
  "Port Williams Seaplane Base",
  "Kempsey Airport",
  "Jackpot Airport (Hayden Field) (FAA: 06U)",
  "Perryville Airport (FAA: PEV)",
  "Keperveyem Airport",
  "Port Bailey Seaplane Base",
  "Akutan Airport",
  "Ajmer Kishangarh Airport",
  "Kol Airport",
  "Karara Airport",
  "Qurghonteppa International Airport",
  "Kerang Airport",
  "Karumba Airport",
  "Depati Parbo Airport",
  "Kurundi Airport",
  "Kirundo Airport",
  "Höga Kusten Airport (Kramfors-Sollefteå Airport)",
  "Karasabai Airport",
  "Kikori Airport",
  "Karawari Airport",
  "John Paul II International Airport Kraków–Balice",
  "Korla Airport",
  "Karanambo Airport",
  "Kiruna Airport",
  "Kurgan Airport",
  "Karup Airport",
  "Kramatorsk Airport",
  "Krasnodar International Airport (Pashkovsky Airport)",
  "Kristiansand Airport, Kjevik",
  "Khartoum International Airport",
  "Kerau Airport",
  "Kimwarer Airport (Kerio Valley Airport)",
  "Turkmenbashi International Airport",
  "Karkar Airport",
  "Karamay Airport",
  "Basango Mboliasa Airport",
  "Kosrae International Airport (FAA: TTK)",
  "Kasanombe Airport",
  "Košice International Airport",
  "Karlstad Airport",
  "Kasese Airport",
  "Kassel Airport",
  "Kisengam Airport",
  "Shahid Ashrafi Esfahani Airport (Kermanshah Airport)",
  "Kissidougou Airport",
  "Kasos Island Public Airport",
  "Karlskoga Airport",
  "Kassala Airport",
  "St. Mary's Airport",
  "Kostanay Airport",
  "Kastoria National Airport (Aristotelis Airport)",
  "Kosipe Airport",
  "Karshi Airport",
  "H. Aroeppala Airport",
  "Sikasso Airport",
  "Rabak Airport",
  "Kristiansund Airport, Kvernberget",
  "Springvale Airport",
  "Kiryat Shmona Airport",
  "Yasuru Airport",
  "Kars Harakani Airport",
  "Kotlas Airport",
  "Karratha Airport",
  "Thorne Bay Seaplane Base",
  "Katiola Airport",
  "Kitadaito Airport",
  "Kerteh Airport",
  "Takaka Aerodrome",
  "Rahadi Osman Airport (Ketapang Airport)",
  "Tikchik Lodge Seaplane Base (FAA: AK56)",
  "Kratié Airport",
  "Kichwa Tembo Airport",
  "Kunua Airport",
  "Kitale Airport",
  "Tribhuvan International Airport",
  "Ketchikan International Airport",
  "Kato Airport",
  "Tinson Pen Aerodrome",
  "Kitee Airfield",
  "RAAF Base Tindal",
  "Brevig Mission Airport",
  "Kittilä Airport",
  "Kota Airport",
  "Kamarata Airport",
  "Katowice International Airport",
  "Koutiala Airport",
  "Katukurunda Airport",
  "Sultan Haji Ahmad Shah Airport (RMAF Kuantan)",
  "Kuria Airport",
  "Kudat Airport",
  "Kukundu Airport",
  "Kurumoch International Airport",
  "Kubin Airport",
  "Kushiro Airport",
  "Kasigluk Airport (FAA: Z09)",
  "Kuala Lumpur International Airport",
  "Yakushima Airport",
  "Kaunas International Airport",
  "Kuopio Airport",
  "Kupiano Airport",
  "Kuri Airport",
  "Razer Airport",
  "Kulusuk Airport",
  "David the Builder Kutaisi International Airport",
  "Bhuntar Airport (Kullu Manali Airport)",
  "Gunsan Airport",
  "Kuyol Airport",
  "Kamusi Airport",
  "Kavala International Airport (Alexander the Great Airport)",
  "Skövde Airport",
  "King Cove Airport",
  "Ganja International Airport",
  "Kitava Island Airport",
  "Kavieng Airport",
  "Kirovsk-Apatity Airport (Khibiny Airport)",
  "Kivalina Airport",
  "Markovo Airport",
  "Morava Airport",
  "Kavalerovo Airport",
  "Korolevu Seaplane Base",
  "Pobedilovo Airport",
  "Bucholz Army Airfield",
  "Dewadaru Airport",
  "Kavadja Airport",
  "Guiyang Longdongbao International Airport",
  "Waterfall Seaplane Base",
  "Kryvyi Rih International Airport",
  "Khwahan Airport",
  "Kuwait International Airport",
  "Gwangju Airport",
  "Kwigillingok Airport (FAA: GGV)",
  "Guilin Liangjiang International Airport",
  "Kowanyama Airport",
  "Quinhagak Airport (FAA: AQH)",
  "Kawito Airport",
  "West Point Village Seaplane Base",
  "Kwai Harbour Airport",
  "Kwailabesi Airport",
  "Kwethluk Airport",
  "Kurwina Airport",
  "Kiwai Island Airport",
  "Kiwayu Airport",
  "Kolwezi Airport",
  "Kasaan Seaplane Base",
  "Kondinskoye Airport",
  "Klerksdorp Airport (P.C. Pelser Airport)",
  "Koro Airport",
  "Komsomolsk-on-Amur Airport",
  "Karoola Airport",
  "Katiu Airport",
  "Konya Airport",
  "Lanyu Airport",
  "Rene Mouawad Air Base (Kleyate Airport)",
  "Yeelirrie Airport",
  "Yalata Airport",
  "Karluk Airport",
  "Tampa North Aero Park (FAA: X39)",
  "Kyaukpyu Airport",
  "Kayes Airport (Dag-Dag Airport)",
  "Kyauktu Airport",
  "Koyukuk Airport",
  "Yalumet Airport",
  "Kyzyl Airport",
  "Zachar Bay Seaplane Base",
  "Kampong Chhnang Airport",
  "Krakor Airport",
  "Kaintiba Airport",
  "Kitzingen Airport",
  "Kozani National Airport (Filippos Airport)",
  "Kazan International Airport",
  "Kyzylorda Airport",
  "Zafer Airport",
  "Kastellorizo Island Public Airport",
  "Lamar Municipal Airport",
  "Lab Lab Airport",
  "Layang-Layang Airport (Swallow Reef Airport)",
  "Quatro de Fevereiro Airport",
  "Lae Nadzab Airport",
  "Purdue University Airport",
  "Oesman Sadik Airport",
  "Lannion – Côte de Granit Airport",
  "Antônio Correia Pinto de Macedo Airport",
  "Aklavik/Freddie Carmichael Airport",
  "Lakeland Linder International Airport",
  "Los Alamos County Airport",
  "Capital Region International Airport",
  "Laoag International Airport",
  "Manuel Márquez de León International Airport",
  "Al Abraq International Airport",
  "Laramie Regional Airport",
  "McCarran International Airport",
  "Manda Airport",
  "Lawton–Fort Sill Regional Airport",
  "Los Angeles International Airport",
  "Ladysmith Airport",
  "Bom Jesus da Lapa Airport",
  "Leeds Bradford Airport",
  "Lubbock Preston Smith International Airport",
  "Lübeck Airport",
  "Khujand Airport",
  "Arnold Palmer Regional Airport",
  "North Platte Regional Airport (Lee Bird Field)",
  "Paris–Le Bourget Airport",
  "Palm Beach Water Airport",
  "Le Sequestre Airport",
  "Komodo Airport",
  "Liboi Airport",
  "Liberal Mid-America Regional Airport",
  "Luabo Airport",
  "Lake Baringo Airport",
  "Lusambo Airport",
  "Long Banga Airport",
  "Lambaréné Airport",
  "Lábrea Airport",
  "Labasa Airport",
  "Lumberton Municipal Airport",
  "Labuan Airport",
  "Libreville International Airport",
  "Juvai Semaring Airport (Long Bawan Airport)",
  "Lubang Airport",
  "La Baule-Escoublac Airport",
  "Lucapa Airport",
  "Larnaca International Airport",
  "Pontes e Lacerda Airport",
  "Galatina Air Base",
  "Louis Trichardt Airport",
  "Golosón International Airport",
  "Río Dulce Airport (Las Vegas Airport)",
  "A Coruña Airport",
  "Lake Charles Regional Airport",
  "Laconia Municipal Airport",
  "Łódź Władysław Reymont Airport",
  "Rickenbacker International Airport",
  "La Coloma Airport",
  "La Cumbre Airport",
  "Balcanoona Airport",
  "Lague Airport",
  "Loncopué Airport",
  "Lake City Gateway Airport",
  "La Chorrera Airport",
  "Shijiazhuang Luancheng Airport",
  "Lucca-Tassignano Airport",
  "Longyan Guanzhishan Airport",
  "London City Airport",
  "Malda Airport",
  "Londrina–Governador José Richa Airport",
  "Lindeman Island Airport",
  "Tarbes–Lourdes–Pyrénées Airport",
  "Leshukonskoye Airport",
  "Lord Howe Island Airport",
  "Lindi Airport (Kikwetu Airport)",
  "Linden Airport",
  "Lidköping-Hovby Airport",
  "Mason County Airport",
  "Lamidanda Airport",
  "Laduani Airstrip",
  "Lawdar Airport",
  "Yichun Lindu Airport",
  "Lahad Datu Airport",
  "Landivisiau Air Base",
  "Lansdowne Airport",
  "Saint-Laurent-du-Maroni Airport",
  "City of Derry Airport",
  "Londolozi Airport",
  "Learmonth Airport",
  "Lebanon Municipal Airport",
  "Coronel Horácio de Mattos Airport",
  "Pulkovo Airport",
  "Leesburg International Airport",
  "Lebakeng Airport",
  "Aleg Airport",
  "Le Havre – Octeville Airport",
  "Almería Airport",
  "Leipzig/Halle Airport",
  "Tata Airport",
  "Lake Evella Airport",
  "Lemmon Municipal Airport",
  "León Airport",
  "Lékoni Airport",
  "Leopoldina Airport",
  "Land's End Airport",
  "Leinster Airport",
  "Lesobeng Airport",
  "Alfredo Vásquez Cobo International Airport",
  "La Seu d'Urgell Airport",
  "Levuka Airfield (Bureta Airport)",
  "Auburn/Lewiston Municipal Airport",
  "Blue Grass Airport",
  "Lelystad Airport",
  "La Esperanza Airport",
  "Lumbo Airport",
  "Langley Air Force Base",
  "Angelina County Airport",
  "Lamerd Airport",
  "Triangle North Executive Airport (Franklin County Airport) (FAA: LHZ)",
  "Kelafo Airport",
  "Lakefield Airport",
  "Linfen Qiaoli Airport",
  "La Fría Airport",
  "Lafayette Regional Airport",
  "Lomé–Tokoin Airport (Gnassingbé Eyadéma Int'l)",
  "LaGuardia Airport",
  "Long Beach Airport",
  "LaGrange Callaway Airport",
  "La Grande/Union County Airport",
  "Lake Gregory Airport",
  "Laguna Army Airfield",
  "Liège Airport",
  "Leigh Creek Airport",
  "Deadman's Cay Airport",
  "Langkawi International Airport",
  "Long Lellang Airport",
  "Laiagam Airport",
  "Linga Linga Airport",
  "Langeoog Airport",
  "Legazpi International Airport",
  "Lago Agrio Airport",
  "Cochrane Airfield",
  "Comodoro D. Ricardo Salomón Airport",
  "Las Gaviotas Airport",
  "Logan-Cache Airport",
  "Gatwick Airport",
  "Lugh Ganane Airport",
  "Lagunillas Airport",
  "Flughafen Lahr (Lahr Airport)",
  "Allama Iqbal International Airport",
  "Lightning Ridge Airport",
  "Lereh Airport",
  "Laohekou Airport",
  "Lehu Airport",
  "Heathrow Airport",
  "Las Heras Airport",
  "Lianshulu Airport",
  "William T. Piper Memorial Airport",
  "Lanzhou Zhongchuan International Airport",
  "Liangping Airport",
  "Limbunya Airport",
  "Limon Municipal Airport",
  "Libenge Airport",
  "Ouanaham Airport",
  "Limoges – Bellegarde Airport",
  "Lihue Airport",
  "Mulia Airport",
  "Likiep Airport",
  "Lille Airport (Lille–Lesquin Airport)",
  "Jorge Chávez International Airport",
  "Linate Airport",
  "Limón International Airport",
  "Lins Airport",
  "Lisala Airport",
  "Daniel Oduber Quirós International Airport",
  "Lisbon Portela Airport",
  "Clinton National Airport (Adams Field)",
  "Livengood Camp Airport (FAA: 4AK)",
  "Loikaw Airport",
  "Likoma Airport",
  "MidCoast Regional Airport at Wright Army Airfield (FAA: LHW)",
  "Loring International Airport (FAA: ME16)",
  "Lodja Airport",
  "Lijiang Sanyi Airport",
  "Texas Gulf Coast Regional Airport (FAA: LBX)",
  "Ljubljana Jože Pučnik Airport (Brnik Airport)",
  "Gewayantana Airport",
  "Lakeba Airport",
  "Lekana Airport",
  "Lakeland Downs Airport",
  "Kenmore Air Harbor Seaplane Base (Seattle Lake Union SPB) (FAA: W55)",
  "Lokichogio Airport",
  "Long Akah Airport",
  "Kulik Lake Airport",
  "Lakselv Airport, Banak",
  "Leknes Airport",
  "Chaudhary Charan Singh International Airport",
  "Lake Placid Airport",
  "Las Khorey Airport",
  "Lake Turkana Airport",
  "Lake County Airport",
  "Lekhwair Airport",
  "Lake Manyara Airport",
  "RAF Lakenheath",
  "Luleå Airport (Kallax Airport)",
  "Libo Airport (Qiannan Airport)",
  "Cagayan North International Airport (Lal-lo Airport)",
  "Malelane Airport",
  "Yongzhou Lingling Airport",
  "Chillagoe Airport",
  "Las Limas Airport",
  "Lalibela Airport",
  "Silampari Airport",
  "Lankaran International Airport",
  "Lissadell Airport",
  "Kelila Airport",
  "Palopo Lagaligo Airport",
  "Linda Downs Airport",
  "Alférez Armando Rodríguez Airport",
  "Lobito Airport",
  "Lüliang Airport",
  "Lilongwe International Airport (Kamuzu Int'l)",
  "Caledonia County Airport (FAA: CDA)",
  "South Jersey Regional Airport (FAA: VAY)",
  "Lake Minchumina Airport (FAA: MHM)",
  "Salima Airport",
  "La Macarena Airport",
  "Los Menucos Airport",
  "Le Mans Arnage Airport",
  "Lamassa Airport",
  "Limón Airport",
  "Lumi Airport",
  "Lae Airport",
  "Fort Valley Federal International Airport",
  "Limbang Airport",
  "RAF Lossiemouth",
  "Lampedusa Airport",
  "Marsa Brega Airport",
  "Finsch Mine Airport",
  "Louisville Winston County Airport",
  "Klamath Falls Airport",
  "Letung Airport",
  "Lopéz de Micay Airport",
  "Lake Murray Airport",
  "Palma Airport",
  "Palm Beach County Park Airport",
  "Lamen Bay Airport",
  "Lengbati Airport",
  "Hunt Field",
  "Lonorore Airport",
  "Munbil Airport",
  "Lese Airport",
  "Lake Nash Airport",
  "Point Lonely Short Range Radar Site (FAA: AK71)",
  "Lincang Airport",
  "Lincoln Airport",
  "Longnan Chengzhou Airport",
  "Langimar Airport",
  "Lost Nation Airport",
  "Leonora Airport",
  "Lonesome Pine Airport",
  "Loani Airport",
  "Tri-County Regional Airport",
  "Lancaster Airport",
  "Robert Atty Bessing Airport",
  "Lihir Island Airport",
  "Smolensk South Airport",
  "Lanai Airport",
  "Linz Airport (Blue Danube Airport)",
  "Lorraine Airport",
  "San Rafael Airport",
  "Lock Airport",
  "Longana Airport",
  "Loei Airport",
  "Loen Airport",
  "Ciudad de Catamayo Airport",
  "Helmuth Baungartem Airport",
  "Lodwar Airport",
  "Derby Field",
  "Francisco Primo de Verdad National Airport",
  "metropolitan area3",
  "L'Mekrareg Airport (Laghouat Airport)",
  "Lombok International Airport",
  "Lobatse Airport",
  "Murtala Muhammed International Airport",
  "Lewis University Airport",
  "Bowman Field",
  "Venustiano Carranza International Airport",
  "Louisa County Airport (Freeman Field) (FAA: LKU)",
  "Los Tablones Airport",
  "Loiyangalani Airport",
  "London-Corbin Airport (Magee Field)",
  "Gran Canaria Airport",
  "El Alto International Airport",
  "Lompoc Airport",
  "La Pedrera Airport",
  "La Primavera Airport",
  "Liupanshui Yuezhao Airport",
  "La Plata Airport",
  "Linköping/Saab Airport",
  "Armando Schwarck Airport",
  "Lipetsk Airport",
  "Liverpool John Lennon Airport",
  "Malekoula Airport (Lamap Airport)",
  "Leron Plains Airport",
  "La Porte Municipal Airport (FAA: PPO)",
  "Lappeenranta Airport",
  "Luang Prabang International Airport",
  "Fishermans Bay/LPS Seaplane Base",
  "Lampang Airport",
  "Long Apung Airport",
  "Liepāja International Airport",
  "Le Puy – Loudes Airport",
  "Pickens County Airport",
  "Caucayá Airport",
  "Qala i Naw Airport",
  "Larissa National Airport",
  "Leribe Airport",
  "Laredo International Airport",
  "Longreach Airport",
  "Little Rock Air Force Base",
  "Loralai Airport",
  "La Rochelle – Île de Ré Airport",
  "Lorica Airport",
  "Le Mars Municipal Airport",
  "Niamtougou International Airport",
  "La Romana International Airport (Casa de Campo International Airport)",
  "Laurie River Airport",
  "Larestan International Airport",
  "Leros Municipal Airport",
  "Lorient South Brittany Airport (Lann-Bihoué Airport)",
  "Las Cruces International Airport",
  "Los Roques Airport",
  "Losuia Airport",
  "Lordsburg Municipal Airport",
  "La Florida Airport",
  "La Crosse Regional Airport",
  "Lawson Army Airfield",
  "Lashio Airport",
  "Sumburgh Airport",
  "Long Island Airport",
  "Lusk Municipal Airport",
  "Los Chiles Airport",
  "Long Semado Airport",
  "Los Banos Municipal Airport",
  "Les Sables-d'Olonne – Talmont Airport",
  "Josefa Camejo International Airport",
  "Maria Dolores Airport",
  "Les Saintes Airport",
  "Launceston Airport",
  "Long Sukang Airport",
  "Nellis Air Force Base",
  "Malikus Saleh Airport",
  "Lhok Sukon Airport",
  "Lismore Airport",
  "Lošinj Airport",
  "Tzaneen Airport (Letaba Airport)",
  "Latrobe Airport",
  "Laï Airport",
  "Ghadames Airport",
  "Leitre Airport",
  "Langtang Airport",
  "Altai Airport",
  "Bassel Al-Assad International Airport",
  "Lastourville Airport",
  "Lethem Airport",
  "Luton Airport",
  "Loreto International Airport",
  "Lyndhurst Airport",
  "Le Touquet – Côte d'Opale Airport",
  "Letterkenny Airfield",
  "Altus Air Force Base",
  "La Môle – Saint-Tropez Airport",
  "Latur Airport",
  "Lotus Vale Station Airport",
  "St. Mary's County Regional Airport (FAA: 2W6)",
  "(Captain Walter Francis Duke Regional Airport)",
  "Cotopaxi International Airport",
  "Tenzing-Hillary Airport",
  "Lumid Pau Airport",
  "Laucala Airport",
  "Lüderitz Airport",
  "Boľkovce Airport",
  "Luke Air Force Base",
  "Lugano Airport",
  "Sahnewal Airport (Ludhiana Airport)",
  "La Unión Airport",
  "Cincinnati Municipal Airport (Lunken Field)",
  "Hesler-Noble Field",
  "Dehong Mangshi Airport",
  "Kenneth Kaunda International Airport",
  "Luena Airport",
  "Kalaupapa Airport",
  "Brigadier Mayor César Raúl Ojeda Airport",
  "Cape Lisburne LRRS Airport",
  "Lusanga Airport",
  "New Laura Airport",
  "Laura Airport",
  "Karel Sadsuitubun Airport",
  "Syukuran Aminuddin Amir Airport",
  "Luxembourg Findel Airport",
  "Lublin Airport",
  "Laval Entrammes Airport",
  "Galpões Airport",
  "Lime Village Airport (FAA: 2AK)",
  "Harry Mwanga Nkumbula International Airport",
  "Livermore Municipal Airport",
  "Lawrenceville/Brunswick Municipal Airport",
  "Mission Field",
  "Laverton Airport",
  "Lavan Airport",
  "Bom Futuro Municipal Airport",
  "Las Vegas Municipal Airport",
  "Lebak Airport",
  "Greenbrier Valley Airport",
  "Lawrence Municipal Airport",
  "Wonopito Airport",
  "Lawn Hill Airport",
  "Lowai Airport",
  "Tingwall Airport (Lerwick/Tingwall Airport)",
  "Wells Municipal Airport (Harriet Field)",
  "Lawrence Municipal Airport",
  "Shirak Airport",
  "Lviv Danylo Halytskyi International Airport",
  "Leeuwarden Air Base",
  "Lewiston–Nez Perce County Airport",
  "Lewistown Municipal Airport",
  "Lawrenceville–Vincennes International Airport",
  "Lawas Airport",
  "Lhasa Gonggar Airport",
  "Louang Namtha Airport",
  "Jim Kelly Field",
  "Luxor International Airport",
  "Lemnos International Airport",
  "Lukulu Airport",
  "Lake County Airport",
  "Luoyang Beijiao Airport",
  "Edward Bodden Airfield",
  "Lycksele Airport",
  "RAF Lyneham",
  "Lianyungang Baitabu Airport",
  "Lynchburg Regional Airport (Preston Glenn Field)",
  "Linyi Shubuling Airport",
  "Lunyuk Airport",
  "Lyon–Bron Airport",
  "Lyons–Rice County Municipal Airport",
  "Faisalabad International Airport",
  "Svalbard Airport, Longyear",
  "Lyon–Saint-Exupéry Airport",
  "Lady Elliot Island Airport",
  "Ely Municipal Airport (FAA: ELO)",
  "Lydd Airport (London Ashford Airport)",
  "Luiza Airport",
  "Lázaro Cárdenas Airport",
  "Liuzhou Bailian Airport",
  "Luozi Airport",
  "Cuango-Luzamba Airport",
  "Matsu Nangan Airport",
  "Luzhou Yunlong Airport",
  "Lizard Island Airport",
  "Gwinnett County Airport (Briscoe Field)",
  "Nyingchi Mainling Airport",
  "Chennai International Airport",
  "João Correa da Rocha Airport",
  "Macon Downtown Airport",
  "Adolfo Suárez Madrid–Barajas Airport",
  "Madera Municipal Airport",
  "Midland International Air and Space Port",
  "Madang Airport",
  "Menorca Airport (Mahón Airport)",
  "Mangochi Airport",
  "Marshall Islands International Airport (Amata Kabua Int'l)",
  "Malakal Airport",
  "Mangole Airport",
  "General Servando Canales International Airport",
  "Manchester Airport",
  "Eduardo Gomes International Airport",
  "Mamai Airport",
  "Mae Sot Airport",
  "La Chinita International Airport",
  "Momote Airport",
  "Matadi Tshimpi Airport",
  "Maupiti Airport",
  "Maloelap Airport (FAA: 3N1)",
  "Malden Regional Airport",
  "Ouro Sogui Airport",
  "Clarence A. Bain Airport",
  "Eugenio María de Hostos Airport",
  "Moi International Airport",
  "Marble Bar Airport",
  "Mbigou Airport",
  "Mahikeng Airport (Mmabatho Airport)",
  "Monbetsu Airport",
  "Porepunkah Airfield",
  "Mobridge Municipal Airport",
  "Maryborough Airport",
  "Mbeya Airport",
  "Sangster International Airport",
  "Orlando Villas-Bôas Regional Airport",
  "Manistee County Blacker Airport",
  "Mkambati Airport",
  "Mount Barnett Airport",
  "Mamburao Airport",
  "Moyobamba Airport",
  "Mbarara Airport",
  "M'Bout Airport",
  "MBS International Airport",
  "Moises R. Espinosa Airport (Masbate Airport)",
  "Mbambanakira Airport",
  "Masa Airport",
  "Moorabbin Airport",
  "Maribor Edvard Rusjan Airport",
  "Omar N. Bradley Airport",
  "Maués Airport",
  "Macenta Airport",
  "McComb–Pike County Airport (John E. Lewis Field)",
  "McClellan Airfield",
  "Mackinac Island Airport",
  "Merced Regional Airport (MacReady Field)",
  "MacDill Air Force Base",
  "McGrath Airport",
  "General Manuel Serrano Airport",
  "Kansas City International Airport",
  "Jorge Isaacs Airport (La Mina Airport)",
  "McCook Ben Nelson Regional Airport",
  "McKinley National Park Airport (FAA: INR)",
  "Middle Georgia Regional Airport",
  "Orlando International Airport",
  "Alberto Alcolumbre International Airport",
  "Miskolc Airport",
  "Monte Caseros Airport",
  "Muscat International Airport",
  "Montluçon – Guéret Airport",
  "McArthur River Mine Airport",
  "Mason City Municipal Airport",
  "Uytash Airport",
  "Sunshine Coast Airport",
  "Zumbi dos Palmares International Airport",
  "Melinda Airport",
  "Sam Ratulangi International Airport",
  "Midland Airpark",
  "José María Córdova International Airport",
  "Taylor County Airport",
  "Mudanjiang Hailang Airport",
  "Southern Illinois Airport",
  "Makurdi Airport",
  "Madras Municipal Airport (FAA: S33)",
  "Mbandaka Airport",
  "Mandalay International Airport",
  "Munduku Airport",
  "Madison Municipal Airport",
  "Middleton Island Airport",
  "Mindiptana Airport",
  "Astor Piazzolla International Airport",
  "Middle Caicos Airport",
  "Harrisburg International Airport",
  "Mendi Airport",
  "Médouneu Airport",
  "Midway International Airport",
  "Mercedes Airport",
  "Henderson Field",
  "Gov. Francisco Gabrielli International Airport (El Plumerillo)",
  "Benedito Lacerda Airport",
  "Essendon Airport",
  "Eloy Alfaro International Airport",
  "Prince Mohammad bin Abdulaziz Airport",
  "Maré Airport",
  "Melfi Airport",
  "Malanje Airport",
  "Mehamn Airport",
  "Meridian Regional Airport (Key Field)",
  "Port Meadville Airport (FAA: GKJ)",
  "Bassatine Air Base",
  "Melbourne Airport",
  "Memphis International Airport",
  "Brenoux Airport",
  "Dare County Regional Airport (FAA: MQI)",
  "Mersing Airport",
  "Cut Nyak Dhien Airport",
  "Castle Airport",
  "Soewondo Air Force Base",
  "Moreton Airport",
  "Serra do Areão Airport",
  "Minden–Tahoe Airport",
  "Mweka Airport",
  "Mexico City International Airport",
  "Meghauli Airport",
  "Messina Airport",
  "Mafia Airport",
  "Monfort Airport",
  "Mafeteng Airport",
  "Mansfield Lahm Regional Airport",
  "McAllen Miller International Airport",
  "Moanda Airport",
  "Muzaffarabad Airport",
  "Mesquite Airport (FAA: 67L)",
  "Marshfield Municipal Airport",
  "Moala Airport",
  "Matsu Beigan Airport",
  "Mount Full Stop Airport",
  "Macau International Airport",
  "Milford Sound Airport",
  "Manguna Airport",
  "Manners Creek Airport",
  "Maradi Airport",
  "Rogue Valley International–Medford Airport",
  "Miraflores Airport",
  "Mfuwe Airport",
  "Accomack County Airport",
  "Magaruque Airport",
  "Méribel Altiport",
  "Mayfa'ah Airport",
  "Meselia Airport",
  "Augusto C. Sandino International Airport",
  "Mount Gambier Airport",
  "Michigan City Municipal Airport",
  "Magdalena Airport",
  "Dobbins Air Reserve Base",
  "Sílvio Name Júnior Regional Airport",
  "Margarima Airport",
  "Margate Airport",
  "Aransas National Wildlife Refuge Airport (FAA: XS10)",
  "Orange County Airport",
  "Mong Ton Airport",
  "Düsseldorf Mönchengladbach Airport",
  "Montgomery Regional Airport (Dannelly Field)",
  "Baracoa Regional Airport",
  "Manega Airport",
  "Manga Airport",
  "Aden Adde International Airport",
  "Moultrie Municipal Airport",
  "Mangaia Airport",
  "Milingimbi Airport",
  "Manaung Airport",
  "Margaret River Station Airport",
  "Morgantown Municipal Airport (Walter L. Bill Hart Field)",
  "Moabi Airport",
  "Dayton–Wright Brothers Airport",
  "Myeik Airport",
  "Mahdia Airport",
  "Mocopulli Airport",
  "Mashhad International Airport (Shahid Hashemi Nejad Airport)",
  "Mitchell Municipal Airport",
  "Morichal Airport",
  "Mannheim City Airport",
  "Marsh Harbour Airport",
  "Moucha Airport",
  "Manhattan Regional Airport",
  "Marshall Memorial Municipal Airport",
  "Hooker County Airport",
  "Mount House Airport",
  "Minsk-1 Airport",
  "Mariehamn Airport",
  "Sacramento Mather Airport",
  "Dunsmuir Municipal-Mott Airport (FAA: 1O6)",
  "Manchester–Boston Regional Airport",
  "Mount Hotham Airport",
  "Mojave Air and Space Port",
  "Monteagudo Airport",
  "Manihiki Island Airport",
  "Morehead Airport",
  "RAF Mildenhall",
  "Miami International Airport",
  "Minot Air Force Base",
  "Crystal Airport",
  "Manuel Crescencio Rejón International Airport",
  "Delaware County Regional Airport",
  "Roy Hurd Memorial Airport (FAA: E01)",
  "Mianyang Nanjiao Airport",
  "Mitchell Plateau Airport",
  "Frank Miloye Milenkowichi–Marília State Airport",
  "Mili Airport (FAA: 1Q9)",
  "Mikkeli Airport",
  "metropolitan area1",
  "Merimbula Airport",
  "Minnipa Airport",
  "Miami Municipal Airport",
  "Mitzpe Ramon Airport",
  "Millard Airport (FAA: MLE)",
  "Monastir Habib Bourguiba International Airport",
  "Misima Island Airport",
  "Shafter Airport (Minter Field)",
  "Maiduguri International Airport",
  "Millville Municipal Airport",
  "Marshalltown Municipal Airport",
  "Mirití-Paraná Airport",
  "Mittiebah Airport",
  "Mainoru Airport",
  "Manja Airport",
  "Mejit Airport (FAA: Q30)",
  "Man Airport",
  "Moenjodaro Airport",
  "Majkin Airport",
  "Mosjøen Airport, Kjærstad",
  "Mitiga International Airport",
  "Moki Airport",
  "Shark Bay Airport",
  "Mouila Airport",
  "Mbuji Mayi Airport",
  "Amborovy Airport (Philibert Tsiranana Airport)",
  "Mount Etjo Airport",
  "Manjimup Airport",
  "Jackson Municipal Airport",
  "Miramar Airport",
  "Maganja da Costa Airport",
  "Mytilene International Airport",
  "Tampa Padang Airport",
  "Murcia–San Javier Airport",
  "Mahenye Airport",
  "Robert J. Miller Air Park (Ocean County Airport)",
  "Mirny Airport",
  "Mariánské Lázně Airport",
  "Mékambo Airport",
  "Charles B. Wheeler Downtown Airport",
  "Milwaukee Mitchell International Airport",
  "Muskegon County Airport",
  "Mokhotlong Airport",
  "M'Boki Airport",
  "Makoua Airport",
  "Molokai Airport",
  "McKellar–Sipes Regional Airport",
  "Mukah Airport",
  "Malekolon Airport",
  "Davis Field",
  "Makemo Airport",
  "Mopah Airport",
  "Meekatharra Airport",
  "Mekane Selam Airport",
  "Mankato Regional Airport",
  "Makokou Airport",
  "Mount Cavenagh Airport",
  "Rendani Airport",
  "Mackay Airport",
  "Malacca International Airport",
  "Malta International Airport (Luqa Airport)",
  "Orlando Melbourne International Airport",
  "McAlester Regional Airport",
  "Malad City Airport",
  "Ibrahim Nasir International Airport",
  "Milford Municipal Airport (Ben and Judy Briscoe Field)",
  "Abdul Rachman Saleh Airport",
  "EuroAirport Basel Mulhouse Freiburg",
  "Quad City International Airport",
  "Baldwin County Airport",
  "Malta Airport (FAA: M75)",
  "Marshall Don Hunter Sr. Airport (FAA: MDM)",
  "General Francisco J. Mujica International Airport",
  "Melilla Airport",
  "Milos Island National Airport",
  "Malabang Airport",
  "Malalaua Airport",
  "Millicent Airport",
  "Miles City Airport (Frank Wiley Field)",
  "Millinocket Municipal Airport",
  "Monroe Regional Airport",
  "Merluna Airport",
  "Spriggs Payne Airport",
  "Malatya Erhaç Airport",
  "Manley Hot Springs Airport",
  "Cerro Largo International Airport",
  "metropolitan area2",
  "Memanbetsu Airport",
  "Ciudad Mante National Airport",
  "Minami-Daito Airport",
  "Teesside International Airport",
  "Mamfe Airport",
  "Mount Magnet Airport",
  "Mammoth Yosemite Airport",
  "McMinn County Airport",
  "Matsumoto Airport",
  "Murmansk Airport",
  "Southwest Minnesota Regional Airport (Marshall/Ryan Field)",
  "Middlemount Airport",
  "Minute Man Air Field (FAA: 6B6)",
  "Maio Airport",
  "San Bernardo Airport",
  "Mbala Airport",
  "Selfs Airport",
  "McEntire Joint National Guard Base",
  "Morristown Municipal Airport",
  "Mal Island Airport",
  "Moma Airport",
  "Malmö Airport",
  "Miyako Airport",
  "Maymana Airport",
  "Melangguane Airport",
  "Muanda Airport (Moanda Airport)",
  "Nacala Airport",
  "Medina Airport",
  "Mungeranie Airport",
  "Mana Island Airport",
  "Maningrida Airport",
  "Al-Musannah Airport",
  "John A. Osborne Airport",
  "Mananjary Airport",
  "Maiana Airport",
  "Ninoy Aquino International Airport / Villamor Air Base",
  "Menominee-Marinette Twin County Airport",
  "Marion Municipal Airport",
  "Manono Airport",
  "Maron Island Airport",
  "Monto Airport",
  "Mongu Airport",
  "Mansa Airport",
  "Minto Al Wright Airport (FAA: 51Z)",
  "Mawlamyaing Airport",
  "Mount Valley Airport",
  "MacDonald Downs Airport",
  "Manicoré Airport",
  "Mono Airport",
  "Manassas Regional Airport (FAA: HEF)",
  "Orestes Acosta Airport",
  "Mobile Regional Airport",
  "Montes Claros/Mário Ribeiro Airport",
  "Modesto City–County Airport (Harry Sham Field)",
  "Momeik Airport",
  "Frans Seda Airport (Wai Oti Airport)",
  "Monghsat Airport",
  "Maleo Airport",
  "Mitiaro Airport (Nukuroa Airport)",
  "Moengo Airstrip",
  "Molde Airport, Årø",
  "Letfotar Airport",
  "Mount Cook Aerodrome",
  "Moomba Airport",
  "Mount Pleasant Municipal Airport",
  "Morondava Airport",
  "Morristown Regional Airport (Moore–Murrell Airport)",
  "Moses Point Airport",
  "Minot International Airport",
  "Mountain Village Airport",
  "Moranbah Airport",
  "metropolitan area3",
  "Morris Municipal Airport",
  "Monterrey Airport",
  "Moorea Airport (Temae Airport)",
  "Katima Mulilo Airport (Mpacha Airport)",
  "Miami Seaplane Base (FAA: X44)",
  "Mukomuko Airport",
  "Sindhri Airport",
  "Mapoda Airport",
  "Makini Airport",
  "Godofredo P. Ramos Airport (Caticlan Airport)",
  "Mamitupu Airport",
  "Petit Jean Park Airport",
  "Montpellier–Méditerranée Airport (Fréjorgues Airport)",
  "Maputo International Airport",
  "RAF Mount Pleasant",
  "Pocono Mountains Municipal Airport",
  "Mulatupo Airport",
  "Ma'an Airport",
  "McPherson Airport",
  "Mount Pleasant Regional Airport (FAA: OSA)",
  "Maliana Airport",
  "Mabua Airstrip",
  "Edward F. Knapp State Airport",
  "Mariupol International Airport",
  "Miyanmin Airport",
  "Maripasoula Airport",
  "Mount Pleasant Municipal Airport",
  "Mandora Station Airport",
  "Macomb Municipal Airport",
  "Miquelon Airport",
  "Maquinchao Airport",
  "Marqua Airport",
  "Magnitogorsk International Airport",
  "Midgard Airport",
  "Minaçu Airport",
  "Moma Airport",
  "San Matías Airport",
  "Mildura Airport",
  "Mardin Airport",
  "Mo i Rana Airport, Røssvoll",
  "Malam Airport",
  "Kruger Mpumalanga International Airport",
  "Moundou Airport",
  "Mosquera Airport",
  "Mustique Airport",
  "Sawyer International Airport (FAA: SAW)",
  "Mariquita Airport",
  "Mostaganem Airport",
  "Telfair–Wheeler Airport",
  "Alula Aba Nega Airport",
  "Smyrna Airport",
  "Margaret River Airport",
  "Misrata Airport",
  "Eastern WV Regional Airport (Shepherd Field)",
  "Maury County Airport",
  "Alberto Carnevalli Airport",
  "Mara Serena Airport",
  "Marfa Municipal Airport",
  "Mareeba Airfield",
  "May River Airport",
  "Merrill Field",
  "Marcala Airport",
  "Marco Island Airport (FAA: MKY)",
  "Miners Lake Airport",
  "Manari Airport",
  "Foothills Regional Airport (was Morganton-Lenoir Airport)",
  "Hood Aerodrome",
  "Marla Airport",
  "Marinduque Airport",
  "José María Velasco Ibarra Airport",
  "Marseille Provence Airport",
  "Moroak Airport",
  "Sir Seewoosagur Ramgoolam International Airport",
  "Mineralnye Vody Airport",
  "Lolland Falster Airport",
  "Mahshahr Airport",
  "Monterey Regional Airport",
  "Moree Airport",
  "Muskrat Dam Airport",
  "Falcon Field Airport (FAA: FFZ)",
  "Mount Pleasant Airport (FAA: 43U)",
  "Manston Airport (Kent International Airport)",
  "Mount Swan Airport",
  "Matsaile Airport",
  "Masirah Air Base",
  "Masalembo Airport",
  "Misawa Airport",
  "Northwest Alabama Regional Airport",
  "Masi-Manimba Airport",
  "Dane County Regional Airport (Truax Field)",
  "Missoula International Airport",
  "Minneapolis–Saint Paul International Airport (Wold–Chamberlain Field)",
  "Minsk National Airport",
  "Muş Airport",
  "Massena International Airport (Richards Field)",
  "Maastricht Aachen Airport",
  "Moshoeshoe I International Airport",
  "Sullivan County International Airport",
  "Massawa International Airport",
  "Mossendjo Airport",
  "Louis Armstrong New Orleans International Airport",
  "Moçâmedes Airport (Yuri Gagarin Airport)",
  "Matamata Airport",
  "Montelíbano Airport",
  "Selfridge Air National Guard Base",
  "Mount Sandford Station Airport",
  "Monte Alegre Airport",
  "Mizan Teferi Airport",
  "Mato Grosso Airport",
  "Florida Keys Marathon Airport",
  "Mosteiros Airport",
  "Montrose Regional Airport",
  "Makin Airport",
  "Maitland Airport",
  "Metlakatla Seaplane Base",
  "Martin State Airport",
  "Coles County Memorial Airport",
  "Montauk Airport",
  "Mitchell Airport",
  "Los Garzones Airport",
  "Matsapha Airport",
  "Minatitlán/Coatzacoalcos International Airport",
  "Montepuez Airport",
  "Mota Lava Airport",
  "Manitowoc County Airport",
  "Metro Field (FAA: MTF)",
  "General Mariano Escobedo International Airport",
  "Bar Yehuda Airfield (Masada Airfield)",
  "Munda Airport",
  "Maun Airport",
  "Munich Airport",
  "Mueda Airport",
  "Waimea-Kohala Airport",
  "Muting Airport",
  "Mulegé Airstrip",
  "Marsa Matruh International Airport",
  "Muir Army Airfield",
  "Mui Airport",
  "Mauke Airport",
  "Spence Airport",
  "José Tadeo Monagas International Airport",
  "Mountain Home Air Force Base",
  "Mulga Park Airport",
  "Muccan Station Airport",
  "Marudi Airport",
  "Minami Torishima Airport",
  "Muscatine Municipal Airport",
  "Ghriss Airport",
  "Multan International Airport",
  "Mouyondzi Airport",
  "Musoma Airport",
  "Mývatn Airport",
  "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
  "Monroe County Airport",
  "Carrasco Gral. Cesáreo L. Berisso International Airport",
  "Montevideo–Chippewa County Airport",
  "Gov. Dix-Sept Rosado Airport",
  "Mevang Airport",
  "Macksville Airport",
  "Manetai Airport",
  "Mulka Airport",
  "Morrisville–Stowe State Airport",
  "Kayenta Airport (FAA: 0V7)",
  "Mount Vernon Airport",
  "Mongo Airport",
  "Fabio Alberto León Bentley Airport",
  "Mogilev Airport",
  "Salak Airport",
  "Mucuri Airport",
  "Mataiva Airport",
  "Musgrave Airport",
  "Megève Altiport",
  "Skagit Regional Airport (FAA: BVS)",
  "Minvoul Airport",
  "Martha's Vineyard Airport",
  "Masvingo Airport",
  "Williamson County Regional Airport",
  "Morawa Airport",
  "Lawrence J. Timmerman Airport",
  "PAF Base M.M. Alam",
  "Merowe Airport",
  "Maewo-Naone Airport",
  "Marawaka Airport",
  "Grant County International Airport",
  "Maramuni Airport",
  "Matthews Ridge Airport",
  "Matak Airport (Tarempa Airport)",
  "Mineral Wells Airport",
  "Windom Municipal Airport",
  "Mwadui Airport",
  "Middletown Regional Airport (Hook Field)",
  "Magway Airport",
  "Motswari Airstrip",
  "Moolawatana Airport",
  "Mussau Island Airport",
  "Mondulkiri Airport",
  "Muan International Airport",
  "Miranda Downs Airport",
  "Mwanza Airport",
  "Manila Municipal Airport",
  "Andi Jemma Airport",
  "Monticello Airport (FAA: U43)",
  "Marion Downs Airport",
  "Laurinburg–Maxton Airport (FAA: MEB)",
  "Maxwell Air Force Base",
  "Marlboro Airport (FAA: 9B1)",
  "Moro Airport",
  "Mati Airport (Imelda R. Marcos Airport)",
  "Minna Airport",
  "Mindik Airport",
  "General Rodolfo Sánchez Taboada International Airport",
  "Morombe Airport",
  "Morlaix – Ploujean Airport",
  "Monticello Regional Airport",
  "Milan–Malpensa Airport",
  "Myrhorod Air Base",
  "Maota Airport",
  "Maintirano Airport",
  "Mullewa Airport",
  "Mörön Airport",
  "Mandalgovi Airport",
  "Mora–Siljan Airport",
  "McCarthy Airport (FAA: 15Z)",
  "Meixian Airport",
  "Moruya Airport",
  "Mayumba Airport",
  "Mariscal Sucre Airport",
  "Malindi Airport",
  "Miyakejima Airport",
  "Montgomery Field Airport",
  "Mayaguana Airport (Abraham's Bay Airport)",
  "Marble Canyon Airport (FAA: L41)",
  "Murray Island Airport",
  "Matsuyama Airport",
  "May Creek Airport",
  "McCall Municipal Airport",
  "Monkey Mountain Airport",
  "Marib Airport",
  "Myroodah Airport",
  "Mary International Airport",
  "Mysore Airport (Mandakalli Airport)",
  "Myrtle Beach International Airport",
  "Myitkyina Airport",
  "Mekoryuk Airport",
  "Yuba County Airport",
  "Mtwara Airport",
  "Menyamya Airport",
  "Miri Airport",
  "Monkey Bay Airport",
  "Manuel Prado Ugarteche Airport",
  "Mocímboa da Praia Airport",
  "Mitzic Airport",
  "Méndez Airport",
  "Manatee Airport",
  "Wild Coast Sun Airport",
  "Magong Airport",
  "Amasya Merzifon Airport",
  "Mopti Airport (Ambodedjo Airport)",
  "Pinal Airpark",
  "Marakei Airport",
  "La Nubia Airport (Santaguida Airport)",
  "Metz-Frescaty Air Base",
  "Minj Airport",
  "Sierra Maestra Airport",
  "Motueka Aerodrome",
  "Mkuze Airport",
  "Mazar-e Sharif International Airport",
  "General Rafael Buelna International Airport",
  "Muzaffarpur Airport",
  "Mulu Airport",
  "Mécheria Airport",
  "Mena Airport",
  "Mossel Bay Airport",
  "Marion Municipal Airport",
  "Narrabri Airport",
  "Naracoorte Airport",
  "Macanal Airport",
  "Boundétingou Airport",
  "Banaina Airport",
  "Dr. Babasaheb Ambedkar International Airport",
  "Naha Airport",
  "Annai Airport",
  "Nakhchivan International Airport",
  "Nakhon Ratchasima Airport",
  "Nalchik Airport",
  "Namlea Airport",
  "Nadi International Airport",
  "Nanchong Gaoping Airport",
  "Naples International Airport",
  "Qaanaaq Airport",
  "Puerto Nare Airport",
  "Lynden Pindling International Airport",
  "São Gonçalo do Amarante–Governador Aluízio Alves International Airport",
  "Napuka Airport",
  "Nevşehir Kapadokya Airport",
  "Narathiwat Airport",
  "Beijing Nanyuan Airport",
  "Nana Airport",
  "Nambaiyufa Airport",
  "Barranco Minas Airport",
  "Begishevo Airport",
  "Enfidha-Hammamet International Airport",
  "NAS JRB New Orleans (Alvin Callender Field)",
  "Nambucca Heads Airport",
  "Wannukandi Airport",
  "Annobón Airport",
  "Jomo Kenyatta International Airport",
  "Changbaishan Airport",
  "Leeward Point Field",
  "Nabire Airport",
  "North Caicos Airport",
  "Nice Côte d'Azur Airport",
  "Nuevo Casas Grandes Municipal Airport",
  "Nachingwea Airport",
  "Antioquia Airport",
  "Sunchales Aeroclub Airport",
  "Newcastle Airport",
  "Chenega Bay Airport (FAA: C05)",
  "Quonset State Airport (FAA: OQU)",
  "San Carlos Airport",
  "Newcastle Airport",
  "Nicoya Guanacaste Airport",
  "Nukus Airport",
  "Annecy – Haute-Savoie – Mont Blanc Airport",
  "Bandanaira Airport",
  "Nouadhibou International Airport",
  "Shri Guru Gobind Singh Ji Airport",
  "Sumbe Airport",
  "Mandera Airport",
  "N'dalatando Airport",
  "Qiqihar Sanjiazi Airport",
  "Namudi Airport",
  "N'Djamena International Airport",
  "Namorik Airport (Namdrik Airport) (FAA: 3N0)",
  "N'Délé Airport",
  "Mendi Airport",
  "Nadunumu Airport",
  "Nador International Airport",
  "Sandstone Airport",
  "Rundu Airport",
  "Sanday Airport",
  "Necochea Airport",
  "Neftekamsk Airport",
  "Negril Aerodrome",
  "Nejjo Airport",
  "Nekemte Airport",
  "NAES Lakehurst (Maxfield Field)",
  "NOLF Whitehouse",
  "Chulman Neryungri Airport",
  "Nathong Airport",
  "Vance W. Amory International Airport",
  "Lakefront Airport",
  "Nefteyugansk Airport",
  "NAS Fallon (Van Voorhis Field)",
  "Niuafo'ou Airport",
  "Nafoora Airport",
  "Young Airport",
  "Ningbo Lishe International Airport",
  "Auguste George Airport",
  "Ngaoundéré Airport",
  "MCAS Kaneohe Bay",
  "Gau Airport",
  "Nogliki Airport",
  "Ngala Airfield",
  "Narganá Airport",
  "Chūbu Centrair International Airport",
  "NAS Corpus Christi (Truax Field)",
  "Ngari Gunsa Airport",
  "Ningerum Airport",
  "Nagasaki Airport",
  "NS Norfolk (Chambers Field)",
  "NOLF Cabaniss Field",
  "Manang Airport",
  "Nha Trang Air Base",
  "Al Minhad Air Base",
  "New Halfa Airport",
  "NAS Patuxent River (Trapnell Field)",
  "Nushki Airport",
  "RAF Northolt",
  "Nuku Hiva Airport",
  "NOLF Barin (FAA: NBJ)",
  "Brunswick Executive Airport",
  "Nimba Airport",
  "Nikolai Airport (FAA: FSP)",
  "Nicosia International Airport1",
  "Nifty Airport",
  "Nikunau Airport",
  "Niokolo-Koba Airport",
  "Diori Hamani International Airport",
  "Ninilchik Airport",
  "Nioki Airport",
  "NAS Jacksonville (Towers Field)",
  "Chase Field Industrial Complex (FAA: 1XA2)",
  "Simberi Airport",
  "Niort - Souché Airport",
  "Niau Airport",
  "Nioro Airport",
  "Naval Air Facility Atsugi",
  "Nizhnevartovsk Airport",
  "Al Najaf International Airport",
  "NAF El Centro",
  "Nkan Airport",
  "Noonkanbah Airport",
  "Nouakchott–Oumtounsy International Airport",
  "Sinak Airport",
  "Nanjing Lukou International Airport",
  "Naukati Bay Seaplane Base (FAA: AK62)",
  "Nkolo-Fuma Airport",
  "Nagoya Airfield (Komaki Airport)",
  "Nankina Airport",
  "Ankokoambo Airport",
  "Nukutepipi Airport",
  "Nkongsamba Airport",
  "Şırnak Airport",
  "Nkaus Airport",
  "MCAS Miramar",
  "Yokangassi Airport",
  "Simon Mwansa Kapwepwe International Airport",
  "NAS Lemoore (Reeves Field)",
  "Quetzalcóatl International Airport",
  "Jerry Tyler Memorial Airport (FAA: 3TR)",
  "Darnley Island Airport",
  "Nelson Lagoon Airport (FAA: OUL)",
  "Ninglang Luguhu Airport",
  "Nikolayevsk-on-Amur Airport",
  "Norfolk Island Airport",
  "Nullagine Airport",
  "Kneeland Airport (FAA: O19)",
  "N'Dolo Airport",
  "Nelspruit Airport",
  "Nicholson Airport",
  "Xinyuan Nalati Airport",
  "Santa Lucía Air Force Base Num 1",
  "Mykolaiv International Airport",
  "Namangan Airport",
  "Daman Airport",
  "Norman's Cay Airport",
  "Nightmute Airport (FAA: IGT)",
  "Maafaru International Airport",
  "San Miguel Airport",
  "Fort McMurray/Mildred Lake Airport (TC: CAJ3)",
  "Nomane Airport",
  "New Moon Airport",
  "Nappa Merrie Airport",
  "Nansang Airport",
  "Namtu Airport",
  "Kenitra Air Base",
  "Santa Ana Airport",
  "Nangade Airport",
  "Nanning Wuxu International Airport",
  "Namutoni Airport",
  "Naknek Airport (FAA: 5NK)",
  "Nondalton Airport (FAA: 5NN)",
  "Naryan-Mar Airport",
  "Connemara Airport",
  "Nan Nakhon Airport",
  "Nanuque Airport",
  "Nunukan Airport",
  "Nanyang Jiangying Airport",
  "NAS Nowra",
  "Nosara Airport",
  "Ireland West Airport Knock",
  "Norddeich Airport",
  "Nogales International Airport",
  "Novorossiysk Airport",
  "Noyabrsk Airport",
  "Nova Xavantina Airport",
  "Nomad River Airport",
  "Nonouti Airport",
  "Naoro Airport",
  "Sinop Airport",
  "Norðfjörður Airport",
  "Fascene Airport",
  "Marin County Airport (Gnoss Field) (FAA: DVO)",
  "La Tontouta International Airport",
  "Albano Machado Airport",
  "Spichenkovo Airport",
  "NAS Pensacola (Forrest Sherman Field)",
  "Hawke's Bay Airport",
  "Nipa Airport",
  "Nephi Municipal Airport (FAA: U14)",
  "New Plymouth Airport",
  "Nanga Pinoh Airport",
  "Napperby Airport",
  "Novo Progresso Airport",
  "Newport State Airport (FAA: UUU)",
  "San Pedro de Urabá Airport",
  "Mpanda Airport",
  "Millington Regional Jetport",
  "NAS Kingsville",
  "Niquelândia Airport",
  "Presidente Perón International Airport",
  "Nottingham Airport",
  "Reyes Murillo Airport",
  "NAS Key West (Boca Chica Field)",
  "Newquay Airport",
  "Nursultan Nazarbayev International Airport (since June 8, 2020)",
  "Narrandera Airport",
  "NS Mayport (Admiral David L. McDonald Field)",
  "NASA Crows Landing Airport",
  "Norderney Airport",
  "Namrole Airport",
  "Narrogin Airport",
  "Grand Lake Regional Airport (FAA: 3O9)",
  "Norrköping Airport",
  "North Ronaldsay Airport",
  "Keibane Airport",
  "Weeze Airport (Niederrhein Airport)",
  "José Aponte de la Torre Airport (FAA: RVR)",
  "NOLF Imperial Beach (Ream Field)",
  "Narita International Airport",
  "Newry Airport",
  "North Seaplane Base",
  "NAS Whiting Field – North",
  "Noshahr Airport",
  "Yaoundé Nsimalen International Airport",
  "Alykel Airport",
  "Slayton Municipal Airport (FAA: DVP)",
  "Norseman Airport",
  "Nelson Airport",
  "Scone Airport",
  "Nakhon Si Thammarat Airport",
  "Noosa Airport",
  "Naval Air Station Sigonella",
  "Natadola Seaplane Base",
  "Notodden Airport, Tuven",
  "Santa Carolina Airport",
  "NAS Point Mugu (Naval Base Ventura County)",
  "Nantes Atlantique Airport",
  "Nantong Xingdong Airport",
  "Stenkol Airport",
  "Manti-Ephraim Airport (FAA: 41U)",
  "Newcastle Airport / RAAF Base Williamtown",
  "Miracema do Tocantins Airport",
  "Normanton Airport",
  "Agostinho Neto Airport",
  "Noto Airport",
  "Del Norte International Airport",
  "Niuatoputapu Airport",
  "NAS Oceana (Apollo Soucek Field)",
  "Ranai Airport",
  "Pilanesberg International Airport",
  "Gregory Lake Seaplane Base",
  "Numbulwar Airport",
  "En Nahud Airport",
  "Nuremberg Airport",
  "Castlereigh Reservoir Seaplane Base",
  "Nuguria Airstrip",
  "Nunchía Airport",
  "Nuiqsut Airport (FAA: AQT)",
  "Hamedan Air Base (Nogeh Airport)",
  "Nukutavake Airport",
  "Nulato Airport",
  "Neom Bay Airport",
  "NOLF Saufley Field",
  "Nunapitchuk Airport (FAA: 16A)",
  "Moffett Federal Airfield",
  "Nullabor Motel Airport",
  "Norsup Airport",
  "Nutuve Airport",
  "Nakuru Airport",
  "NAS Whidbey Island (Ault Field)",
  "Novy Urengoy Airport",
  "Benito Salas Airport",
  "Nevada Municipal Airport",
  "Nueva Guinea Airport",
  "Navoi International Airport",
  "Narvik Airport, Framnes",
  "Nervino Airport (FAA: O02)",
  "Novo Aripuanã Airport",
  "Novgorod Airport",
  "Nevers - Fourchambault Airport",
  "Navegantes–Ministro Victor Konder International Airport",
  "Neyveli Airport",
  "Mohéli Bandar Es Eslam Airport",
  "Parlin Field (FAA: 2B3)",
  "Norwich International Airport",
  "Nowata Airport",
  "Nyagan Airport",
  "metropolitan area2",
  "Nyeri Airport",
  "MCAF Quantico (Turner Field)",
  "Sunyani Airport",
  "Nanyuki Airport",
  "Nadym Airport",
  "Nyngan Airport",
  "New York Skyports Seaplane Base",
  "Stockholm Skavsta Airport",
  "Nyurba Airport",
  "Naypyidaw Airport",
  "Nyaung U Airport",
  "Monywa Airport",
  "Nzagi Airport",
  "Maria Reiche Neuman Airport",
  "Nzérékoré Airport",
  "Manzhouli Xijiao Airport",
  "Zhalantun Chengjisihan Airport",
  "NAS North Island (Halsey Field)",
  "Forward Operating Base Shank",
  "Orange Airport",
  "Shindand Air Base",
  "Bagram Airfield",
  "Albert J. Ellis Airport",
  "Oakland International Airport",
  "Capital do Café Airport",
  "Oamaru Airport",
  "El Arrayán Airport",
  "Marina Municipal Airport",
  "Sharana Airstrip / Forward Operating Base Sharana",
  "Xoxocotlán International Airport",
  "Camp Bastion Air Base",
  "Oban Airport",
  "Obock Airport",
  "Obano Airport",
  "Okeechobee County Airport",
  "Oberpfaffenhofen Airport",
  "Óbidos Airport",
  "Oostmalle Airfield",
  "Morobe Airport",
  "Oban Airport (North Connel Airport)",
  "Tokachi–Obihiro Airport",
  "Aubenas Aerodrome (Ardèche Méridionale Aerodrome)",
  "Kobuk Airport",
  "Obo Airport",
  "Ocean Reef Club Airport (FAA: 07FA)",
  "Francisco de Orellana Airport",
  "Ocean City Municipal Airport",
  "Ocala International Airport (Jim Taylor Field)",
  "A.L. Mangham Jr. Regional Airport",
  "Ian Fleming International Airport",
  "Boolgeeda Airport",
  "Oceanside Municipal Airport (FAA: OKB)",
  "Corisco International Airport",
  "Aguas Claras Airport",
  "Washington–Warren Airport (Warren Field)",
  "Ouadda Airport",
  "Córdoba Airport",
  "Oakdale Airport (FAA: O27)",
  "Oodnadatta Airport",
  "Hans Christian Andersen Airport",
  "RAF Odiham",
  "Ouanda Djallé Airport",
  "Cordillo Downs Airport",
  "Garrett County Airport (FAA: 2G4)",
  "Long Seridan Airport",
  "Bodaybo Airport",
  "Ord River Airport",
  "Odessa International Airport",
  "A.J. Eisenberg Airport (FAA: OKH)",
  "Oudomsay Airport",
  "Oecusse Airport",
  "Oryol Yuzhny Airport",
  "Vincent Fayks Airport",
  "L.O. Simenstad Municipal Airport",
  "Örnsköldsvik Airport",
  "Antoine de Saint Exupéry Airport",
  "Offutt Air Force Base",
  "Ouango Fitini Airport",
  "Ólafsfjörður Airport",
  "Norfolk Regional Airport (Karl Stefan Memorial Field)",
  "Ofu Airport (FAA: Z08)",
  "Searle Field",
  "Orangeburg Municipal Airport",
  "Ogden-Hinckley Airport",
  "Ogeramnang Airport",
  "Kahului Airport",
  "Ogle Airport",
  "Ustupu-Ogobsucum Airport",
  "Yonaguni Airport",
  "Abengourou Airport",
  "Bongor Airport",
  "Ogdensburg International Airport",
  "Ordu–Giresun Airport",
  "Ongava Airstrip",
  "Ain Beida Airport",
  "Beslan Airport",
  "RNZAF Base Ohakea",
  "Ambohibary Airport",
  'Ohrid "St. Paul the Apostle" Airport',
  "Mohe Gulian Airport",
  "Okha Airport (Novostroyka Airport)",
  "Oshakati Airport",
  "Okhotsk Airport",
  "Wyk auf Föhr Airport",
  "Sohar Airport",
  "PAF Base Kohat",
  "Ourilândia do Norte Airport",
  "Lt. Warren Eaton Airport",
  "Oshima Airport",
  "Okushiri Airport",
  "Oita Airport",
  "Johnson County Executive Airport",
  "Tanjung Api Airport",
  "Naha Airport",
  "Orchid Beach Airport",
  "Will Rogers World Airport",
  "Okadama Airport",
  "Okinoerabu Airport",
  "Okaukuejo Airport",
  "Okoyo Airport",
  "RAF Cottesmore",
  "Oki Airport",
  "Okayama Airport",
  "Kokomo Municipal Airport",
  "Gunung Bintang Airport",
  "Okmulgee Regional Airport",
  "Okondja Airport",
  "Yokota Air Base",
  "Oksapmin Airport",
  "Okaba Airport",
  "Yorke Island Airport",
  "Garden County Airport",
  "Oktyabrsky Airport",
  "Mokuti Lodge Airport",
  "Okao Airport",
  "Oakey Army Aviation Centre",
  "Ørland Airport",
  "Olbia Costa Smeralda Airport",
  "Senadora Eunice Michiles Airport",
  "Old Town Municipal Airport and Seaplane Base (Dewitt Field)",
  "Cattaraugus County-Olean Airport",
  "L. M. Clayton Airport",
  "Old Harbor Airport (FAA: 6R7)",
  "Rif Airport",
  "Olpoi Airport (North West Santo Airport)",
  "Fuerte Olimpo Airport",
  "Oyo Ollombo Airport",
  "Olympia Regional Airport",
  "Lago Musters Airport",
  "Olomouc Airport (Neředín Airport)",
  "Olympic Dam Airport",
  "Olsobip Airport",
  "Forward Operating Base Salerno",
  "Nogales International Airport",
  "Columbus Municipal Airport",
  "Olive Branch Airport",
  "Olkiombo Airstrip",
  "Olney-Noble Airport",
  "Olyokminsk Airport",
  "Eppley Airfield",
  "Omboué Hospital Airport",
  "Ormoc Airport",
  "Oranjemund Airport",
  "Nome Airport",
  "King Hussein Air Base",
  "Omega Airport",
  "Urmia Airport",
  "Omidiyeh Air Base",
  "Ōmura Airport",
  "Omak Airport",
  "Omkalai Airport",
  "Marmul Airport",
  "Mostar Airport",
  "Oradea International Airport",
  "Omsk Tsentralny Airport",
  "Thbeng Meanchey Airport (Preah Vinhear Airport)",
  "Winona Municipal Airport (Max Conrad Field)",
  "Ononge Airport",
  "Ondangwa Airport",
  "Onepusu Airport",
  "Mornington Island Airport",
  "Oneonta Municipal Airport (FAA: N66)",
  "Moanamani Airport",
  "Odate–Noshiro Airport",
  "Olenyok Airport",
  "O'Neill Municipal Airport (John L. Baker Field)",
  "Socorro Municipal Airport",
  "Ontario Municipal Airport",
  "Newport Municipal Airport",
  "Zonguldak Airport",
  "Monkira Airport",
  "Onslow Airport",
  "Ontario International Airport",
  "Ono-i-Lau Airport",
  "Enrique Adolfo Jiménez Airport",
  "Olney Municipal Airport",
  "Oskaloosa Municipal Airport",
  "Toksook Bay Airport",
  "Gold Coast Airport (Coolangatta Airport)",
  "Cooma–Snowy Mountains Airport",
  "Mooraberree Airport",
  "Onotoa Airport",
  "Kópasker Airport",
  "Open Bay Airport",
  "Miami-Opa Locka Executive Airport",
  "Oenpelli Airport",
  "St. Landry Parish Airport (Ahart Field)",
  "Francisco de Sá Carneiro Airport",
  "Presidente João Figueiredo Airport",
  "Balimo Airport",
  "Opuwa Airport",
  "Orán Airport",
  "Örebro Airport",
  "Orocue Airport",
  "O'Hare International Airport",
  "Orléans – Saint-Denis-de-l'Hôtel Airport",
  "Norfolk International Airport",
  "Zorg en Hoop Airport",
  "Worcester Regional Airport",
  "Port Lions Airport",
  "Orinduik Airport",
  "Cork Airport",
  "Orlando Executive Airport",
  "Sywell Aerodrome",
  "Oran Es Sénia Airport",
  "Yoro Airport",
  "Orapa Airport",
  "Yorketown Airport",
  "Orpheus Island Resort Waterport",
  "Northway Airport",
  "Juan Mendoza Airport",
  "Robert (Bob) Curtis Memorial Airport (FAA: D76)",
  "Ormara Airport",
  "Oriximiná Airport",
  "Orly Airport",
  "Orange Walk Airport",
  "metropolitan area1",
  "Grand Glaize-Osage Beach Airport (FAA: K15)",
  "Oscoda–Wurtsmith Airport",
  "Åre Östersund Airport",
  "Omora Airport",
  "Ostafyevo International Airport",
  "Ossima Airport",
  "Wittman Regional Airport",
  "Osijek Airport",
  "Oskarshamn Airport",
  "Oslo Airport, Gardermoen",
  "Mosul International Airport",
  "Osan Air Base",
  "Osborne Mine Airport",
  "Leoš Janáček Airport Ostrava",
  "Osh Airport",
  "Ostend–Bruges International Airport",
  "Ohio State University Airport",
  "Orsk Airport",
  "Kosciusko-Attala County Airport",
  "Namsos Airport, Høknesøra",
  "Koszalin-Zegrze Pomorskie Airport",
  "Mota Airport",
  "Bol-Bérim Airport",
  "Contadora Airport",
  "Worthington Municipal Airport",
  "Southwest Oregon Regional Airport",
  "Pitu Airport",
  "Otjiwarongo Airport",
  "Tillamook Airport (FAA: TMK)",
  "Boutilimit Airport",
  "Ottumwa Regional Airport",
  "Ed-Air Airport (FAA: 2IG4)",
  "Henri Coandă International Airport",
  "Coto 47 Airport",
  "Anacortes Airport (FAA: 74S)",
  "Andre Maggi Airport",
  "Otú Airport",
  "Ontong Java Airport",
  "Oria Airport",
  "Ralph Wien Memorial Airport",
  "Ouagadougou Airport",
  "Angads Airport",
  "Ouésso Airport",
  "Ouahigouya Airport",
  "Oudtshoorn Airport",
  "Out Skerries Airport",
  "Oulu Airport",
  "Oum Hadjer Airport",
  "University of Oklahoma Westheimer Airport (Max Westheimer Airport)",
  "Batouri Airport",
  "Jornalista Benedito Pimentel–Ourinhos State Airport",
  "Bousso Airport",
  "Ouanga Airport",
  "Tazadit Airport",
  "Bekily Airport",
  "Tolmachevo Airport",
  "Asturias Airport (Oviedo Airport)",
  "Oroville Municipal Airport",
  "Air Force Base Overberg",
  "El Tuqui Airport",
  "Olavarría Airport",
  "Sovetsky Airport",
  "Owatonna Degner Regional Airport",
  "Owensboro–Daviess County Airport",
  "Norwood Memorial Airport",
  "Owendo Airport",
  "Central Maine Airport of Norridgewock",
  "Osvaldo Vieira International Airport",
  "Waterbury–Oxford Airport",
  "Miami University Airport",
  "Oxford Airport (London Oxford Airport)",
  "Orientos Airport",
  "Oxnard Airport",
  "Morney Airport",
  "Goya Airport",
  "Oyem Airport",
  "Moyo Airport",
  "Oiapoque Airport",
  "Moyale Airport",
  "Ouyen Airport",
  "Tres Arroyos Airport",
  "Saint-Georges-de-l'Oyapock Airport",
  "Ozona Municipal Airport",
  "Labo Airport",
  "Zagora Airport",
  "Zaporizhia International Airport",
  "Morón Air Base",
  "Cairns Army Airfield",
  "Ouarzazate Airport",
  "Hpa-An Airport",
  "Bilaspur Airport",
  'Albrook "Marcos A. Gelabert" International Airport',
  "Paderborn Lippstadt Airport",
  "Paine Field (Snohomish County Airport)",
  "Pakuba Airfield",
  "Pagadian Airport",
  "Barkley Regional Airport",
  "Parachinar Airport",
  "Port Allen Airport",
  "Captain Germán Olano Moreno Air Base",
  "Tyndall Air Force Base",
  "Pattani Airport",
  "Palo Alto Airport (Santa Clara County Airport)",
  "Toussaint L'Ouverture International Airport",
  "Palmer Municipal Airport",
  "metropolitan area1",
  "Paros National Airport",
  "Lok Nayak Jayaprakash Airport",
  "Pauk Airport",
  "Paulo Afonso Airport",
  "Pambwa Airport",
  "Port-de-Paix Airport",
  "Pamol Airport",
  "El Tajín National Airport",
  "Paranaíba Airport",
  "Hermanos Serdán International Airport",
  "Porbandar Airport",
  "Morela Airport (Puerto Berrío Airport)",
  "Grider Field",
  "Plattsburgh International Airport",
  "Paro International Airport",
  "Palm Beach International Airport",
  "Paama Airport",
  "Bartolomé Salom Airport",
  "Johan Adolf Pengel International Airport",
  "Porto Amboim Airport",
  "Paraburdoo Airport",
  "Punta Islita Airport",
  "Pimenta Bueno Airport",
  "Puerto Barrios Airport",
  "Puerto Leda Airport",
  "Putao Airport",
  "Porto dos Gaúchos Airport",
  "Fazenda Piraguassu Airport",
  "Plettenberg Bay Airport",
  "Portage Creek Airport (FAA: A14)",
  "Pondok Cabe Airport",
  "Puerto Rico Airport",
  "Prairie du Chien Municipal Airport (FAA: PDC)",
  "Potchefstroom Airport",
  "Palacios Airport",
  "Puerto La Victoria Airport",
  "FAP Captain David Abensur Rengifo International Airport",
  "Playa del Carmen Airport",
  "Picton Aerodrome",
  "Punta Colorada Airstrip",
  "Príncipe Airport",
  "Boun Neua Airport",
  "Germán Olano Airport",
  "Picos Airport",
  "Princeton Airport (FAA: 39N)",
  "Poplarville-Pearl River County Airport (FAA: M13)",
  "Punta Chivato Airstrip",
  "Obando Airport",
  "Pedro Bay Airport (FAA: 4K0)",
  "Mueo/Nickel Airport",
  "Ponta do Ouro Airport",
  "Pandie Pandie Airport",
  "Faz Taua Airport",
  "Minangkabau International Airport",
  "Pindiu Airport",
  "DeKalb–Peachtree Airport",
  "João Paulo II Airport",
  "Pedasí Airport (Capt. J. Montenegro Airport)",
  "Parndana Airport",
  "Pendopo Airport",
  "Capitán de Corbeta Carlos A. Curbelo International Airport",
  "Presidente Dutra Airport",
  "Piedras Negras International Airport",
  "Eastern Oregon Regional Airport",
  "Tydeo Larre Borges International Airport",
  "Plovdiv Airport",
  "Portland International Airport",
  "Pedernales Airport",
  "Penneshaw Airport",
  "Pebane Airport",
  "Pelican Seaplane Base",
  "Pardubice Airport",
  "Perm International Airport (Bolshoye Savino Airport)",
  "Peenemünde Airfield",
  "Perugia San Francesco d'Assisi – Umbria International Airport",
  "Comodoro Pedro Zanni Airport",
  "Matecaña International Airport",
  "Beijing Capital International Airport",
  "Pelaneng Airport",
  "Padre Aldamiz International Airport",
  "Penang International Airport",
  "Peppimenarti Airport",
  "Pecos Municipal Airport",
  "Perth Airport",
  "Petrozavodsk Airport (Besovets Airport)",
  "João Simões Lopes Neto International Airport",
  "Puerto Lempira Airport",
  "Pécs-Pogány International Airport",
  "Bacha Khan International Airport",
  "Pechora Airport",
  "Penong Airport",
  "Penza Airport (Ternovka Airport)",
  "Lauro Kurtz Airport",
  "Pacific City State Airport",
  "Patreksfjörður Airport",
  "Primrose Aerodrome",
  "Paphos International Airport",
  "Parsabad-Moghan Airport",
  "Ilebo Airport",
  "Page Municipal Airport",
  "Pangoa Airport",
  "Grant County Airport (FAA: W99)",
  "Punta Gorda Airport",
  "Yegepa Airport",
  "Perpignan–Rivesaltes Airport",
  "Pantnagar Airport",
  "Chitato Airport",
  "Depati Amir Airport",
  "Trent Lott International Airport (FAA: PQL)",
  "Port Graham Airport",
  "Pangia Airport",
  "Stevens Field (FAA: PSO)",
  "Buli Airport",
  "Kirk Field",
  "Grand Canyon Caverns Airport (FAA: L37)",
  "Persian Gulf Airport",
  "Pitt–Greenville Airport",
  "Périgueux Bassillac Airport",
  "Comte. Antonio Amilton Beraldo Airport",
  "Phan Rang Air Base",
  "Parnaíba-Prefeito Dr. João Silva Filho International Airport",
  "Port Harcourt International Airport",
  "Harry Clever Field",
  "Port Hedland International Airport",
  "Newport News/Williamsburg International Airport",
  "Port Harcourt City Airport",
  "Pinheiro Airport",
  "Palm Beach County Glades Airport",
  "Philadelphia International Airport",
  "St. Clair County International Airport",
  "Point Hope Airport",
  "Philip Airport",
  "The Monument Airport",
  "Pacific Harbour Airport",
  "Phitsanulok Airport",
  "Henry County Airport",
  "Hendrik Van Eck Airport",
  "Phoenix Sky Harbor International Airport",
  "Phetchabun Airport",
  "General Wayne A. Downing Peoria International Airport",
  "Hattiesburg–Laurel Regional Airport",
  "Pine Cay Airport",
  "St. Pete–Clearwater International Airport",
  "Pingtung South Airport",
  "Pocatello Regional Airport",
  "Glasgow Prestwick Airport",
  "Carlos Miguel Jiménez Airport",
  "Harris County Airport",
  "Júlio Belém Airport",
  "Capitán FAP Renán Elías Olivera Airport",
  "Pilot Point Airport (FAA: PNP)",
  "Pipillipai Airport",
  "Pierre Regional Airport",
  "Poitiers–Biard Airport",
  "Pittsburgh International Airport",
  "FAP Captain Guillermo Concha Iberico International Airport",
  "Pirapora Airport",
  "Pikwitonei Airport",
  "Pico Airport",
  "Point Lay LRRS Airport",
  "Pajala Airport",
  "Payson Airport (FAA: PAN)",
  "Dr. Augusto Roberto Fuster International Airport",
  "Panjgur Airport",
  "Puerto Jiménez Airport",
  "Napaskiak Airport",
  "Mid-Ohio Valley Regional Airport",
  "Petropavlovsk-Kamchatsky Airport (Yelizovo Airport)",
  "Park Rapids Municipal Airport (Konshok Field)",
  "Parkes Airport",
  "Park Falls Municipal Airport",
  "Pangkor Airport",
  "Porto Cheli Airport",
  "Playa Grande Airport",
  "Pakokku Airport",
  "Port Kaituma Airstrip",
  "Iskandar Airport",
  "Parakou Airport",
  "Puka-Puka Airport",
  "Pokhara Airport",
  "Port Keats Airfield",
  "Sultan Syarif Kasim II International Airport",
  "Pskov Airport",
  "Selebi-Phikwe Airport",
  "Beijing Daxing International Airport",
  "Tjilik Riwut Airport",
  "Pakse International Airport",
  "Planadas Airport",
  "Planeta Rica Airport",
  "Carrillo Airport (Playa Samara/Carrillo Airport)",
  "Paiela Airport",
  "Pala Airport",
  "Plymouth City Airport",
  "Palm Island Airport",
  "Placencia Airport",
  "M. Graham Clark Downtown Airport",
  "Manaus Air Force Base",
  "Sultan Mahmud Badaruddin II International Airport",
  "Pellston Regional Airport (Emmet County)",
  "Port Lincoln Airport",
  "Captain Ramon Xatruch Airport",
  "Palanga International Airport",
  "St. Clair County Airport",
  "Providenciales International Airport",
  "Plato Airport",
  "Belo Horizonte/Pampulha – Carlos Drummond de Andrade Airport",
  "Poltava Airport",
  "Mutiara Airport",
  "Semey Airport",
  "Plymouth Municipal Airport (FAA: C65)",
  "Port Elizabeth Airport",
  "Pemba Airport",
  "Pembina Municipal Airport",
  "El Tepual Airport",
  "Palmdale Regional Airport",
  "Parma Airport (Giuseppe Verdi Airport)",
  "Ponta Porã International Airport",
  "Greater Portsmouth Regional Airport",
  "Palma de Mallorca Airport",
  "Palm Island Airport",
  "Port Moller Airport (FAA: 1AK3)",
  "Pumani Airport",
  "Falcone–Borsellino Airport (Punta Raisi Airport)",
  "Pimaga Airport",
  "Perito Moreno Airport",
  "Palmerston North Airport",
  "Palmyra Airport",
  "Paramakatoi Airstrip",
  "Santiago Mariño Caribbean International Airport",
  "Palmas–Brigadeiro Lysias Rodrigues Airport",
  "Metropolitan Airport (FAA: 13MA)",
  "El Tehuelche Airport",
  "Palmar Sur Airport",
  "Pamplona Airport",
  "Porto Nacional Airport",
  "Ponca City Regional Airport",
  "Punta Gorda Airport",
  "Northeast Philadelphia Airport",
  "Santos Dumont Airport",
  "Phnom Penh International Airport",
  "Pohnpei International Airport",
  "Penglai Shahekou Airport",
  "Supadio Airport",
  "Pantelleria Airport",
  "Princeton Municipal Airport",
  "Girua Airport",
  "Pune Airport",
  "Pointe Noire Airport",
  "Pensacola International Airport",
  "Teniente Julio Gallardo Airport",
  "Panguitch Municipal Airport (FAA: U55)",
  "Panevėžys Air Base (Pajuostis Airport)",
  "North Texas Regional Airport (FAA: GYI)",
  "Puducherry Airport",
  "Senador Nilo Coelho Airport",
  "Salgado Filho International Airport",
  "Pope Field (Pope AFB)",
  "Brackett Field",
  "Podor Airport",
  "Polk Army Airfield",
  "Poplar Bluff Municipal Airport",
  "Port-Gentil International Airport",
  "Pocahontas Municipal Airport",
  "Captain Nicolas Rojas Airport",
  "Pedro Pereira dos Santos Airport",
  "Pemba Airport",
  "Jacksons International Airport",
  "Poptún Airport",
  "Poços de Caldas Airport",
  "Gregorio Luperón International Airport",
  "Pori Airport",
  "Piarco International Airport",
  "Ken Jones Aerodrome",
  "Dutchess County Airport",
  "Prešov Air Base",
  "Portorož Airport",
  "Pontoise – Cormeilles Aerodrome",
  "Powell Municipal Airport",
  "Poznań–Ławica Airport",
  "Perry Lefors Field",
  "Presidente Prudente Airport",
  "Prospect Creek Airport",
  "Puerto Peñasco International Airport",
  "Tri-City Airport",
  "Pago Pago International Airport",
  "Parai-tepuí Airport",
  "Port Pirie Airport",
  "Panjang Island Airport",
  "Petropavl Airport",
  "Phaplu Airport",
  "Pompano Beach Airpark (FAA: PMP)",
  "Guillermo León Valencia Airport",
  "Whitsunday Coast Airport",
  "Kapiti Coast Airport",
  "Tuanku Tambusai Airport",
  "Puerto Princesa International Airport",
  "Fa'a'ā International Airport",
  "Papun Airport",
  "Port Protection Seaplane Base (FAA: 19P)",
  "Papa Westray Airport",
  "Param Airport",
  "Pouso Alegre Airport",
  "Puerto Páez Airport",
  "Phu Quoc International Airport",
  "Batticaloa Seaplane Base",
  "Northern Maine Regional Airport at Presque Isle",
  "Palenque International Airport",
  "Port Macquarie Airport",
  "Pilot Station Airport (FAA: 0AK)",
  "General Justo José de Urquiza Airport",
  "Paso Robles Municipal Airport",
  "Ernest A. Love Field",
  "Pardoo Airport",
  "Pore Airport",
  "Václav Havel Airport Prague",
  "Phrae Airport",
  "Praslin Island Airport",
  "Prieska Airport",
  "Portimão Airport",
  "Pristina International Airport",
  "Perry Municipal Airport",
  "Propriano Airport",
  "Presidencia Roque Sáenz Peña Airport",
  "Paruima Airport",
  "Parasi Airport",
  "Pyay Airport",
  "Přerov Airport",
  "Prentice Airport (FAA: 5N2)",
  "Cox Field",
  "Wonderboom Airport",
  "Prineville Airport (FAA: S39)",
  "Pisa International Airport (Galileo Galilei Airport)",
  "Bellefonte Airport (FAA: N96)",
  "Tri-Cities Airport",
  "Port Said Airport",
  "Mercedita Airport",
  "Pittsfield Municipal Airport",
  "Petersburg James A. Johnson Airport",
  "Sankt Peter-Ording Airport",
  "Pasni Airport",
  "Kasiguncu Airport",
  "New River Valley Airport",
  "Perth Airport (Scone Airport)",
  "Portsmouth International Airport at Pease",
  "Palestine Municipal Airport",
  "Antonio Nariño Airport",
  "Palm Springs International Airport",
  "Philadelphia Seaplane Base (FAA: 9N2)",
  "Abruzzo Airport",
  "Libertador General José de San Martín Airport",
  "Pangsuma Airport",
  "Papa Stour Airstrip",
  "Passos Airport",
  "Palacios Municipal Airport",
  "Port Stanley Airport",
  "Puerto Suárez International Airport",
  "Port Alsworth Airport (FAA: TPO)",
  "Dinwiddie County Airport",
  "Port Alice Seaplane Base (FAA: 16K)",
  "Port Alexander Seaplane Base (FAA: AHP)",
  "Malolo Lailai Airport",
  "Polokwane International Airport",
  "Port Heiden Airport",
  "Portland Airport",
  "Oakland County International Airport",
  "Palmarito Airport",
  "Harry P. Williams Memorial Airport",
  "Juvenal Loureiro Cardoso Airport",
  "Pointe-à-Pitre International Airport (Le Raizet Airport)",
  "Porto de Moz Airport",
  "Atkinson Municipal Airport",
  "Pratt Regional Airport",
  "Platinum Airport",
  "Porterville Municipal Airport",
  "Heritage Field",
  "Contador Airport",
  "Tocumen International Airport",
  "Río Amazonas Airport",
  "Puas Airport",
  "Pueblo Memorial Airport",
  "Carbon County Regional Airport",
  "Puerto Deseado Airport",
  "Puerto Obaldia Airport",
  "Pau Pyrénées Airport",
  "Port Augusta Airport",
  "Pureni Airport",
  "Punta Cana International Airport",
  "Pukarua Airport",
  "Port of Poulsbo Marina Moorage Seaplane Base (FAA: 83Q)",
  "Sangia Nibandera Airport",
  "Punia Airport",
  "Pô Airport",
  "Presidente Carlos Ibáñez del Campo International Airport",
  "Puerto Rico Airport",
  "Gimhae International Airport",
  "Sri Sathya Sai Airport",
  "Tres de Mayo Airport",
  "Malabou Airport",
  "Pullman–Moscow Regional Airport",
  "El Mirador Airport",
  "Pula Airport",
  "Puerto Cabezas Airport",
  "El Embrujo Airport",
  "Provincetown Municipal Airport",
  "Theodore Francis Green State Airport",
  "El Porvenir Airport",
  "Placerville Airport",
  "Shanghai Pudong International Airport",
  "Governador Jorge Teixeira de Oliveira International Airport",
  "Edu Chaves Airport",
  "Aktion National Airport",
  "Pike County Airport (FAA: PBX)",
  "Reales Tamarindos Airport",
  "Licenciado Gustavo Díaz Ordaz International Airport",
  "Provideniya Bay Airport",
  "Provo Municipal Airport",
  "Hale County Airport",
  "Wiley Post Airport",
  "Sher-Wood Airport",
  "Pevek Airport",
  "Beles Airport",
  "Chicago Executive Airport",
  "Wirasaba Airport",
  "Portland International Jetport",
  "Pitts Town Airport",
  "Pweto Airport",
  "Pavlodar Airport",
  "Port Walter Seaplane Base",
  "Bremerton National Airport",
  "Ralph Wenz Field (FAA: PNA)",
  "Atung Bungsu Airport",
  "Prominent Hill Airport",
  "Polacca Airport (FAA: P10)",
  "Puerto Escondido International Airport",
  "Porto Santo Airport",
  "Surin Airport",
  "Pleiku Airport",
  "Velasquez Airport",
  "Jeypore Airport",
  "Playón Chico Airport",
  "Tongareva Airport",
  "Pakyong Airport",
  "Cacique Aramare Airport",
  "Polyarny Airport",
  "Payam International Airport",
  "Perry Island Seaplane Base",
  "Plymouth Municipal Airport",
  "Payán Airport",
  "Putumayo Airport",
  "Andravida Air Base",
  "Paradise Skypark (FAA: CA92)",
  "Yaviza Airport",
  "Pai Airport",
  "Paz de Ariporo Airport",
  "Pietermaritzburg Airport",
  "Zhob Airport",
  "Panzhihua Bao'anying Airport",
  "Pukapuka Island Airfield",
  "Zulu Inyala Airport",
  "Manuel Carlos Piar Guayana Airport",
  "Maquehue Airport",
  "Port Sudan New International Airport",
  "Piešťany Airport",
  "L'Aquila–Preturo Airport",
  "Bella Coola Airport",
  "RAF Coningsby",
  "Husum Schwesing Airport",
  "Monte Real Air Base",
  "Sam Mbakwe Airport",
  "Paya Lebar Air Base",
  "Rand Airport",
  "Rancagua de la Independencia Airport",
  "Querétaro Intercontinental Airport",
  "Warri Airport (Osubi Airport)",
  "Mário Pereira Lopes Airport",
  "Ain Arnat Airport",
  "Salerno Costa d'Amalfi Airport (Pontecagnano Airport)",
  "Shache Airport",
  "Ubari Airport",
  "Chichester/Goodwood Airport",
  "Akwa Ibom International Airport",
  "RAF Wyton",
  "Wilgrove Air Park (FAA: 8A6)",
  "Rakanda Airport",
  "Rabaul Airport (Tokua Airport)",
  "John H. Batten Airport",
  "Road Town Seaplane Base",
  "Arar Domestic Airport",
  "Rafaela Airport",
  "Raglan Aerodrome",
  "Rafha Domestic Airport",
  "Nelson Mandela International Airport",
  "Rajkot Airport",
  "Marrakesh Menara Airport",
  "Riverside Municipal Airport",
  "Ramingining Airport",
  "Ravenna Airport",
  "Leite Lopes Airport",
  "Rapid City Regional Airport",
  "Sugimanuru Airport",
  "Rarotonga International Airport",
  "Rasht Airport",
  "Raduzhny Airport",
  "Cravo Norte Airport",
  "Arawa Airport",
  "Oram Airport",
  "Rawalakot Airport",
  "Rabat–Salé Airport",
  "Borba Airport",
  "Robinvale Airport",
  "Dallas Executive Airport",
  "Ratanakiri Airport",
  "Big Bear City Airport (FAA: L35)",
  "Roseburg Regional Airport",
  "Rabi Airport",
  "Rebun Airport",
  "French Valley Airport (FAA: F70)",
  "Red Bluff Municipal Airport",
  "Straubing Wallmühle Airport",
  "Roboré Airport",
  "Rabaraba Airport",
  "Rurrenabaque Airport",
  "Plácido de Castro International Airport",
  "Orbost Airport",
  "Marsabit Airport",
  "Roebourne Airport",
  "Ramata Airport",
  "Lowcountry Regional Airport",
  "Rumbek Airport",
  "Ruby Airport",
  "Ellsworth Air Force Base",
  "Richards Bay Airport",
  "Roche Harbor Seaplane Base",
  "Almirante Padilla Airport",
  "H. H. Coffield Regional Airport",
  "Redcliffe Airport",
  "Richmond Airport",
  "American River Airport",
  "Rochefort – Saint-Agnant Airport",
  "Reconquista Airport",
  "Fulton County Airport",
  "Rochester Airport",
  "Nartron Field",
  "Las Higueras Airport",
  "Port Nelson Airport",
  "Rockhampton Downs Airport",
  "Red Dog Airport",
  "Redenção Airport",
  "Redding Municipal Airport",
  "Merdey Airport (Jahabra Airport)",
  "Reading Regional Airport (Carl A. Spaatz Field)",
  "Roberts Field",
  "Redang Airport",
  "Radom Airport",
  "Kazi Nazrul Islam Airport",
  "Grand Forks Air Force Base",
  "Rincón de los Sauces Airport",
  "Richard Toll Airport",
  "Raleigh–Durham International Airport",
  "Red Devil Airport",
  "Rodez–Marcillac Airport",
  "Reao Airport",
  "Rechlin–Lärz Airfield",
  "Recife/Guararapes–Gilberto Freyre International Airport",
  "Mifflin County Airport (FAA: RVL)",
  "Reese Airpark (FAA: 8XS8)",
  "Reggio di Calabria Airport",
  "Régina Airport",
  "metropolitan area1",
  "Almirante Marcos A. Zar Airport",
  "Orenburg Tsentralny Airport",
  "Rome State Airport",
  "Siem Reap International Airport (Angkor Int'l)",
  "Reko Diq Airport",
  "Retalhuleu Airport",
  "Resistencia International Airport",
  "Røst Airport",
  "Reus Airport",
  "Churhata Airport",
  "General Lucio Blanco International Airport",
  "Reyes Airport",
  "Resende Airport",
  "Rafaï Airport",
  "Chicago Rockford International Airport (Greater Rockford Airport)",
  "Rooke Field",
  "Rollang Field (FAA: 5MS1)",
  "Raufarhöfn Airport",
  "Raiatea Airport (Uturoa Airport)",
  "Río Frío Airport",
  "Rosita Airport",
  "Hermes Quijada International Airport",
  "Porgera Airport",
  "Balurghat Airport",
  "Rangiroa Airport",
  "Gorno-Altaysk Airport",
  "Piloto Civil Norberto Fernández International Airport",
  "Yangon International Airport",
  "Chongjin Airport",
  "Ranger Municipal Airport (FAA: F23)",
  "Burgos Airport",
  "Japura Airport",
  "Reykhólar Airport",
  "Termas de Río Hondo Airport",
  "Reims – Champagne Air Base",
  "Ruhengeri Airport",
  "Rhinelander–Oneida County Airport",
  "Roy Hill Station Airport",
  "Skorpion Mine Airport",
  "Rhodes International Airport",
  "Ramechhap Airport",
  "Al Hamra Seaplane Base",
  "Alxa Right Banner Badanjilin Airport",
  "Reid–Hillview Airport of Santa Clara County",
  "Santa Maria Airport",
  "Riberalta Airport",
  "Richmond International Airport",
  "Richmond Municipal Airport",
  "Rice Lake Regional Airport (Carl's Field) (FAA: RPD)",
  "Richfield Municipal Airport",
  "Rio Grande Regional Airport",
  "Scarlett Martinez International Airport",
  "Juan Simons Vela Airport",
  "Garfield County Regional Airport",
  "San Nicolas Airport",
  "Ringgi Cove Airport",
  "metropolitan area2",
  "Flabob Airport",
  "Rishiri Airport",
  "March Air Reserve Base",
  "Riverton Regional Airport",
  "Riga International Airport",
  "Riyan Airport",
  "Rizhao Shanzihe Airport",
  "Rajahmundry Airport",
  "Rajbiraj Airport",
  "Shah Makhdum Airport",
  "Rajouri Airport",
  "Rijeka Airport",
  "Logroño–Agoncillo Airport",
  "Marinda Airport",
  "Rafsanjan Airport",
  "Aratika-Nord Airport",
  "Knox County Regional Airport",
  "Roskilde Airport",
  "Rock Hill/York County Airport (FAA: UZA)",
  "Rokot Airport",
  "Aransas County Airport",
  "Robert S. Kerr Airport",
  "Rock Springs–Sweetwater County Airport",
  "Ras Al Khaimah International Airport",
  "Kairuku Airport",
  "Reykjavík Airport",
  "Rockwood Municipal Airport",
  "Rokeby Airport",
  "Shigatse Peace Airport",
  "Richland Airport",
  "Rostock–Laage Airport",
  "Bayannur Tianjitai Airport",
  "Valle del Conlara Airport",
  "Rosella Plains Airport",
  "Relais de la Reine Airport",
  "Arlit Airport",
  "Roma Airport",
  "Buraimi Airport",
  "Ramagundam Airport (Basanth Nagar Airport)",
  "Griffiss International Airport",
  "Marsa Alam International Airport",
  "Richard B. Russell Airport",
  "Federico Fellini International Airport",
  "Renmark Airport",
  "Ratmalana Airport",
  "Rumginae Airport",
  "Rampart Airport",
  "Taichung Airport (Taichung Ching Chuan Kang Airport)",
  "Ramstein Air Base",
  "Rimatara Airport",
  "Región de Murcia International Airport",
  "Mariposa-Yosemite Airport (FAA: MPI)",
  "Ulawa Airport",
  "Ronneby Airport",
  "Warren County Memorial Airport",
  "Randolph Air Force Base",
  "Roanne Renaison Airport",
  "Rangely Airport (FAA: 4V0)",
  "New Richmond Regional Airport",
  "Corn Island Airport",
  "Yoron Airport",
  "Rennell/Tingoa Airport",
  "Qarn Alam Airport",
  "Bornholm Airport",
  "Reno–Tahoe International Airport",
  "Rongelap Airport",
  "Robinson River Airport",
  "Rennes–Saint-Jacques Airport",
  "Renton Municipal Airport",
  "Ranau Airport",
  "Jasper County Airport (FAA: RZL)",
  "Roanoke–Blacksburg Regional Airport (Woodrum Field)",
  "Roberts International Airport",
  "Greater Rochester International Airport",
  "Robertson Airfield",
  "Montague Airport (Yreka Rohrer Field) (FAA: 1O5)",
  "Rogers Municipal Airport (Carter Field)",
  "Robinhood Airport",
  "Roi Et Airport",
  "Rockhampton Airport",
  "Roosevelt Municipal Airport",
  "metropolitan area3",
  "Juan José Rondón Airport",
  "Maestro Marinho Franco Airport",
  "Rota International Airport (FAA: GRO)",
  "Roman Tmetuchl International Airport",
  "Rosario – Islas Malvinas International Airport",
  "Rotorua Regional Airport",
  "Ruse Airport",
  "Platov International Airport",
  "Roswell International Air Center",
  "Roseau Municipal Airport (Rudy Billberg Field)",
  "Río Mayo Airport",
  "US Naval Station Rota",
  "Rolpa Airport",
  "Roper Bar Airport",
  "Ngukurr Airport",
  "Rosh Pina Airport",
  "Swami Vivekananda Airport",
  "Roper Valley Airport",
  "Roundup Airport",
  "Ruoqiang Loulan Airport",
  "Qayyarah Airfield West",
  "Marree Airport",
  "Sir Gaëtan Duval Airport",
  "Barora Airport",
  "Jacarepaguá Airport",
  "Rourkela Airport",
  "Merrill Municipal Airport",
  "Marromeu Airport",
  "Raroia Airport",
  "Røros Airport",
  "Warroad International Memorial Airport (Swede Carlson Field)",
  "Robinson River Airport",
  "Santa Rosa Airport",
  "Roseberth Airport",
  "Rock Sound International Airport",
  "Rose Bay Water Airport",
  "Russian Mission Airport",
  "Rosario Seaplane Base (FAA: W49)",
  "Abresso Airport",
  "Russell Municipal Airport",
  "Ruston Regional Airport",
  "Damazin Airport",
  "Rochester International Airport",
  "Yeosu/Suncheon Airport",
  "Southwest Florida International Airport",
  "Rouses Point Seaplane Base (FAA: K21)",
  "Rotuma Airport",
  "Juan Manuel Gálvez International Airport",
  "Ratnagiri Airport",
  "Frans Sales Lega Airport",
  "David Constantijn Saudale Airport",
  "Spirit Lake Municipal Airport (FAA: 0F3)",
  "Rotterdam The Hague Airport",
  "Raton Municipal Airport (Crews Field)",
  "Rutland Plains Airport",
  "Rottnest Island Airport",
  "Saratov Tsentralny Airport",
  "Merty Merty Airport",
  "Arua Airport",
  "Shahroud Airport",
  "Butembo Airport",
  "Yuruf Airport",
  "Rugao Air Base",
  "King Khalid International Airport",
  "Sierra Blanca Regional Airport (FAA: SRR)",
  "Chaurjahari Airport",
  "Maavaarulaa Airport",
  "Rumjatar Airport",
  "Roland Garros Airport",
  "Rupsi Airport",
  "Rurutu Airport",
  "Marau Airport",
  "Rutland – Southern Vermont Regional Airport",
  "Ruti Airport",
  "Rubelsanto Airport",
  "Copán Ruinas Airport",
  "Farafangana Airport",
  "River Cess Airport",
  "General Leite de Castro Airport",
  "Los Colonizadores Airport",
  "Rzhevka Airport",
  "Rostov-on-Don Airport",
  "Rørvik Airport, Ryum",
  "Rovaniemi Airport",
  "Reivilo Airport",
  "Green River Municipal Airport (FAA: U34)",
  "Richard Lloyd Jones Jr. Airport",
  "Ravensthorpe Airport",
  "Raivavae Airport",
  "Pres. Gral. Óscar D. Gestido International Airport",
  "Redwood Falls Municipal Airport",
  "Rocky Mount–Wilson Regional Airport",
  "Rawlins Municipal Airport (Harvey Field)",
  "Rivne International Airport",
  "Ar Rawdah Airport",
  "Rexburg–Madison County Airport",
  "Roxas Airport",
  "Staroselye Airport",
  "Moss Airport, Rygge / Rygge Air Station",
  "Shaikh Zayed International Airport",
  "Royal Airstrip",
  "Royan – Médis Aerodrome",
  "Rio Turbio Airport",
  "Santa Cruz Airport",
  "Rzeszów–Jasionka Airport",
  "Turlatovo Airport",
  "Cesar Lim Rodriguez Airport",
  "Ramsar International Airport",
  "Sawan Airport",
  "Halifax County Airport",
  "Shively Field",
  "Juancho E. Yrausquin Airport",
  "Sacramento Executive Airport",
  "Safford Regional Airport",
  "Santa Fe Municipal Airport",
  "Shirdi Airport",
  "Sana'a International Airport (El Rahaba Airport)",
  "Sauðárkrókur Airport",
  "Monseñor Óscar Arnulfo Romero International Airport",
  "Salamo Airport",
  "San Diego International Airport",
  "metropolitan area1",
  "Ramón Villeda Morales International Airport",
  "San Andros Airport",
  "Sparta Community Airport (Hunter Field)",
  "Salton Sea Airport",
  "San Antonio International Airport",
  "Tardamu Airport",
  "Savannah/Hilton Head International Airport",
  "Sabiha Gökçen International Airport",
  "Sambú Airport",
  "Siena–Ampugnano Airport",
  "Sasstown Airport",
  "Santa Barbara Municipal Airport",
  "Santa Bárbara de Barinas Airport",
  "Selbang Airport",
  "San Bernardino International Airport",
  "Suabi Airport",
  "Sardeh Band Airport",
  "Maimun Saleh Airport",
  "Gustaf III Airport",
  "Sambailo Airport",
  "São Mateus Airport",
  "Saint-Brieuc – Armor Airport",
  "Santa Ana del Yacuma Airport",
  "Sheboygan County Memorial Airport",
  "South Bend International Airport",
  "Salina-Gunnison Airport (FAA: 44U)",
  "San Luis Obispo County Regional Airport (McChesney Field)",
  "Sibi Airport",
  "Saibai Island Airport",
  "Steamboat Springs Airport (Bob Adams Field)",
  "Sabetta International Airport",
  "Springbok Airport",
  "Sabah Airport",
  "Sibu Airport",
  "Shelby Airport",
  "Salisbury–Ocean City–Wicomico Regional Airport",
  "Sibiu International Airport",
  "Santa Catalina Airport",
  "Scribner State Airport",
  "Deadhorse Airport",
  "Sulaco Airport",
  "University Park Airport (FAA: UNV)",
  "Scottsdale Airport (FAA: SDL)",
  "Spring Creek Airport",
  "Schenectady County Airport",
  "Paramillo Airport",
  "Stockton Metropolitan Airport",
  "Comodoro Arturo Merino Benítez International Airport",
  "Scammon Bay Airport",
  "Saarbrücken Airport",
  "Aktau Airport",
  "Mont-Dauphin - Saint-Crépin Airport",
  "Santiago de Compostela Airport",
  "Scandinavian Mountains Airport",
  "Scatsta Airport",
  "Socotra Airport",
  "Antonio Maceo International Airport",
  "Suceava International Airport (Ștefan cel Mare Int'l)",
  "Syktyvkar Airport",
  "Salina Cruz Airport",
  "San Cristóbal Airport",
  "Santa Cruz/Graciosa Bay/Luova Airport",
  "Air Force Base Langebaanweg",
  "Sand Creek Airport",
  "Lubango Mukanka Airport",
  "Vicecomodoro Ángel de la Paz Aragonés Airport",
  "Louisville International Airport (Standiford Field)",
  "Sanandaj Airport",
  "Santa Rosa de Copán Airport",
  "Saidor Airport",
  "Sendai Airport",
  "Sandakan Airport",
  "Sundsvall-Timrå Airport (Midlanda Airport)",
  "Brown Field Municipal Airport",
  "Sandane Airport, Anda",
  "Sand Point Airport",
  "Las Américas International Airport",
  "Santander Airport",
  "Sado Airport",
  "Saidu Sharif Airport",
  "Santos Dumont Airport",
  "Sde Dov Airport",
  "Sedona Airport (FAA: SEZ)",
  "Sidney–Richland Municipal Airport",
  "metropolitan area2",
  "Seattle–Tacoma International Airport",
  "Sabha Airport",
  "Sdom Airfield (Hashnayim Airfield)",
  "Gillespie Field",
  "Sebring Regional Airport",
  "Penn Valley Airport",
  "Senggeh Airport",
  "Srednekolymsk Airport",
  "metropolitan area3",
  "Craig Field",
  "London Southend Airport",
  "Séguéla Airport",
  "Stephenville Clark Regional Airport",
  "Sei Pakning Airport",
  "Freeman Municipal Airport",
  "Seronera Airstrip",
  "Severodonetsk Airport",
  "Siwa Oasis North Airport",
  "Sélibaby Airport",
  "Seychelles International Airport",
  "Sfax–Thyna International Airport",
  "Orlando Sanford International Airport",
  "Saint-François Airport",
  "Las Flecheras Airport",
  "San Fernando Airport",
  "Felts Field",
  "L'Espérance Airport (Grand Case Airport)",
  "San Felipe International Airport",
  "Kangerlussuaq Airport",
  "Soure Airport",
  "São Filipe Airport",
  "Sanford Seacoast Regional Airport",
  "Sauce Viejo Airport",
  "San Francisco International Airport",
  "Şanlıurfa Airport",
  "Subic Bay International Airport",
  "Skellefteå Airport",
  "Safia Airport",
  "Santa Fé do Sul Airport",
  "Macagua Airport",
  "metropolitan area4",
  "North Central State Airport",
  "Sheghnan Airport",
  "Singaua Airport",
  "Surgut International Airport",
  "Sønderborg Airport",
  "Siegerland Airport",
  "Springfield–Branson National Airport",
  "Springfield–Beckley Municipal Airport",
  "PAF Base Mushaf",
  "Sagarai Airport",
  "Sangapi Airport",
  "Sangley Point Airport",
  "San Ignacio Airfield",
  "Tan Son Nhat International Airport",
  "St George Airport",
  "Shay Gap Airport",
  "Sangkimah Airport",
  "Sugar Land Regional Airport",
  "Stuttgart Municipal Airport",
  "St. George Regional Airport",
  "Sierra Grande Airport",
  "Saginaw Seaplane Base (FAA: A23)",
  "Songea Airport",
  "Skagway Airport",
  "Songkhla Airport",
  "Shanghai Hongqiao International Airport",
  "Nakashibetsu Airport",
  "Shire Airport",
  "Shenandoah Valley Regional Airport",
  "Shenyang Taoxian International Airport",
  "Shihezi Huayuan Airport",
  "Shungnak Airport",
  "Shishmaref Airport",
  "Shimojishima Airport",
  "Sharjah International Airport",
  "Sehonghong Airport",
  "Shillong Airport (Barapani Airport)",
  "Nanki–Shirahama Airport",
  "Sanderson Field",
  "King Mswati III International Airport (Sikhuphe Int'l)",
  "Southport Airport",
  "Sheridan County Airport",
  "Shashi Airport",
  "Shepparton Airport",
  "Smith Point Airport",
  "Shreveport Regional Airport",
  "Sharurah Domestic Airport",
  "Shageluk Airport",
  "Shinyanga Airport",
  "Seshutes Airport",
  "Xi'an Xiguan Airport",
  "Sibiti Airport",
  "San José Airport",
  "Amílcar Cabral International Airport",
  "Sines Airport",
  "Simara Airport",
  "Fernando Luis Ribas Dominicci Airport",
  "Silgadhi Airport",
  "Sidi Ifni Airport (Sania Ramel Airport)",
  "Siglufjörður Airport",
  "Sikeston Memorial Municipal Airport",
  "Sila Airport",
  "Simbai Airport",
  "Singapore Changi Airport",
  "Smithton Airport",
  "Simferopol International Airport",
  "Dabo Singkep Airport",
  "Sion Airport",
  "Sishen Airport",
  "Sitka Rocky Gutierrez Airport",
  "Siuna Airport",
  "Sullivan County Airport",
  "Sibisa Airport",
  "Singleton Airport",
  "Siskiyou County Airport",
  "Sissano Airport",
  "San Juan de Marcona Airport",
  "San Joaquín Airport",
  "San Jose International Airport",
  "Los Cabos International Airport",
  "Jorge Enrique González Torres Airport",
  "Cruz Bay Seaplane Base",
  "San Pedro de Jagua Airport",
  "San Juan del Cesar Airport",
  "San Jose Airport",
  "Sarajevo International Airport",
  "Professor Urbano Ernesto Stumpf Airport",
  "São Gabriel da Cachoeira Airport",
  "St. Johns Industrial Air Park",
  "Juan Santamaría International Airport",
  "Prof. Eribelto Manoel Reino State Airport",
  "Sesheke Airport",
  "San Juan de Urabá Airport",
  "San José de Chiquitos Airport",
  "San Angelo Regional Airport (Mathis Field)",
  "Luis Muñoz Marín International Airport",
  "San Javier Airport",
  "Shijiazhuang Zhengding International Airport",
  "Sarteneja Airport",
  "Seinäjoki Airport",
  "São Jorge Airport",
  "Fairchild Air Force Base",
  "Robert L. Bradshaw International Airport",
  "Suki Airport",
  "Samarkand International Airport",
  "Skien Airport, Geiteryggen",
  "Kelly Field Annex / Lackland Air Force Base",
  'Thessaloniki Airport "Macedonia"',
  "Surkhet Airport",
  "Skikda Airport",
  "Shaktoolik Airport (FAA: 2C7)",
  "Broadford Airfield",
  "Skeldon Airport",
  "Stokmarknes Airport, Skagen",
  "Sadiq Abubakar III International Airport",
  'Skopje "Alexander the Great" Airport',
  "Sekake Airport",
  "Shakiso Airport",
  "Vojens Airport (Skrydstrup Airport)",
  "Sialkot International Airport",
  "Skyros Island National Airport",
  "St. Catherine International Airport",
  "Skwentna Airport",
  "Saransk Airport",
  "Griffing Sandusky Airport",
  "Sukkur Airport",
  "Martín Miguel de Güemes International Airport",
  "Storm Lake Municipal Airport",
  "Salt Lake City International Airport",
  "Sliač Airport",
  "McNary Field",
  "Sulayel Airport",
  "Smith Field",
  "Vanua Lava Airport",
  "Solwezi Airport",
  "Solomon Airport",
  "Adirondack Regional Airport",
  "Salalah International Airport",
  "Salamanca Airport",
  "Salina Regional Airport",
  "Salem–Leckrone Airport",
  "Ponciano Arriaga International Airport",
  "Sleetmute Airport",
  "Sulphur Springs Municipal Airport",
  "Silistra Airfield",
  "Harriet Alexander Field (FAA: ANK)",
  "George F. L. Charles Airport",
  "Shimla Airport",
  "Plan de Guadalupe International Airport",
  "Salt Cay Airport",
  "Salekhard Airport",
  "Marechal Cunha Machado International Airport",
  "Santa Maria Airport",
  "Franco Bianco Airport",
  "Santa María Airport",
  "Smith Field",
  "Lake Cumberland Regional Airport",
  "Sacramento International Airport",
  "Santa María Airport",
  "Sapmanga Airport",
  'Samos International Airport "Aristarchos of Samos"',
  "Sim Airport",
  "St. Michael Airport",
  "Stella Maris Airport",
  "Semporna Airport",
  "Lemhi County Airport",
  "Santa Monica Municipal Airport",
  "Stockholm Airport",
  "H. Asan Airport",
  "Simón Bolívar International Airport",
  "Sainte Marie Airport",
  "Sheep Mountain Airport",
  "Samedan Airport (Engadin Airport)",
  "Smara Airport",
  "Santa Maria Public Airport (Capt. G. Allan Hancock Field)",
  "Simenti Airport",
  "Stoelmans Eiland Airstrip",
  "John Wayne Airport (Orange County Airport)",
  "Snake Bay Airport",
  "General Ulpiano Paez Airport",
  "Preguiça Airport",
  "Sub Teniente Nestor Arias Airport",
  "Capitán Av. Juan Cochamanidis Airport",
  "Stanthorpe Airport",
  "Greenville/Sinoe Airport (R.E. Murray Airport)",
  "San Julián Air Base",
  "Winston Field Airport",
  "Shawnee Regional Airport",
  "San Ignacio de Moxos Airport",
  "Shannon Airport",
  "Sakon Nakhon Airport",
  "St. Paul Island Airport",
  "San Quintín Military Airstrip",
  "Saint-Nazaire Montoir Airport",
  "Salinas Municipal Airport",
  "Las Cruces Airport",
  "Abel Santamaría Airport",
  "Santa Elena de Uairén Airport",
  "Thandwe Airport",
  "Semnan Municipal Airport",
  "Sidney Municipal Airport (Lloyd W. Carr Field)",
  "Santa Cruz Air Force Base",
  "Sóc Trăng Airfield",
  "Hévíz–Balaton Airport",
  "Adisumarmo International Airport",
  "Bertram Luiz Leupolz–Sorocaba Airport",
  "Souanké Airport",
  "Sofia Airport",
  "Sogndal Airport, Haukåsen",
  "Sørkjosen Airport",
  "Semonkong Airport",
  "Solomon State Field Airport (FAA: AK26)",
  "San Tomé Airport",
  "Santo-Pekoa International Airport",
  "Söderhamn Airport (Helsinge Airport)",
  "Moore County Airport",
  "Dominique Edward Osok Airport",
  "Sodankylä Airfield",
  "Southampton Airport",
  "Seldovia Airport",
  "Show Low Regional Airport",
  "Alberto Lleras Camargo Airport",
  "Stronsay Airport",
  "Solenzara Air Base",
  "Spartanburg Downtown Memorial Airport",
  "Charlotte Amalie Harbor Seaplane Base (St. Thomas Seaplane Base) (FAA: VI22)",
  "La Palma Airport",
  "Saidpur Airport",
  "Sepulot Airport",
  "Black Hills Airport (Clyde Ice Field)",
  "Albert Whitted Airport",
  "Sopu Airport",
  "Abraham Lincoln Capital Airport",
  "Sparti Airport",
  "metropolitan area5",
  "Spangdahlem Air Base",
  "Saipan International Airport (Francisco C. Ada Int'l) (FAA: GSN)",
  "Menongue Airport",
  "John Greif II Airport",
  "Wichita Falls Municipal Airport",
  "Sipitang Airstrip",
  "Split Airport",
  "Sepik Plains Airport",
  "Spencer Municipal Airport",
  "San Pédro Airport",
  "Sphinx International Airport",
  "Springdale Municipal Airport (FAA: ASG)",
  "Santa Ynez Airport (FAA: IZA)",
  "Santa Ana Airport",
  "Southern Cross Airport",
  "Shangrao Sanqingshan Airport",
  "San Luis de Palenque Airport",
  "Tebelian Airport (Susilo Airport)",
  "Nà Sản Airport",
  "Whiteside County Airport (Jos. H. Bittorf Field)",
  "Sanming Shaxian Airport",
  "Sidi Barrani Airport",
  "San Carlos Airport",
  "São Miguel do Araguaia Airport",
  "Sanana Airport",
  "Storuman Airport",
  "Šiauliai International Airport",
  "Soroako Airport",
  "Matthew Spain Airport",
  "China Strait Airstrip",
  "Saposoa Airport",
  "Sequim Valley Airport (FAA: W28)",
  "Skive Airport",
  "Hélio Wasum Airport",
  "São Lourenço do Sul Airport",
  "RAF Scampton",
  "Santa Rosa Airport",
  "Santa Rosa Airport",
  "Searcy Municipal Airport",
  "San Ramón Airport",
  "Alcantarí Airport",
  "San Rafael Airport (Hamilton Field)",
  "Achmad Yani International Airport",
  "Sarh Airport",
  "Temindung Airport",
  "Capitán Germán Quiroga Guardia Airport",
  "Palo Verde Airport",
  "Sandringham Station Airport",
  "Strahan Airport",
  "Santana Ramos Airport",
  "Stord Airport, Sørstokken",
  "Sarasota–Bradenton International Airport",
  "Dunwich Airport",
  "San Marcos Airport",
  "Soroti Airport",
  "Stony River Airport",
  "Rowan County Airport (FAA: RUQ)",
  "Gardabya Airport",
  "Dasht-e Naz Airport",
  "El Trompillo Airport",
  "Deputado Luís Eduardo Magalhães International Airport",
  "Christiansted Harbor Seaplane Base (St. Croix Seaplane Base) (FAA: VI32)",
  "Shaw Air Force Base",
  "San Felipe Airport",
  "Solapur Airport",
  "Stinson Municipal Airport",
  "Malabo International Airport (Saint Isabel Airport)",
  "Sharm el-Sheikh International Airport",
  "Malcolm McKinnon Airport",
  "Sandnessjøen Airport, Stokka",
  "Sturt Creek Airport",
  "Santa Rosalía Airport",
  "Seoul Air Base",
  "São Lourenço Airport",
  "Silver Plains Airport",
  "La Sarre Airport (TC: CSR8)",
  "Sara Airport",
  "Siassi Airport",
  "Santa Teresita Airport",
  "Siasi Airport",
  "Stuart Island Airpark (FAA: 7WA5)",
  "Mbanza Congo Airport",
  "Santos Air Force Base",
  "Stauning Vestjylland Airport",
  "Miguel Urdaneta Fernández Airport",
  "St. Cloud Regional Airport",
  "Mayor Buenaventura Vivas Airport",
  "Stevens Point Municipal Airport",
  "Stephens Island Airport",
  "St. George Airport (FAA: PBV)",
  "Strathmore Airport",
  "Cibao International Airport",
  "Rosecrans Memorial Airport",
  "Sterling Municipal Airport",
  "Lambert–St. Louis International Airport",
  "Santarém-Maestro Wilson Fonseca Airport",
  "London Stansted Airport",
  "metropolitan area6",
  "St. Paul Downtown Airport (Holman Field)",
  "St. Marys Municipal Airport (FAA: OYM)",
  "Stuttgart Airport",
  "Charles M. Schulz–Sonoma County Airport",
  "Cyril E. King Airport",
  "Surat Airport",
  "Stavropol Shpakovskoye Airport",
  "Henry E. Rohlsen Airport",
  "Nueva Hespérides International Airport",
  "Santa Terezinha Airport",
  "Witham Field",
  "Juanda International Airport",
  "Stroud Municipal Airport",
  "Door County Cherryland Airport",
  "Lamezia Terme International Airport",
  "Surigao Airport",
  "Sur Airport",
  "Sukhumi Babushara Airport (Dranda Airport)",
  "Satu Mare International Airport",
  "Sakkyryr Airport",
  "Sui Airport",
  "Sumter Airport (FAA: SMS)",
  "Friedman Memorial Airport",
  "Sunriver Airport (FAA: S21)",
  "Trunojoyo Airport",
  "Sucúa Airport",
  "Summer Beaver Airport (TC: CJV7)",
  "Spirit of St. Louis Airport",
  "Sumbawanga Airport",
  "Travis Air Force Base",
  "Nausori International Airport",
  "Richard I. Bong Airport",
  "Sioux Gateway Airport (Colonel Bud Day Field)",
  "Suntar Airport",
  "Suria Airport",
  "Savoonga Airport",
  "Sambava Airport",
  "Grant County Airport",
  "Argyle International Airport",
  "Susanville Municipal Airport",
  "Savé Airport",
  "Stavanger Airport, Sola",
  "Statesville Regional Airport",
  "Eduardo Falla Solano Airport",
  "Svolvær Airport, Helle",
  "Silver Creek Airport",
  "Savonlinna Airport",
  "St Pauls Airport",
  "Hunter Army Airfield",
  "Sheremetyevo International Airport",
  "Kuito Airport",
  "Seville Airport",
  "Stevens Village Airport",
  "Savuti Airport",
  "Savusavu Airport",
  "Sparrevohn LRRS Airport",
  "Koltsovo International Airport",
  "Savo Airport",
  "Juan Vicente Gómez International Airport",
  "Jieyang Chaoshan International Airport",
  "Shaw River Airport",
  "Stawell Airport",
  "Seward Airport",
  "Siwea Airport",
  "Stewart International Airport",
  "Satwag Airport",
  "Swan Hill Airport",
  "South West Bay Airport",
  "San Vicente Airport",
  "Suia-Missu Airport",
  "Sahiwal Airport",
  "Stillwater Regional Airport",
  "Swakopmund Airport",
  "Sultan Muhammad Kaharuddin III Airport (Brangbiji Airport)",
  "Silur Airport",
  "Swansea Airport",
  "Strezhevoy Airport",
  "Suwon Air Base",
  "Severo-Evensk Airport",
  "Avenger Field",
  "Shakawe Airport",
  "Sitiawan Airport",
  "Sialum Airport",
  "Strasbourg Airport",
  "West Sale Airport",
  "Berlin Schönefeld Airport",
  "Senanga Airport",
  "Sehulea Airport",
  "Sirri Island Airport",
  "Shanshan Airport",
  "Saumlaki Airport (Olilit Airport)",
  "Sligo Airport",
  "Princess Juliana International Airport",
  "Sua Pan Airport",
  "São Félix do Araguaia Airport",
  "Sheldon Point Airport",
  "Soldotna Airport",
  "Sheikh ul-Alam International Airport",
  "Sahabat Airport",
  "Sungai Tiang Airport",
  "Soddu Airport",
  "Salem Airport",
  "Sauren Airport",
  "São Félix do Xingu Airport",
  "Sidney Municipal Airport (FAA: N23)",
  "Siirt Airport",
  "Eareckson Air Station",
  "Seal Bay Seaplane Base",
  "Shiringayoc Airport",
  "Sydney Airport (Kingsford Smith Airport)",
  "Saadah Airport",
  "Silva Bay Seaplane Base",
  "Shelbyville Municipal Airport (Bomar Field)",
  "Sirjan Airport",
  "Stykkishólmur Airport",
  "Pu'er Simao Airport",
  "Stanton Airfield",
  "Shonai Airport",
  "Ruben Cantu Airport",
  "Tobías Bolaños International Airport",
  "Syracuse Hancock International Airport",
  "Saskylakh Airport",
  "Saint-Yan Airport (Charolais Bourgogne Sud Airport)",
  "Warraber Island Airport",
  "Sylvester Airport",
  "Sehwan Sharif Airport",
  "Sanya Phoenix International Airport",
  "Stornoway Airport",
  "Shiraz International Airport (Shahid Dastghaib Int'l)",
  "Soyo Airport",
  "Sultan Abdul Aziz Shah Airport",
  "Semera Airport",
  "Samsun-Çarşamba Airport",
  "Salzburg Airport",
  "Zaysan Airport",
  "Siguanea Airport",
  "Skukuza Airport",
  "Whiteman Air Force Base",
  "Sesriem Airport",
  "Santa Cruz Island Airport",
  "Santa Paula Airport",
  "Stara Zagora Airport",
  "Ryan's Creek Aerodrome",
  "Ségou Airport",
  "Suzhou Guangfu Airport",
  "Schwerin-Parchim International Airport",
  "Shenzhen Bao'an International Airport",
  "Olsztyn-Mazury Regional Airport",
  '"Solidarity" Szczecin–Goleniów Airport',
  "Tarapaina Airport",
  "Arthur Napoleon Raymond Robinson International Airport",
  "Daniel Z. Romualdez Airport",
  "Perry Stokes Airport",
  "Daegu International Airport",
  "Oran Tafaraoui Airport",
  "Bohol–Panglao International Airport",
  "Whitegrass Airport",
  "Taiz International Airport",
  "Tadji Airport",
  "Takamatsu Airport",
  "Ralph M. Calhoun Memorial Airport",
  "General Francisco Javier Mina International Airport",
  "Tangalooma Airport",
  "Qingdao Liuting International Airport",
  "Tapachula International Airport",
  "Tarcoola Airport",
  "Taranto-Grottaglie Airport",
  "Tashkent International Airport",
  "Poprad–Tatry Airport",
  "Tauramena Airport",
  "Tau Airport (FAA: HI36)",
  "Tacuarembó Airport",
  "Taliabu Airport",
  "Tartu Airport",
  "Daşoguz Airport",
  "Tabibuga Airport",
  "Dong Tac Airport",
  "Tuba City Airport (FAA: T03)",
  "Timbiquí Airport",
  "Timbunke Airport",
  "Tabiteuea North Airport",
  "Tabubil Airport",
  "Tugdan Airport",
  "New Bight Airport",
  "Tabarka–Aïn Draham International Airport",
  "Timber Creek Airport",
  "Tableland Homestead Airport",
  "Tumbang Samba Airport",
  "Waynesville-St. Robert Regional Airport (Forney Field)",
  "Tabora Airport",
  "Cap. FAP Pedro Canga Rodríguez Airport",
  "Tarabo Airport",
  "Statesboro–Bulloch County Airport",
  "Tbilisi International Airport",
  "Tabatinga International Airport",
  "Fuaʻamotu International Airport",
  "Tabal Airport",
  "Tambov Donskoye Airport",
  "Tshabong Airport",
  "Tabriz International Airport",
  "Tennant Creek Airport",
  "Treasure Cay Airport",
  "Tucumcari Municipal Airport",
  "Tarapacá Airport",
  "Tulcea Danube Delta Airport",
  "Tocoa Airport",
  "Tacheng Airport",
  "Tchibanga Airport",
  "metropolitan area1",
  "Torembi Airport",
  "Tinboli Airport",
  "Tuscaloosa Regional Airport",
  "McChord Field",
  "Tehuacán Airport",
  "La Florida Airport",
  "Taba International Airport",
  "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
  "Tuticorin Airport",
  "Truth or Consequences Municipal Airport",
  "Takotna Airport",
  "Thaba Nchu Airport",
  "Tocumwal Airport",
  "Tabas Airport",
  "Terrace Bay Airport",
  "Tengchong Tuofeng Airport",
  "Trinidad Airport",
  "Tetebedi Airport",
  "Teniente Jorge Henrich Arauz Airport",
  "Tandag Airport",
  "Tadjoura Airport",
  "Taldykorgan Airport",
  "Tandil Airport",
  "Theda Station Airport",
  "South Lewis County Airport (Ed Carlson Memorial Field)",
  "Trompeteros Airport",
  "Theodore Airport",
  "Sasereme Airport",
  "Tanda Tula Airport",
  "Samangoky Airport",
  "Tradewind Airport",
  "Trat Airport",
  "Toledo Executive Airport",
  "Tela Airport",
  "Teterboro Airport",
  "Telêmaco Borba Airport",
  "Thisted Airport",
  "Cheikh Larbi Tébessi Airport",
  "Telfer Airport",
  "Tenkodogo Airport",
  "Tetlin Airport (FAA: 3T4)",
  "Tezu Airport",
  "Tatitlek Airport (FAA: 7KA)",
  "Telupid Airport",
  "Temora Airport",
  "Tongren Fenghuang Airport",
  "Terapo Airport",
  "Teptep Airport",
  "Tekirdağ Çorlu Airport",
  "Lajes Airport",
  "Teseney Airport",
  "Chingozi Airport",
  "Te Anau Airport (Manapouri Airport)",
  "Teruel Airport",
  "Telluride Regional Airport",
  "Thingeyri Airport",
  "Tezpur Airport",
  "Tifalmin Airport",
  "Tefé Airport",
  "Tufi Airport",
  "Teófilo Otoni Airport (Juscelino Kubitscheck Airport)",
  "Telefomin Airport",
  "Tenerife–North Airport (Los Rodeos Airport)",
  "Wadi al Jandali Airport",
  "Tenerife–South Airport (Reina Sofia Airport)",
  "Taftan Airport",
  "Chengdu Tianfu International Airport",
  "Tengah Air Base",
  "Tagbita Airport",
  "Tanjung Manis Airport",
  "Podgorica Airport",
  "Sharpe Field (FAA: AL73)",
  "Sultan Mahmud Airport",
  "Tongoa Airport",
  "Tingo María Airport",
  "Tiga Airport",
  "Taganrog Airport",
  "Tagula Airport",
  "Târgu Mureș International Airport",
  "Latrobe Regional Airport",
  "Tongliao Airport",
  "Podkamennaya Tunguska Airport",
  "Tangará da Serra Airport",
  "Sidi Mahdi Airport",
  "Chokwe Airport",
  "Tanga Airport",
  "Toncontín International Airport",
  "Targovishte Airport (Buhovtsi Airfield)",
  "Ángel Albino Corzo International Airport",
  "Tullahoma Regional Airport (William Northern Field)",
  "Thaba Tseka Airport",
  "Tchien Airport",
  "Tho Xuan Airport",
  "Teresina–Senador Petrônio Portella Airport",
  "Thangool Airport",
  "Taharoa Aerodrome",
  "Tichitt Airport",
  "Thakhek Airport",
  "Tachilek Airport",
  "Thompson Falls Airport",
  "Trollhättan–Vänersborg Airport",
  "Thorshofn Airport",
  "Hot Springs County–Thermopolis Municipal Airport",
  "Tianshui Maijishan Airport",
  "Mehrabad International Airport",
  "Sukhothai Airport",
  "Tamchakett Airport",
  "Thule Air Base",
  "York Airport",
  "Trincomalee Harbour Seaplane Base",
  "Turukhansk Airport",
  "P.R. Mphephu Airport",
  "Tahoua Airport",
  "Tirana International Airport Nënë Tereza",
  "Tibú Airport",
  "Tinak Airport (FAA: N18)",
  "Abdelhafid Boussouf Bou Chekif Airport",
  "Tippi Airport",
  "Ta'if Regional Airport",
  "Tingwon Airport",
  "Tikehau Airport",
  "Tarinkot Airport",
  "Tijuana International Airport",
  "Tinker Air Force Base",
  "Cheadle Airport (TC: CFQ4)",
  "Mozes Kilangin Airport",
  "Tindouf Airport",
  "Tilin Airport",
  "Tripoli International Airport",
  "Tinian International Airport (West Tinian Airport) (FAA: TNI)",
  "Tirupati Airport",
  "Richard Pearse Airport",
  "Tivat Airport",
  "Tacoma Narrows Airport",
  "Space Coast Regional Airport",
  "Tidjikja Airport",
  "Tari Airport",
  "Capitán Oriel Lea Plaza Airport",
  "Sei Bati Airport",
  "Ticantiquí Airport",
  "Warukin Airport",
  "Tajima Airport",
  "Trujillo Airport (Capiro Airport)",
  "Tokat Airport",
  "Plínio Alarcom Airport",
  "Roshchino International Airport",
  "Takume Airport",
  "H.A.S. Hanandjoeddin Airport (Buluh Tumbang Airport)",
  "Tanjung Harapan Airport",
  "Kulob Airport",
  "Thanjavur Air Force Station",
  "Talkeetna Airport",
  "Tekadu Airport",
  "Tiko Airport",
  "Takoradi Airport",
  "Tenakee Seaplane Base",
  "Truckee Tahoe Airport (FAA: TRK)",
  "Radin Inten II Airport",
  "Takhli Royal Thai Air Force Base",
  "Tokeen Seaplane Base (FAA: 57A)",
  "Tok Junction Airport (FAA: 6K8)",
  "Chuuk International Airport",
  "Taku Lodge Seaplane Base",
  "Tokunoshima Airport",
  "Tlokoeng Airport",
  "Takapoto Airport",
  "Kigoma Airport",
  "Thakurgaon Airport",
  "Tokushima Airport",
  "Tak Airport",
  "Turku Airport",
  "Tatakoto Airport",
  "Tekin Airport",
  "Takaroa Airport",
  "Turkey Creek Airport",
  "Tokoroa Aerodrome",
  "Teller Airport (FAA: TER)",
  "Tarbela Dam Airport",
  "Licenciado Adolfo López Mateos International Airport",
  "Tuli Lodge Airport",
  "Toliara Airport",
  "Telida Airport (FAA: 2K5)",
  "Tulaghi Heliport",
  "Tallahassee International Airport",
  "Sultan Bantilan Airport (Lalos Airport)",
  "Tatalina LRRS Airport",
  "Talakan Airport",
  "Tallinn Airport (Lennart Meri Tallinn Airport)",
  "Zenata – Messali El Hadj Airport",
  "Toulon–Hyères Airport (Hyères Le Palyvestre Airport)",
  "Tol Airport",
  "Tumolbil Airport",
  "Turpan Jiaohe Airport",
  "Mefford Field Airport",
  "Toulouse–Blagnac Airport",
  "Tuluksak Airport",
  "Golfo de Morrosquillo Airport",
  "Ben Gurion Airport",
  "Talasea Airport",
  "Panguilemo Airport",
  "Plastun Airport",
  "Catalão Airport",
  "Henry Tift Myers Airport",
  "Miami Executive Airport",
  "Tambolaka Airport (Waikabubak Airport)",
  "Timbedra Airport",
  "Gabriel Vargas Santos Airport",
  "Thimarafushi Airport",
  "Tommanggong Airport",
  "Tanah Merah Airport",
  "Tumlingtar Airport",
  "Termez Airport",
  "Tam Kỳ Airport",
  "Tamale Airport",
  "Toamasina Airport",
  "Tamana Airport",
  "Tumeremo Airport",
  "Tampere–Pirkkala Airport",
  "Tambao Airport",
  "Aguenar – Hadj Bey Akhamok Airport",
  "São Tomé International Airport",
  "Porto Trombetas Airport",
  "Tambor Airport",
  "Tamworth Airport",
  "Timimoun Airport",
  "Tiom Airport",
  "Thames Aerodrome",
  "Jinan Yaoqiang International Airport",
  "Tanah Grogot Airport",
  "Tin City LRRS Airport",
  "Alberto Delgado Airport",
  "New Tanegashima Airport",
  "Toussus-le-Noble Airport",
  "Tangier Ibn Battouta Airport",
  "Tonghua Sanyuanpu Airport",
  "Satna Airport",
  "Raja Haji Fisabilillah Airport",
  "Tununak Airport (FAA: 4KA)",
  "Ternopil International Airport",
  "Teniente R. Marsh Airport",
  "Tainan Airport",
  "Tamarindo Airport",
  "Twentynine Palms Airport",
  "Teraina Airport",
  "Ivato International Airport",
  "Tungsten (Cantung) Airport (TC: CBX5)",
  "Dade-Collier Training and Transition Airport",
  "Newton Municipal Airport",
  "Tabuaeran Island Airport",
  "Jumandy Airport",
  "Steung Treng Airport",
  "Tosontsengel Airport",
  "Zamperini Field",
  "Tobruk Airport",
  "Toccoa Airport (R.G. LeTourneau Field)",
  "Tioman Airport",
  "Tozeur–Nefta International Airport",
  "Bogashevo Airport",
  "Togiak Airport",
  "Torres Airport",
  "Troy Municipal Airport",
  "Madrid–Torrejón Airport",
  "Torokina Airport",
  "Toledo Express Airport",
  "Timbuktu Airport",
  "Tonu Airport",
  "San Vito de Java Airport",
  "Philip Billard Municipal Airport",
  "Barriles Airport",
  "Torrington Municipal Airport",
  "Tromsø Airport, Langnes",
  "Totness Airstrip",
  "Touho Airport",
  "West End Seaplane Base",
  "Luiz dal Canalle Filho Airport",
  "Tobolsk Airport",
  "Toyama Airport",
  "Mahana Airport",
  "Tampa International Airport",
  "Tarapoa Airport",
  "Taiwan Taoyuan International Airport",
  "Peter O. Knight Airport",
  "Taiping Airport",
  "Tonopah Airport",
  "Tapini Airport",
  "Taplejung Airport",
  "Teuku Cut Ali Airport",
  "Draughon–Miller Central Texas Regional Airport",
  "Tiputini Airport",
  "Cad. FAP Guillermo del Castillo Paredes Airport",
  "Amado Nervo International Airport",
  "Tom Price Airport",
  "Vincenzo Florio Airport Trapani–Birgi",
  "Tapeta Airport",
  "Tikapur Airport",
  "Tupai Airport",
  "Al-Taqaddum Air Base",
  "Tarko-Sale Airport",
  "Taloqan Airport",
  "Trepell Airport",
  "Maranggo Airport",
  "Captain Ernesto Esguerra Cubides Air Base",
  "Tarama Airport",
  "Gonzalo Mejía Airport",
  "Francisco Sarabia International Airport (Torreón Int'l)",
  "Trondheim Airport, Værnes",
  "Tiree Airport",
  "Sandefjord Airport, Torp",
  "Tauranga Airport",
  "Trona Airport (FAA: L72)",
  "Tri-Cities Regional Airport",
  "Tarakbits Airport",
  "Juwata International Airport",
  "Terrell Municipal Airport",
  "Jacqueline Cochran Regional Airport",
  "Turin Airport (Caselle Airport)",
  "Taree Airport",
  "José Galera dos Santos Airport",
  "China Bay Airport",
  "Trieste – Friuli Venezia Giulia Airport (Ronchi dei Legionari Airport)",
  "FAP Captain Carlos Martínez de Pinillos International Airport",
  "Trivandrum International Airport",
  "Bonriki International Airport",
  "Trenton Municipal Airport",
  "Tororo Airport",
  "Tiruchirappalli International Airport",
  "Taipei Songshan Airport",
  "Tsumeb Airport",
  "Taisha Airport",
  "Tshipise Airport",
  "Astana International Airport",
  "Treviso-Sant'Angelo Airport",
  "Tanacross Airport",
  "Tshikapa Airport",
  "Tsile Tsile Airport",
  "Tsushima Airport",
  "Taskul Airport",
  "Tamuín National Airport",
  "Taos Regional Airport (FAA: SKX)",
  "Tianjin Binhai International Airport",
  "Tehachapi Municipal Airport",
  "Torres Airport",
  "Timișoara Traian Vuia International Airport",
  "Trang Airport",
  "Tabiteuea South Airport",
  "Townsville Airport",
  "Tsewi Airport",
  "Tasikmalaya Airport (Wiriadinata Airport)",
  "Tanjung Santan Airport",
  "Tsetserleg Airport",
  "Tan Tan Airport (Plage Blanche Airport)",
  "Tortolì Airport (Arbatax Airport)",
  "Las Breas Airport",
  "Portland–Troutdale Airport",
  "Sultan Babullah Airport",
  'Tartagal "General Enrique Mosconi" Airport',
  "RAFO Thumrait",
  "Tetiaroa Airport",
  "Tottori Airport",
  "Turtle Island Seaplane Base",
  "Tablón de Tamará Airport",
  "Trenton–Mercer Airport",
  "Britton Municipal Airport (FAA: BTN)",
  "Tortuguero Airport (Barra de Tortuguero Airport)",
  "Pongtiku Airport",
  "Tsaratanana Airport",
  "Taitung Airport (Taitung Fongnian Airport)",
  "Sania Ramel Airport",
  "Tissa Tank Waterdrome",
  "Truscott-Mungalalu Airport",
  "Teniente Coronel Luis a Mantilla International Airport",
  "Tubuai – Mataura Airport",
  "Teniente General Benjamín Matienzo International Airport",
  "Tambacounda Airport",
  "Tours Val de Loire Airport",
  "Tuguegarao Airport",
  "Turaif Domestic Airport",
  "Tum Airport",
  "Turbat International Airport",
  "Tulsa International Airport",
  "Tumut Airport",
  "Tunis–Carthage International Airport",
  "Taupo Airport",
  "Tupelo Regional Airport",
  "Tougan Airport",
  "Tucuruí Airport",
  "Tucson International Airport",
  "Tauta Airport",
  "Tabuk Regional Airport",
  "San Rafael Airport",
  "Tubala Airport",
  "Tumbler Ridge Airport (TC: CBX7)",
  "Tulum Airport",
  "Tucumã Airport",
  "Morafenobe Airport",
  "Cherry Capital Airport",
  "Thief River Falls Regional Airport",
  "Thomasville Regional Airport",
  "Lake Tahoe Airport",
  "Tangshan Sannühe Airport",
  "Matei Airport",
  "Dawei Airport",
  "Twin Hills Airport (FAA: A63)",
  "Toowoomba City Aerodrome",
  "Tumxuk Tangwangcheng Airport",
  "Jefferson County International Airport (FAA: 0S9)",
  "Taylor Airport (FAA: AK49)",
  "Magic Valley Regional Airport (Joslin Field)",
  "Tewantin Airport",
  "Torwood Airport",
  "Sanga-Sanga Airport",
  "Tawau Airport",
  "Tawa Airport",
  "Pukaki Airport (Twizel Airport)",
  "Rembele Airport",
  "Teixeira de Freitas Airport (9 de maio Airport)",
  "Texarkana Regional Airport (Webb Field)",
  "Berlin Tegel Airport",
  "Teminabuan Airport",
  "Huangshan Tunxi International Airport",
  "Tanbar Airport",
  "Tabou Airport",
  "Klokovo Airport",
  "Tibooburra Airport",
  "Tynda Airport",
  "Tyonek Airport",
  "Torsby Airport",
  "Thylungra Airport",
  "Capitán FAP Víctor Montes Arias International Airport",
  "Staniel Cay Airport",
  "Taiyuan Wusu International Airport",
  "metropolitan area2",
  "Tobermorey Airport",
  "Tyler Pounds Regional Airport",
  "McGhee Tyson Airport",
  "Treinta y Tres Airport",
  "Taylor Airport (FAA: TYL)",
  "Belize City Municipal Airport",
  "Tuscola Area Airport (FAA: CFS)",
  "Tuzla International Airport",
  "Cupul National Airport",
  "South Andros Airport (Congo Town Airport)",
  "Tsimiroro Airport",
  "Trabzon Airport",
  "Incirlik Air Base",
  "San Luis Río Colorado Airport",
  "Mount Aue Airport",
  "Ua Huka Airport",
  "Suai Airport",
  "Narsarsuaq Airport",
  "Luau Airport",
  "Andersen Air Force Base",
  "Ua Pou Airport",
  "Domingo Faustino Sarmiento Airport",
  "Bouarfa Airport",
  "Samburu Airport (Buffalo Spring Airport)",
  "Uaxactun Airport",
  "Mário de Almeida Franco Airport",
  "Mabuiag Island Airport",
  "Buin Airport",
  "Yamaguchi Ube Airport",
  "Ubon Ratchathani Airport",
  "Ubrub Airport",
  "Columbus-Lowndes County Airport",
  "Ubatuba Airport (Gastão Madeira Airport)",
  "Kalumburu Airport",
  "Ulanqab Airport",
  "Yucca Airstrip (FAA: NV11)",
  "Eunice Airport (FAA: 4R7)",
  "Lutsk Airport",
  "Buchanan Airport",
  "Ukhta Airport",
  "Everett–Stewart Regional Airport",
  "Undara Airport",
  "Bermuda Dunes Airport",
  "Volkel Air Base",
  "Ten. Cel. Av. César Bombonato Airport",
  "Uzhhorod International Airport",
  "Campoformido Airport",
  "Maharana Pratap Airport",
  "Queenstown Airport",
  "Quelimane Airport",
  "Urengoy Airport",
  "Kumejima Airport",
  "Waukesha County Airport (Crites Field)",
  "Quetta International Airport",
  "Ufa International Airport",
  "Bulgan Airport",
  "Ugashik Bay Airport",
  "Urgench International Airport",
  "San Juan (Uganik) Seaplane Base (FAA: WSJ)",
  "Union Glacier Blue-Ice Runway",
  "Waukegan National Airport",
  "Uíge Airport",
  "Ugashik Airport (FAA: 9A8)",
  "Bulagtai Airport",
  "Bilogai Airport",
  "Kunovice Airport",
  "El Caraño Airport",
  "Phu Cat Airport",
  "Útila Airport",
  "Ust-Ilimsk Airport",
  "Quillayute Airport",
  "Quincy Regional Airport (Baldwin Field)",
  "Mariscal Sucre International Airport",
  "Quimper–Cornouaille Airport (Pluguffan)",
  "Quoin Hill Airfield",
  "Quirindi Airport",
  "Jaluit Airport (FAA: N55)",
  "Ujae Airport",
  "Uljin Airport",
  "Uiju Airfield",
  "Ukunda Airport (Diani Airport)",
  "Kobe Airport",
  "Ust-Kuyga Airport",
  "Mukhaizna Airport",
  "Ukiah Municipal Airport",
  "Oskemen Airport (Ust-Kamenogorsk Airport)",
  "Waukon Municipal Airport (FAA: Y01)",
  "Mukeiras Airport",
  "Sevastopol International Airport",
  "Quakertown Airport",
  "Nuku Airport",
  "Ust-Kut Airport",
  "Capitán José Daniel Vazquez Airport",
  "Ulei Airport",
  "Ulundi Airport",
  "Sule Airport",
  "Ölgii Airport",
  "Prince Abdul Majeed bin Abdulaziz Domestic Airport",
  "Ulithi Airport (FAA: TT02)",
  "Lensk Airport",
  "Glenforsa Airfield",
  "New Ulm Municipal Airport",
  "Chinggis Khaan International Airport",
  "Ulaangom Airport",
  "Quilpie Airport",
  "Heriberto Gíl Martínez Airport",
  "Mulatos Airport",
  "Gulu Airport",
  "Ulyanovsk Baratayevka Airport",
  "Ulusaba Airstrip",
  "Ulyanovsk Vostochny Airport",
  "Donoi Airport",
  "Punta de Maisí Airport",
  "Umba Airport",
  "Umeå Airport",
  "Quince Mil Airport",
  "Summit Airport",
  "RAAF Woomera Airfield",
  "Ust-Maya Airport",
  "Umiat Airport",
  "Orlando de Carvalho Airport",
  "Sumy Airport",
  "Mena Intermountain Municipal Airport",
  "Una-Comandatuba Airport",
  "Unguía Airport",
  "Kunduz Airport",
  "Qacha's Nek Airport",
  "Kiunga Airport",
  "Union Island Airport",
  "Unalakleet Airport",
  "Ranong Airport",
  "Öndörkhaan Airport",
  "Unst Airport",
  "Dodge County Airport",
  "Mururoa Airport",
  "Pogogul Airport",
  "Franklin County Airport",
  "University-Oxford Airport",
  "Playa Baracoa Airport",
  "Sultan Hasanuddin International Airport",
  "Upala Airport",
  "Ignacio López Rayón International Airport",
  "Upolu Airport",
  "Upiara Airport",
  "RAF Upavon",
  "Oral Ak Zhol Airport",
  "Castilho Airport (Urubupunga Airport)",
  "Ürümqi Diwopu International Airport",
  "Burg Feuerstein Airport",
  "Kuressaare Airport",
  "Rubem Berta International Airport",
  "Uribe Airport",
  "Uray Airport",
  "Uriman Airport",
  "Urgun Airport",
  "Rouen Airport (Vallée de Seine Airport)",
  "Urrao Airport",
  "Kursk Vostochny Airport",
  "Surat Thani Airport",
  "Uroubi Airport",
  "Gurayat Domestic Airport",
  "Uruzgan Airport",
  "Concord Regional Airport (FAA: JQF)",
  "Union County Airport/Troy Shelton Field (FAA: 35A)",
  "Ushuaia – Malvinas Argentinas International Airport",
  "Mabaruma Airport",
  "Usharal Airport",
  "Usinsk Airport",
  "Useless Loop Airport",
  "Samui Airport",
  "Ulsan Airport",
  "Usino Airport",
  "Uşak Airport",
  "Ust-Nera Airport",
  "Sancti Spíritus Airport",
  "Northeast Florida Regional Airport (FAA: SGJ)",
  "Francisco B. Reyes Airport",
  "Mutare Airport",
  "Muttaburra Airport",
  "Soesterberg Air Base",
  "Nutwood Downs Airport",
  "Quthing Airport",
  "Udon Thani International Airport",
  "Utti Airport",
  "Utirik Airport (FAA: 03N)",
  "Tunica Municipal Airport (FAA: UTA)",
  "Upington Airport",
  "Indian Mountain LRRS Airport",
  "U-Tapao International Airport",
  "Ust-Tsilma Airport",
  "Mthatha Airport",
  "Ustupo Airport",
  "Queenstown Airport",
  "Bugulma Airport",
  "Baikal International Airport",
  "Ugnu-Kuparuk Airport (FAA: UBW)",
  "Baruun-Urt Airport",
  "Yuzhno-Sakhalinsk Airport (Khomutovo Airport)",
  "Manumu Airport",
  "Garner Field",
  "Ouvéa Airport (Ouloup Airport)",
  "Hewanorra International Airport",
  "União da Vitória Airport",
  "El Kharga Airport",
  "Uvol Airport",
  "Nyala Airport",
  "Yulin Yuyang Airport",
  "Uyuni Airport (Joya Andina Airport)",
  "Užice-Ponikve Airport",
  "Hope Bay Aerodrome (TC: CHB3)",
  "Urzhar Airport",
  "Curuzú Cuatiá Airport",
  "Vaasa Airport",
  "Yavarate Airport",
  "Varrelbusch Airport",
  "Moody Air Force Base",
  "Valence-Chabeuil Airport",
  "Major Brigadeiro Trompowsky Airport",
  "Cap. Av. Vidal Villagomez Toledo Airport",
  "Vanimo Airport",
  "Chevak Airport",
  "Valença Airport",
  "Villa International Airport",
  "Van Ferit Melen Airport",
  "Suavanao Airport",
  "Rodelillo Airfield",
  "Varna Airport",
  "Sivas Airport",
  "Vatomandry Airport",
  "Vatukoula Airport",
  "Vavaʻu International Airport",
  "Vardø Airport, Svartnes",
  "Ann Airport",
  "Mandalay Chanmyathazi Airport",
  "Vandenberg Air Force Base",
  "Bokpyin Airport",
  "Brescia Airport (Gabriele D'Annunzio Airport)",
  "Vanuabalavu Airport",
  "Visby Airport",
  "Can Tho International Airport",
  "Victoria River Downs Airport",
  "Venice Marco Polo Airport",
  "Valcheta Airport",
  "Vichadero Airport",
  "Chu Lai International Airport",
  "Viracopos/Campinas International Airport",
  "Carora Airport",
  "Con Dao Airport (Cỏ Ống Airport)",
  "Victoria Regional Airport",
  "Southern California Logistics Airport",
  "Ovda Airport",
  "Fagernes Airport, Leirin",
  "Glauber Rocha Airport",
  "El Hierro Airport",
  "Dong Hoi Airport",
  "Vidalia Regional Airport",
  "Gobernador Edgardo Castello Airport",
  "Van Don International Airport",
  "Valle de la Pascua Airport",
  "Villa Dolores Airport",
  "Vadsø Airport",
  "Vidyanagar Airport (Jindal Airport)",
  "Valdez Airport (Pioneer Field)",
  "Venetie Airport",
  "Maikwak Airport",
  "Vernal Regional Airport",
  "General Heriberto Jara International Airport",
  "Barakoma Airport",
  "Tioga Municipal Airport (FAA: D60)",
  "Vestmannaeyjar Airport",
  "Victoria Falls Airport",
  "Vijayawada Airport",
  "Vologda Airport",
  "Vigo–Peinador Airport",
  "General Villegas Airport",
  "North Las Vegas Airport",
  "Villa Garzón Airport",
  "Henrique de Carvalho Airport",
  "Vilhelmina Airport",
  "Culberson County Airport",
  "Verkhnevilyuysk Airport",
  "Vichy — Charmeil Airport",
  "Vahitahi Airport",
  "Ângelo Ponzoni Municipal Airport",
  "Villa Constitución Airport",
  "Vicenza Airport",
  "Vienna International Airport",
  "Juan Pablo Pérez Alfonzo Airport",
  "Rolla National Airport",
  "Vinh International Airport",
  "Virgin Gorda Airport",
  "Dakhla Airport",
  "Havryshivka Vinnytsia International Airport",
  "Viqueque Airport",
  "Virginia Airport",
  "Visalia Municipal Airport",
  "Vitoria Airport",
  "Viru Harbour Airport",
  "Vivigani Airfield",
  "Eurico de Aguiar Salles Airport",
  "Vélizy – Villacoublay Air Base",
  "Xai-Xai Airport",
  "Virginia Highlands Airport",
  "Gurúé Airport",
  "Rach Gia Airport",
  "Vnukovo International Airport",
  "Vicksburg Municipal Airport",
  "Vorkuta Airport",
  "Vandalia Municipal Airport",
  "Valencia Airport",
  "Valdosta Regional Airport",
  "Valle Airport (FAA: 40G)",
  "Villa Gesell Airport",
  "Bauerfield International Airport",
  "Volgodonsk Airport",
  "Valladolid Airport",
  "Lieutenant Colonel Rafael Pabón Airport",
  "Arturo Michelena International Airport",
  "Vila Rica Municipal Airport",
  "Vallenar Airport",
  "Valesdir Airport",
  "Velikiye Luki Airport",
  "Dr. Antonio Nicolás Briceño Airport",
  "Anglesey Airport / RAF Valley",
  "Villa Reynolds Airport",
  "Dr. Juan Plate Airport",
  "Baimuru Airport",
  "Salavan Airport",
  "Venice Municipal Airport",
  "Vangaindrano Airport",
  "Meucon Airport",
  "Vilnius Airport",
  "Vanrook Airport",
  "Lal Bahadur Shastri Airport",
  "Ventspils International Airport",
  "Vilankulo Airport",
  "Van Nuys Airport",
  "Vodochody Airport",
  "Volgograd International Airport",
  "Vohemar Airport",
  "Voinjama Airport (Tenebu Airport)",
  "Volk Field Air National Guard Base",
  "Nea Anchialos National Airport",
  "Votuporanga Airport (Domingos Pignatari Airport)",
  "Voronezh International Airport (Chertovitskoye Airport)",
  "Ondjiva Pereira Airport",
  "Vipingo Airport",
  "Vopnafjörður Airport",
  "Destin–Fort Walton Beach Airport / Eglin Air Force Base",
  "Chimoio Airport",
  "Porter County Regional Airport",
  "Cecil Airport",
  "Antonio Rivera Rodríguez Airport",
  "Juan Gualberto Gómez Airport",
  "Vero Beach Regional Airport",
  "Virac Airport",
  "Vredendal Airport",
  "Varandey Airport",
  "Varkaus Airport",
  "Vila Real Airport",
  "Verona Villafranca Airport",
  "Kawama Airport",
  "Roy Otten Memorial Airfield (FAA: 3VS)",
  "Vryburg Airport",
  "Carlos Rovirosa Pérez International Airport",
  "Viseu Airport",
  "Hartness State Airport",
  "Luhansk International Airport",
  "Stockholm Västerås Airport",
  "Vitebsk Vostochny Airport",
  "Wattay International Airport",
  "Vatulele Airport",
  "Vung Tau Airport",
  "Vittel - Champ-de-Courses Airport",
  "Nevatim Airbase",
  "Miller Field",
  "Hermanos Ameijeiras Airport",
  "Visakhapatnam Airport",
  "Alfonso López Pumarejo Airport",
  "Veliky Ustyug Airport",
  "Mvuu Camp Airport",
  "Mahanoro Airport",
  "La Vanguardia Airport",
  "Viru Viru International Airport",
  "Västervik Airport",
  "Las Malvinas Airport",
  "Vladivostok International Airport",
  "Takhamalt Airport",
  "Lichinga Airport",
  "Cesária Évora Airport (São Pedro Airport)",
  "Växjö/Kronoberg Airport",
  "Vryheid Airport",
  "Vilyuysk Airport",
  "Illinois Valley Regional Airport (Walter A. Duncan Field)",
  "Wales Airport",
  "Wacca Airport",
  "Andriamena Airport",
  "Wadi al-Dawasir Domestic Airport",
  "Wana Airport",
  "Whanganui Airport",
  "Harry Stern Airport (FAA: BWP)",
  "Ambalabe Airport",
  "Wawoi Falls Airport",
  "Ankazoabo Airport",
  "Wallops Flight Facility",
  "Ambatondrazaka Airport",
  "Wabo Airport",
  "Alto Palena Airfield",
  "Antsalova Airport",
  "Waris Airport",
  "metropolitan area1",
  "Waterford Airport",
  "Wauchope Airport",
  "Wave Hill Airport",
  "Warsaw Chopin Airport",
  "Zuwarah Airport",
  "Greene County Airport",
  "Warwick Airport",
  "Wahai Airport",
  "Stebbins Airport",
  "Wapolu Airport",
  "Befandriana Airport",
  "Ankaizina Airport",
  "Schleswig Air Base",
  "West Branch Community Airport (FAA: Y31)",
  "Wapenamanda Airport",
  "Antsoa Airport",
  "Beaver Airport",
  "Roben–Hood Airport (FAA: RQB)",
  "Boulder Municipal Airport (FAA: BDU)",
  "Wilkes-Barre Wyoming Valley Airport",
  "Gamboa Airport",
  "Chaitén Airfield",
  "Chandalar Lake Airport",
  "Wadi Ain Airport",
  "Enid Woodring Regional Airport",
  "Hosea Kutako International Airport",
  "Wondai Airport",
  "Waldronaire Airport (FAA: 90WA)",
  "Barrow County Airport",
  "Shiyan Wudangshan Airport",
  "Parker County Airport",
  "Wedau Airport",
  "Weifang Airport",
  "Weihai Dashuibo Airport",
  "Weipa Airport",
  "Welkom Airport",
  "Weam Airport",
  "Waghete Airport",
  "Wee Waa Airport",
  "Ketchikan Harbor Seaplane Base (FAA: 5KE)",
  "Woodford Aerodrome",
  "Fianarantsoa Airport",
  "Northern Aroostook Regional Airport (FAA: FVE)",
  "Wagga Wagga Airport",
  "Warangal Airport",
  "Walgett Airport",
  "Shaoyang Wugang Airport",
  "Winchester Regional Airport (FAA: OKV)",
  "Mau Hau Airport (Umbu Mehang Kunda Airport)",
  "Wangaratta Airport",
  "Wagau Airport",
  "Wagny Airport",
  "Hyder Seaplane Base (FAA: 4Z7)",
  "Wadi Halfa Airport",
  "Whakatane Airport",
  "Welshpool Airport",
  "Franz Josef Glacier Aerodrome",
  "Whiteman Airport",
  "Whalsay Airstrip",
  "Wharton Regional Airport (FAA: ARM)",
  "Wuhu Airport",
  "Wilbarger County Airport (FAA: F05)",
  "Wick Airport",
  "Wiesbaden Army Airfield",
  "Waiheke Island Aerodrome",
  "Wilson Airport",
  "Winton Airport",
  "Wilcannia Airport",
  "Wairoa Aerodrome",
  "Wittenoom Airport",
  "Witu Airport",
  "Woja Airport",
  "General William J. Fox Airfield",
  "Wajir Airport",
  "Wonju Airport",
  "Wanaka Airport",
  "Warracknabeal Airport",
  "Air Force Base Waterkloof",
  "Hwange Town Airport",
  "Wakkanai Airport",
  "Aleknagik Airport (FAA: 5A8)",
  "Wakunai Airport",
  "Walker's Cay Airport",
  "Wallal Airport",
  "Walcha Airport",
  "Strother Field",
  "Miles Airport",
  "Wellington International Airport",
  "Walaha Airport",
  "Selawik Airport",
  "Wollogorang Airport",
  "Waterloo Airport",
  "West Angelas Airport",
  "Loring Seaplane Base (FAA: 13Z)",
  "Hihifo Airport",
  "Willows-Glenn County Airport",
  "Mandritsara Airport",
  "Warrnambool Airport",
  "Winnemucca Municipal Airport",
  "Mandabe Airport",
  "Mount Keith Airport",
  "Ozark Regional Airport (FAA: BPK)",
  "Warsaw Modlin Airport",
  "Meyers Chuck Seaplane Base (FAA: 84K)",
  "Malaimbandy Airport",
  "Maroantsetra Airport",
  "White Mountain Airport",
  "Mampikony Airport",
  "Mananara Nord Airport",
  "Zunyi Maotai Airport",
  "Madirovalo Airport",
  "Wamena Airport",
  "Napakiak Airport",
  "Windarra Airport",
  "Wora na Yeno Airport",
  "Wenshan Puzhehei Airport",
  "Matahora Airport",
  "Wunnummin Lake Airport (TC: CKL3)",
  "Naga Airport",
  "Windorah Airport",
  "Nawabshah Airport",
  "Wanuma Airport",
  "Wenzhou Longwan International Airport",
  "Wonenara Airport",
  "Woensdrecht Air Base",
  "Uonquén Airport",
  "Shellharbour Airport",
  "Wondoola Airport",
  "Moramba Airport",
  "Wonsan Kalma International Airport",
  "Wang-an Airport",
  "Willow Airport (FAA: UUO)",
  "Cabo Juan Román Airfield",
  "Port Bergé Airport",
  "Pincher Creek Airport",
  "Wrotham Park Airport",
  "Powell Lake Water Aerodrome (TC: CAQ8)",
  "Wipim Airport",
  "North Fork Valley Airport (FAA: 7V2)",
  "Capitán Fuentes Martínez Airport",
  "Guardiamarina Zañartu Airport",
  "Warder Airport",
  "Robins Air Force Base",
  "Whangarei Airport",
  "Wrangell Airport",
  "McGuire Air Force Base",
  "Worland Municipal Airport",
  "Windarling Airport",
  "Copernicus Airport Wrocław",
  "Warton Aerodrome",
  "Warrawagine Airport",
  "Westray Airport",
  "Weerawila Airport",
  "Wasua Airport",
  "Steamboat Bay Seaplane Base",
  "Condron Army Airfield",
  "Cape Sarichef Airport (FAA: 26AK)",
  "Washington County Airport (FAA: AFJ)",
  "Brookhaven Airport (FAA: HWV)",
  "Chongqing Wushan Airport",
  "Wiseman Airport",
  "South Naknek Airport",
  "Washabo Airport",
  "Waspam Airport",
  "Wasior Airport",
  "Westerly State Airport",
  "Wasu Airport",
  "Westsound Seaplane Base (FAA: WA83)",
  "Whitsunday Airport",
  "Westport Airport",
  "Tambohorano Airport",
  "Toowoomba Wellcamp Airport",
  "West End Airport",
  "Wotje Airport (FAA: N36)",
  "Noatak Airport",
  "Tuntutuliak Airport (FAA: A61)",
  "RAF Waddington",
  "Wotho Airport",
  "Woitape Airport",
  "Whiteriver Airport (FAA: E24)",
  "Tsiroanomandidy Airport",
  "Wantoat Airport",
  "Whitianga Aerodrome",
  "Wuhai Airport",
  "Wudinna Airport",
  "Wau Airport",
  "Wuhan Tianhe International Airport",
  "Murrin Murrin Airport",
  "Wasum Airport",
  "Wiluna Airport",
  "Wuyishan Airport",
  "Xinzhou Wutaishan Airport",
  "Wau Airport",
  "Wuvulu Island Airport",
  "Sunan Shuofang International Airport",
  "Wuzhou Xijiang Airport",
  "Walvis Bay Airport",
  "Watsonville Municipal Airport",
  "Manakara Airport",
  "Waterville Robert LaFleur Airport",
  "JadeWeserAirport",
  "Wasilla Airport (FAA: IYS)",
  "Cape May Airport",
  "Woodie Woodie Airport",
  "Wewak Airport",
  "North Whale Seaplane Base (FAA: 96Z)",
  "West Woodward Airport",
  "Newtok Airport (FAA: EWU)",
  "West Wyalong Airport",
  "Wanzhou Wuqiao Airport",
  "Whyalla Airport",
  "Yes Bay Lodge Seaplane Base (FAA: 78K)",
  "Yengema Airport",
  "Wyndham Airport",
  "Yellowstone Airport",
  "Wa Airport",
  "Urad Middle Banner Airport",
  "Xinyang Minggang Airport",
  "Álamos Airport",
  "Serafin Enoss Bertaso Airport",
  "Aribinda Airport",
  "Saül Airport",
  "Blubber Bay Seaplane Base",
  "Bearskin Lake Airport (TC: CNE3)",
  "Bogande Airport",
  "Birjand International Airport",
  "Bedele Airport (Buno Bedele Airport)",
  "Biniguni Airport",
  "Boulsa Airport",
  "Brockville Regional Tackaberry Airport (TC: CNL3)",
  "Christmas Island Airport",
  "Cluff Lake Airport (TC: CJS3)",
  "Chatham-Kent Airport",
  "Coron Airport",
  "Colac Airport",
  "Châlons Vatry Airport",
  "Diébougou Airport",
  "Djibo Airport",
  "Xingcheng Airport",
  "Grand Geneva Resort Airport (FAA: C02)",
  "Xiangyang Liuji Airport",
  "Hamburg Finkenwerder Airport",
  "Gaoua Airport (Amilcar Cabral Airport)",
  "Gorom Gorom Airport",
  "Xangongo Airport",
  "Kangiqsualujjuaq (Georges River) Airport",
  "Xichang Qingshan Airport",
  "Xienglom Airport",
  "Xinguara Municipal Airport",
  "Ahmad al-Jaber Air Base",
  "Xilinhot Airport",
  "Xingning Air Base",
  "Xi'an Xianyang International Airport",
  "Al Udeid Air Base",
  "Mangla Airport",
  "Kantchari Airport",
  "Xieng Khouang Airport",
  "Kasabonika Airport",
  "Kaya Airport",
  "Lac Brochet Airport",
  "Long Xuyen Airport",
  "Saint-Louis Airport",
  "Leo Airport",
  "Lemwerder Airport",
  "Maramag Airport",
  "Mallacoota Airport",
  "Madison Municipal Airport (FAA: MDS)",
  "Mahendranagar Airport",
  "Manihi Airport",
  "Masasi Airport",
  "Minlaton Airport",
  "Xiamen Gaoqi International Airport",
  "Macmillan Pass Airport (TC: CFC4)",
  "Edmundo Carvajal Airport",
  "Yam Island Airport",
  "Northwest Arkansas Regional Airport",
  "Quang Ngai Airport",
  "Ali Air Base",
  "Xining Caojiabao Airport",
  "Xingtai Dalian Airport",
  "Nouna Airport",
  "Pama Airport",
  "Pukatawagan Airport",
  "Soto Cano Air Base",
  "Poplar River Airport",
  "Pine Ridge Airport (FAA: IEN)",
  "Quepos La Managua Airport",
  "Qualicum Beach Airport (TC: CAT4)",
  "RAAF Base Richmond",
  "Xinbarag Youqi Baogede Airport",
  "Ross River Airport",
  "Jerez Airport (La Parra Airport)",
  "Sir Bani Yas Airport",
  "South Caicos Airport",
  "Tonopah Test Range Airport (FAA: TNX)",
  "Sebba Airport",
  "South Indian Lake Airport",
  "Siocon Airport",
  "Seletar Airport",
  "Thargomindah Airport",
  "Tadoule Lake Airport",
  "Taroom Airport",
  "Tara Airport",
  "Xuzhou Guanyin Airport",
  "Vinh Long Airport",
  "Williston Basin International Airport",
  "Yandina Airport",
  "Ye Airport",
  "Edwaki Airport",
  "Zabré Airport",
  "Anahim Lake Airport (TC: CAJ4)",
  "Arctic Bay Airport (TC: CJX7)",
  "Cat Lake Airport",
  "Moose Lake Airport (TC: CJB4)",
  "Fort Frances Municipal Airport",
  "La Grande-4 Airport",
  "General Bernardo O'Higgins Airport",
  "Lyall Harbour Seaplane Base",
  "Yakutat Airport",
  "Alert Bay Airport",
  "Sault Ste. Marie Airport",
  "Yangambi Airport",
  "Yaoundé Airport",
  "Yap International Airport (FAA: T11)",
  "Maple Bay Seaplane Base",
  "La Grande-3 Airport",
  "Yasawa Island Airport",
  "Attawapiskat Airport",
  "Kattiniq/Donaldson Airport (TC: CTP9)",
  "Mayne Island Water Aerodrome (Miner's Bay Seaplane Base) (TC: CAW7)",
  "Angling Lake/Wapekeka Airport (TC: CKB6)",
  "St. Anthony Airport",
  "Tofino/Long Beach Airport",
  "Banff Airport",
  "Kugaaruk Airport",
  "Baie-Comeau Airport",
  "Uranium City Airport",
  "Bamfield Water Aerodrome (TC: CAE9)",
  "Canadian Forces Base Bagotville",
  "Bull Harbour Waterdrome",
  "Black Tickle Airport (TC: CCE4)",
  "Baie-Johan-Beetz Seaplane Base",
  "Baker Lake Airport",
  "Campbell River Airport",
  "Bob Quinn Lake Airport (TC: CBW4)",
  "Yibin Wuliangye Airport",
  "Telegraph Harbour Seaplane Base",
  "Brandon Municipal Airport (McGill Field)",
  "Opapimiskan Lake Airport (TC: CKM8)",
  "Brochet Airport",
  "Berens River Airport",
  "Bedwell Harbour Water Aerodrome (TC: CAB3)",
  "Lourdes-de-Blanc-Sablon Airport",
  "Bonnyville Airport",
  "Courtenay Airpark (TC: CAH3)",
  "Cambridge Bay Airport",
  "Cornwall Regional Airport",
  "Nanaimo Airport",
  "Centralia/James T. Field Memorial Aerodrome",
  "Cortes Island Aerodrome (TC: CCI9)",
  "West Kootenay Regional Airport (Castlegar Airport)",
  "Miramichi Airport",
  "Colville Lake/Tommy Kochon Aerodrome",
  "Charlo Airport",
  "St. Catharines/Niagara District Airport",
  "Cochrane Aerodrome",
  "Kugluktuk Airport",
  "Chetwynd Airport",
  "Cross Lake (Charlie Sinclair Memorial) Airport",
  "Chesterfield Inlet Airport",
  "Coronation Airport",
  "Yuncheng Guangong Airport",
  "Chilliwack Airport",
  "Clyde River Airport",
  "Fairmont Hot Springs Airport",
  "Dawson City Airport",
  "Burwash Airport",
  "Drayton Valley Industrial Airport (TC: CER3)",
  "Paradise River Airport (TC: CDF4)",
  "Deer Lake Regional Airport",
  "Digby/Annapolis Regional Airport",
  "Hatchet Lake Airport (TC: CJL2)",
  "Dease Lake Airport",
  "Lt. Col W.G. (Billy) Barker VC Airport",
  "Dolbeau-Saint-Félicien Airport",
  "Nain Airport",
  "Dawson Creek Airport",
  "Boundary Bay Airport",
  "Kasba Lake Airport (TC: CJL8)",
  "Bloodvein River Airport",
  "Obre Lake/North of Sixty Airport (TC: CKV4)",
  "metropolitan area1",
  "Bar River Airport (TC: CPF2)",
  "Yecheon Air Base",
  "Edmonton International Airport",
  "Yenişehir Airport",
  "Arviat Airport",
  "Elliot Lake Municipal Airport",
  "Manitowaning/Manitoulin East Municipal Airport",
  "Estevan Regional Aerodrome",
  "Royal Naval Air Station Yeovilton",
  "Yankisa Airport",
  "Fort Severn Airport",
  "Yasuj Airport",
  "Edson Airport",
  "Eureka Aerodrome",
  "Inuvik (Mike Zubko) Airport",
  "Amos/Magny Airport",
  "Fort Albany Airport",
  "Iqaluit Airport",
  "Fredericton International Airport",
  "Forestville Airport",
  "Fontanges Airport (TC: CTU2)",
  "Fort Hope Airport",
  "Fort MacKay/Firebag Aerodrome (TC: CFG6)",
  "Wekweètì Airport (Snare Lake Airport) (TC: CFJ7)",
  "Fort Reliance Water Aerodrome (CJN8)",
  "Flin Flon Airport",
  "Fort Resolution Airport",
  "Fort Simpson Airport",
  "Taipa Ferry Terminal",
  "St. Lewis (Fox Harbour) Airport (TC: CCK4)",
  "Texada/Gillies Bay Airport",
  "Grande Cache Airport (TC: CEQ5)",
  "Gorge Harbour Water Aerodrome",
  "Ganges Water Aerodrome (TC: CAX6)",
  "Fort Good Hope Airport",
  "Miho–Yonago Airport",
  "Kingston/Norman Rogers Airport",
  "La Grande Rivière Airport",
  "Gimli Industrial Park Airport",
  "Greenway Sound Water Aerodrome",
  "Gods Lake Narrows Airport",
  "Michel-Pouliot Gaspé Airport",
  "Geraldton (Greenstone Regional) Airport",
  "Îles-de-la-Madeleine Airport",
  "Igloolik Airport",
  "Havre Saint-Pierre Airport",
  "Kuujjuarapik Airport",
  "Gillam Airport",
  "Grise Fiord Airport",
  "Port Hope Simpson Airport (TC: CCP4)",
  "Hudson Bay Airport",
  "Hakai Passage Water Aerodrome",
  "Dryden Regional Airport",
  "Hope Aerodrome",
  "Hearst (René Fontaine) Municipal Airport",
  "Charlottetown Airport (TC: CCH4)",
  "Campbell River Water Aerodrome (TC: CAE3)",
  "Ulukhaktok/Holman Airport",
  "Gjoa Haven Airport",
  "John C. Munro Hamilton International Airport",
  "Hornepayne Municipal Airport",
  "Hopedale Airport",
  "Poplar Hill Airport",
  "Chevery Airport",
  "Sechelt Aerodrome (TC: CAP3)",
  "Haines Junction Airport",
  "Montréal/Saint-Hubert Airport",
  "Hay River/Merlyn Carter Airport",
  "Halifax Stanfield International Airport",
  "Yogyakarta International Airport",
  "Atikokan Municipal Airport",
  "Yichun Mingyueshan Airport",
  "Arxan Yi'ershi Airport",
  "Saint-Augustin Airport",
  "Big Bay Water Aerodrome (TC: CAF6)",
  "Yichang Sanxia Airport",
  "Ivujivik Airport",
  "Yining Airport",
  "Pond Inlet Airport",
  "Willow Run Airport",
  "Island Lake Airport (Garden Hill Airport)",
  "Yiwu Airport",
  "Jasper Airport",
  "Fort Liard Airport",
  "Saint-Jean Airport",
  "Hinton/Jasper-Hinton Airport (TC: CEC4)",
  "Samjiyon Airport",
  "Stephenville International Airport",
  "Kamloops Airport",
  "Collins Bay Airport",
  "Kincardine Municipal Airport",
  "Knee Lake Airport (TC: CJT3)",
  "Region of Waterloo International Airport",
  "Kangirsuk Airport",
  "Yingkou Lanqi Airport",
  "Key Lake Airport",
  "Kitkatla Water Aerodrome (TC: CAP7)",
  "Schefferville Airport",
  "Yakima Air Terminal (McAllister Field)",
  "Chan Gurney Municipal Airport",
  "Hakkari Yüksekova Airport",
  "Waskaganish Airport",
  "Yakutsk Airport",
  "Klemtu Water Aerodrome",
  "Chisasibi Airport (TC: CSU2)",
  "Kirkland Lake Airport",
  "Kindersley Regional Airport",
  "Buttonville Municipal Airport",
  "Lac La Biche Airport",
  "Kimmirut Airport",
  "Chapleau Airport",
  "Whatì Airport (TC: CEM3)",
  "Yalgoo Airport",
  "Lansdowne House Airport",
  "Ylivieska Airfield",
  "Meadow Lake Airport",
  "Lake Simcoe Regional Airport",
  "Lloydminster Airport",
  "Clinton Creek Airport",
  "Yilan Airport",
  "Mingan Airport",
  "La Tuque Airport",
  "Leaf Rapids Airport",
  "Lebel-sur-Quévillon Airport (TC: CSH4)",
  "Alert Airport",
  "Yevlakh Airport",
  "Kelowna International Airport",
  "Yulin Fumian Airport",
  "Langley Regional Airport",
  "Mayo Airport",
  "Merritt Airport (TC: CAD5)",
  "Mould Bay Airport",
  "Matane Airport",
  "Montague Harbour Water Aerodrome",
  "Manitouwadge Airport",
  "Mary's Harbour Airport",
  "CFB Moose Jaw (C.M. McEwen Airport)",
  "Mys-Kamenny Airport",
  "Charlevoix Airport",
  "Fort McMurray International Airport",
  "Makkovik Airport",
  "Moosonee Airport",
  "Port McNeill Airport (TC: CAT5)",
  "metropolitan area3",
  "Moisés Benzaquén Rengifo Airport",
  "Chibougamau/Chapais Airport",
  "Mansons Landing Water Aerodrome (TC: CAV7)",
  "Mary River Aerodrome (TC: CMR2)",
  "Maniwaki Airport",
  "Montréal–Mirabel International Airport",
  "Natashquan Airport",
  "Yanbu Airport (Yenbo Airport)",
  "Wemindji Airport",
  "Gatineau-Ottawa Executive Airport",
  "Norway House Airport",
  "Youngstown–Warren Regional Airport",
  "Hudson's Hope Airport",
  "Yanji Chaoyangchuan Airport",
  "Points North Landing Airport",
  "Matagami Airport",
  "Yandicoogina Airport",
  "North Spirit Lake Airport (TC: CKQ3)",
  "Natuashish Airport (TC: CNH2)",
  "Nemiscau Airport",
  "Yantai Penglai International Airport",
  "Snap Lake Airport (TC: CSK6)",
  "Yangyang International Airport",
  "Yancheng Nanyang International Airport",
  "Ekati Airport",
  "Old Crow Airport",
  "CFB Cold Lake (R.W. McNair Airport)",
  "Donnelly Airport (TC: CFM4)",
  "Ogoki Post Airport",
  "Oxford House Airport",
  "Opinaca Aerodrome (TC: CPN8)",
  "High Level Airport",
  "Yola Airport",
  "Yongphulla Airport (Yonphula Airport)",
  "Oshawa Airport",
  "Rainbow Lake Airport",
  "Billy Bishop Regional Airport",
  "Yotvata Airfield",
  "Ottawa Macdonald–Cartier International Airport",
  "Prince Albert (Glass Field) Airport",
  "Alberni Valley Regional Airport (TC: CBS8)",
  "Nora Aliqatchialuk Ruben Airport",
  "Parry Sound Area Municipal Airport (TC: CNK4)",
  "Peace River Airport",
  "Portage la Prairie/Southport Airport",
  "Inukjuak Airport",
  "Port Simpson Water Aerodrome (TC: CAN8)",
  "Aupaluk Airport",
  "Pickle Lake Airport",
  "Pikangikum Airport",
  "Port-Menier Airport",
  "Peawanuck Airport",
  "Peterborough Airport",
  "Prince Rupert Airport",
  "Port Hawkesbury Airport",
  "Pender Harbour Water Aerodrome (TC: CAG8)",
  "Powell River Airport",
  "Puvirnituq Airport",
  "Fort Chipewyan Airport",
  "Burns Lake Airport",
  "Muskoka Airport",
  "Québec City Jean Lesage International Airport",
  "Quaqtaq Airport",
  "The Pas Airport",
  "Red Deer Regional Airport",
  "Windsor International Airport",
  "Watson Lake Airport",
  "Yarmouth Airport",
  "April Point Water Aerodrome",
  "Kenora Airport",
  "Lethbridge Airport",
  "Greater Moncton International Airport",
  "Nakina Airport",
  "CFB Comox",
  "Regina International Airport",
  "St. Thomas Municipal Airport",
  "Thunder Bay International Airport",
  "Grande Prairie Airport",
  "Yorkton Municipal Airport",
  "North Battleford Airport (Cameron McIntosh Airport)",
  "Gander International Airport / CFB Gander",
  "Sydney/J.A. Douglas McCurdy Airport",
  "Quesnel Airport",
  "Gamètì/Rae Lakes Airport",
  "Resolute Bay Airport",
  "Refuge Cove Water Aerodrome",
  "Dean River Airport",
  "Cartwright Airport",
  "Rigolet Airport (TC: CCZ2)",
  "Rivière-du-Loup Airport",
  "Roberval Airport",
  "Red Lake Airport",
  "Rocky Mountain House Airport",
  "Rivers Inlet Water Aerodrome (TC: CAU8)",
  "Ottawa/Rockcliffe Airport",
  "Trois-Rivières Airport",
  "Stuart Island Airport",
  "Red Sucker Lake Airport",
  "Rankin Inlet Airport",
  "Revelstoke Airport",
  "Sable Island Aerodrome",
  "Sudbury Airport",
  "Sherbrooke Airport",
  "Squamish Airport",
  "Stony Rapids Airport",
  "Lutselk'e Airport",
  "Smiths Falls-Montague Airport",
  "Parry Sound/Frying Pan Island-Sans Souci Water Aerodrome (TC: CPS9)",
  "Saint John Airport",
  "Sanikiluaq Airport",
  "Saint-Léonard Aerodrome",
  "Fort Smith Airport",
  "Salmon Arm Airport",
  "Postville Airport (TC: CCD4)",
  "Marathon Aerodrome",
  "Songyuan Chaganhu Airport",
  "St. Theresa Point Airport",
  "Summerside Airport",
  "Bella Bella/Shearwater Water Aerodrome (TC: CAW8)",
  "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
  "Pembroke Airport",
  "Hartley Bay Water Aerodrome (TC: CAY4)",
  "Thicket Portage Airport",
  "Cape Dorset Airport",
  "Alma Airport",
  "Sullivan Bay Water Aerodrome (TC: CAV5)",
  "Thompson Airport",
  "Big Trout Lake Airport",
  "Mont Tremblant International Airport",
  "metropolitan area4",
  "Tofino Harbour Water Aerodrome (TC: CAB4)",
  "Tasiujaq Airport",
  "CFB Trenton",
  "Timmins/Victor M. Power Airport",
  "Tisdale Airport (TC: CJY3)",
  "Tasu Water Aerodrome",
  "Telegraph Creek Airport (TC: CBM5)",
  "Yangzhou Taizhou Airport",
  "Billy Bishop Toronto City Airport (Toronto Island Airport)",
  "Yuanmou Air Base",
  "Tuktoyaktuk/James Gruben Airport",
  "Umiujaq Airport",
  "Yuendumu Airport",
  "Montréal–Trudeau International Airport",
  "Yuma International Airport / MCAS Yuma (FAA: NYL)",
  "Yushu Batang Airport",
  "Repulse Bay Airport",
  "Hall Beach Airport",
  "Rouyn-Noranda Airport",
  "Iconi Airport",
  "Bonaventure Airport",
  "La Ronge (Barber Field) Airport",
  "Yeva Airport",
  "Vernon Regional Airport",
  "Vermilion Airport",
  "Qikiqtarjuaq Airport",
  "Cape Dyer Airport",
  "Val-d'Or Airport",
  "Kuujjuaq Airport",
  "Norman Wells Airport",
  "Vancouver International Airport",
  "Buffalo Narrows Airport",
  "Wiarton Airport",
  "Deer Lake Airport",
  "Petawawa Airport",
  "Kangiqsujuaq (Wakeham Bay) Airport",
  "Winnipeg James Armstrong Richardson International Airport",
  "Victoria Harbour Water Airport",
  "Déline Airport",
  "Wabush Airport",
  "Williams Lake Airport",
  "Williams Harbour Airport (TC: CCA6)",
  "Webequie Airport",
  "Chutes-des-Passes/Lac Margane Water Aerodrome (TC: CTM3)",
  "White River Water Aerodrome (TC: CNJ8)",
  "Whistler/Green Lake Water Aerodrome (TC: CAE5)",
  "Wrigley Airport",
  "Cranbrook/Canadian Rockies International Airport",
  "Edmonton City Centre (Blatchford Field) Airport",
  "Saskatoon John G. Diefenbaker International Airport",
  "Medicine Hat Airport",
  "Fort St. John Airport (North Peace Airport)",
  "Rimouski Airport",
  "Sioux Lookout Airport",
  "Whale Cove Airport",
  "Pangnirtung Airport",
  "Beaver Creek Airport",
  "Earlton (Timiskaming Regional) Airport",
  "Prince George Airport",
  "Northwest Regional Airport",
  "London International Airport",
  "Abbotsford International Airport",
  "Erik Nielsen Whitehorse International Airport",
  "Wawa Airport",
  "Yueyang Sanhe Airport",
  "North Bay/Jack Garland Airport",
  "Calgary International Airport",
  "Smithers Airport",
  "Northern Rockies Regional Airport",
  "Penticton Regional Airport",
  "Charlottetown Airport",
  "Taloyoak Airport",
  "Rivers Airport",
  "Victoria International Airport",
  "Lynn Lake Airport",
  "Cowley Airport",
  "Swift Current Airport",
  "Churchill Airport",
  "CFB Goose Bay",
  "St. John's International Airport",
  "Kapuskasing Airport",
  "Armstrong Airport",
  "Mont-Joli Airport",
  "Toronto Pearson International Airport",
  "Cache Creek Airport (Ashcroft Regional Airport)",
  "Gore Bay-Manitoulin Airport",
  "Yellowknife Airport",
  "Salluit Airport",
  "Slave Lake Airport",
  "Sandspit Airport",
  "Sarnia Chris Hadfield Airport",
  "Coral Harbour Airport",
  "Port Hardy Airport",
  "Whitecourt Airport",
  "Sept-Îles Airport",
  "Teslin Airport",
  "CFB Greenwood",
  "Zhangye Ganzhou Airport",
  "Trail Airport (TC: CAD4)",
  "Alice Arm/Silver City Water Aerodrome (TC: CAC3)",
  "York Landing Airport",
  "Zadar Airport",
  "Franjo Tuđman Airport",
  "Zahedan Airport",
  "Zaranj Airport",
  "Pichoy Airport",
  "Zamboanga International Airport",
  "Cahors - Lalbenque Airport",
  "Zaria Airport",
  "Zhaotong Airport",
  "Zaragoza Airport",
  "Zábřeh Airport",
  "Bathurst Airport",
  "Žabljak Airport",
  "Biloela Airport",
  "Roland-Désourdy Airport",
  "Bowen Airport",
  "Konarak Airport",
  "Sayaboury Airport",
  "General Leobardo C. Ruiz International Airport",
  "La Araucanía Airport",
  "Dalma Airport",
  "Secunda Airport",
  "Senggo Airport",
  "Bella Bella (Campbell Island) Airport",
  "Eastmain River Airport",
  "Zenag Airport",
  "Zero Airport (Ziro Airport)",
  "Faro Airport",
  "Fond-du-Lac Airport",
  "Fort McPherson Airport",
  "Tulita Airport",
  "Fairview Airport (TC: CEB5)",
  "Grand Forks Airport",
  "Gods River Airport",
  "South Galway Airport",
  "Ngoma Airport",
  "Little Grand Rapids Airport",
  "La Romaine Airport (TC: CTT5)",
  "Gaua Airport",
  "Zhanjiang Airport",
  "Shamshernagar Airport",
  "High Prairie Airport",
  "Zhongwei Shapotou Airport (Zhongwei Xiangshan Airport)",
  "Ramenskoye Airport (Zhukovsky Airport)",
  "Victoria Airport",
  "Ziguinchor Airport",
  "Ixtapa-Zihuatanejo International Airport",
  "Alzintan Airport",
  "Zhigansk Airport",
  "Zamzama Airport",
  "Jenpeg Airport",
  "Swan River Airport",
  "Tanjung Pelepas Port Airport",
  "Kasaba Bay Airport",
  "Kashechewan Airport",
  "Kegaska Airport (TC: CTK6)",
  "Zyryanka Airport",
  "Playa de Oro International Airport",
  "La Tabatière Airport (TC: CTU5)",
  "Pasir Gudang Port Airport",
  "Zalingei Airport",
  "Sena Madureira Airport",
  "South Cariboo Regional Airport",
  "Zamora National Airport",
  "Masset Airport",
  "Nanaimo Harbour Water Airport (TC: CAC8)",
  "Nyac Airport",
  "Zinder Airport",
  "Newman Airport",
  "Namu Water Aerodrome",
  "Abeid Amani Karume International Airport",
  "Ocean Falls Water Aerodrome (TC: CAH2)",
  "Cañal Bajo Carlos Hott Siebert Airport ",
  "Sachigo Lake Airport",
  "Pucón Airport",
  "Zephyrhills Municipal Airport",
  "Pinehouse Lake Airport",
  "Queenstown Airport",
  "Queen Charlotte City Water Aerodrome (TC: CAQ6)",
  "Zweibrücken Airport",
  "Zhangjiakou Ningyuan Airport",
  "Zurich Airport",
  "Serui Airport",
  "Round Lake (Weagamow Lake) Airport",
  "Sarmi Orai Airport",
  "San Salvador Airport (Cockburn Town Airport)",
  "Pierrefonds Airport",
  "Sandy Lake Airport",
  "Sassandra Airport",
  "Stewart Aerodrome",
  "Prince Rupert/Seal Cove Water Airport",
  "Tureia Airport",
  "Tête-à-la-Baleine Airport (TC: CTB6)",
  "Zakynthos International Airport (Dionysios SolomosAirport)",
  "Shamattawa Airport",
  "Zhytomyr Airport",
  "Tahsis Water Aerodrome (TC: CAL9)",
  "Zaqatala International Airport",
  "Ignace Municipal Airport",
  "Pupelde Airfield",
  "Zhuhai Jinwan Airport (Zhuhai Sanzao Airport)",
  "Zilfi Airport",
  "Churchill Falls Airport",
  "Miandrivazo Airport",
  "Springvale Airport",
  "Savannakhet Airport",
  "Andapa Airport",
  "Wollaston Lake Airport",
  "Zabrat Airport",
  "Zunyi Xinzhou Airport",
  "Osmani International Airport",
  "Zonalnoye Airport",
  "Mzuzu Airport",
  "Zanesville Municipal Airport",
];
