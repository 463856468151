import {
  AppBar,
  IconButton,
  Toolbar,
  TextField,
  Box,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect, useState } from "react";
import { BorderCrossingsForm } from "./BorderCrossings";
import { BorderCrossingsTable } from "./BorderCrossingsTable";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { v4 as uuidv4 } from "uuid";

import { set, omitBy } from "lodash/fp";
import { firebaseConfig } from "./config.local";
import { useForm, Controller } from "react-hook-form";
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from "axios";

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const useFirebaseAuth = () => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user && user.uid) {
        const userDoc = await db.collection("users").doc(user.uid).get();
        if (!userDoc.data()?.is_admin) {
          firebase.auth().signOut();
          return;
        }
        setUser(user);
      } else {
        // User is signed out.
        // ...
        setUser(user);
      }
    });
  }, []);

  return user;
};

function App() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [localEmail, setLocalEmail] = useState(email);
  const [userData, setUserData] = useState<any>(null);
  const [userId, setUserId] = useState<any>(null);

  const [docRef, setDocRef] = useState<any>(null);
  const [editingId, setEditingId] = useState<any>(null);

  useEffect(() => {
    setUserData(null);
    setEditingId(null);

    if (email) {
      const usersRef = db.collection("users");
      const query = usersRef.where("email", "==", email);

      const unsubscribe = query.onSnapshot((qs) => {
        if (qs.docs.length) {
          setUserData(qs.docs[0].data());
          setUserId(qs.docs[0].id);
          setDocRef(qs.docs[0].ref);
        } else {
          enqueueSnackbar("No such user found.");
        }
      });

      return unsubscribe;
    }
  }, [email]);

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            Waypoint Admin
          </Typography>
          <Button color="inherit" onClick={() => firebase.auth().signOut()}>
            Log Out
          </Button>
        </Toolbar>
      </AppBar>

      <Box p={4}>
        <Typography variant="h4">Border Crossings</Typography>
        <Box mb={2} display="flex" alignItems="flex-end">
          <TextField
            value={localEmail}
            onChange={(e) => setLocalEmail(e.target.value)}
            label="User Email"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setEmail(localEmail);
              }
            }}
          />
          <Button onClick={() => setEmail(localEmail)}>Load</Button>
        </Box>

        {userData ? (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              {userData ? (
                <BorderCrossingsTable
                  selected={editingId}
                  passports={userData.passports}
                  trips={userData.trips}
                  onEdit={(r) => setEditingId(r.id)}
                  onDelete={(r) => {
                    const confirmed = window.confirm(
                      "Are you sure you want to delete?"
                    );
                    if (confirmed) {
                      setEditingId(null);

                      const newTrips = userData.trips.filter(
                        (t) => t.id !== r.id
                      );

                      docRef.update({ trips: newTrips });
                      enqueueSnackbar("Deleted");
                    }
                  }}
                />
              ) : null}
              <Button onClick={() => setEditingId(null)}>
                Add Border Crossing
              </Button>
            </Grid>
            <Grid item xs={5}>
              <BorderCrossingsForm
                userId={userId}
                data={userData?.trips?.find((t: any) => t.id === editingId)}
                passports={userData.passports}
                onSave={(trip) => {
                  if (editingId) {
                    const index = userData.trips.findIndex(
                      (t: any) => t.id === editingId
                    );

                    const newTrips = set(
                      [index],
                      {
                        ...userData.trips[index],
                        ...trip,
                      },
                      userData.trips
                    );

                    docRef.update({ trips: newTrips });
                    enqueueSnackbar("Saved");
                  } else {
                    return axios
                      .post(
                        "https://us-central1-waypoint-e9a8e.cloudfunctions.net/tripOnRequest",
                        omitBy((v) => !v, {
                          email: email,
                          country: trip.country,
                          passport: trip?.passport.country,
                          startDate: trip.start_date,
                          visaCategory: trip.visa_category,
                          modeOfArrival: trip?.mode_of_arrival,
                          modeOfDeparture: trip?.mode_of_departure,
                          portOfArrival: trip?.port_of_arrival,
                          portOfDeparture: trip?.port_of_departure || null,
                          evidence: trip?.evidence || null,
                        })
                      )
                      .then((r) => {
                        enqueueSnackbar("New record created.");
                        setEditingId(null);
                      })
                      .catch(() => {
                        enqueueSnackbar("Error saving...");
                      });
                  }
                }}
              />
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </div>
  );
}

const LogIn = () => {
  const { register, handleSubmit, watch, control, errors, reset } = useForm({});

  const onSubmit = (data) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .catch((error) => {
        console.log("auth", error);
      });
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      height="100%"
    >
      <Box>
        <Typography>Waypoint</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <TextField
              name="email"
              inputRef={register({ required: true })}
              label="Email"
            />
          </Box>
          <Box>
            <TextField
              name="password"
              inputRef={register({ required: true })}
              type="password"
              label="Password"
            />
          </Box>
          <Button color="primary" type="submit">
            Log In
          </Button>
        </form>
      </Box>
    </Box>
  );
};

const AuthGate = () => {
  const user = useFirebaseAuth();

  return <SnackbarProvider> {user ? <App /> : <LogIn />} </SnackbarProvider>;
};

export default AuthGate;
